import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";

const json = {
  1: {
    unit: "Unit 8",
    id: "SB9-2024-U8-P81-E1",
    exerciseKey: "img/FriendsPlus/Page81/Key/E1answerKey.png",
    component: T6,
    inputSize: 500,
    textAlign: "center",
    // maxLength:5,
    // hideBtnFooter:true,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 23,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 350,
      paddingTop: 5,
      // fontWeight: 600,
    },
    hintBox: [
      {
        src: ["finish", "leave", "study", "not read", "close", "not use"],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 150,
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Study the table. Then complete sentences 1-6.`,
        color: "#5cc9dd",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative; font-size: 22px; line-height: 50px'>
        <img style="width:17cm; height:12cm; margin-left: 100px" src='img/FriendsPlus/Page81/E1/1.jpg' />
        <div style='display: flex;  width: 27cm; margin-top: 20px'>
          <div>
            <div><b>1 </b>&ensp;</div>
            <div><b>2 </b>&ensp;</div>
            <div><b>3 </b>&ensp;</div>
            <div><b>4 </b>&ensp;</div>
            <div><b>5 </b>&ensp;</div>
            <div><b>6 </b>&ensp;</div>
          </div>
          <div>
            <div>'I'm scared of spiders.'<br>
            He said that he # scared of spiders.</div>
            <div>'We can't find the way home.' <br>
            The children said that they # the way home. </div>
            <div>'The man has a beard.' <br>
            She told the officer that the man # a beard.</div>
            <div>'My friends are playing badminton outside.' <br>
            Jane told her mom that her friends # badminton outside.</div>
            <div>'We bought the tickets for you, Kim.' <br>
            Kim's cousins said that they # the tickets for her.</div>
            <div>'I've never ridden on roller coasters before.' <br>
            The girl told her roommate that she # on roller coasters before.</div>
          </div>
        </div>
        `,
        answer: [
          "was",
          "couldn't find",
          "had",
          "were playing",
          "had bought",
          "had never ridden",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 8",
    id: "SB9-2024-U8-P81-E2",
    exerciseKey: "img/FriendsPlus/Page81/Key/E2answerKey.png",
    component: T6,
    inputSize: 500,
    textAlign: "center",
    // maxLength:5,
    // hideBtnFooter:true,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 23,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 350,
      paddingTop: 5,
      // fontWeight: 600,
    },
    textareaStyle: {
      // background: 'none',
      width: 800,
      fontSize: 22,
      height: 70,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "2",
        title: `Study <i>said</i> and <i>told</i> in the reported sentences in exercise 1. What is the difference between them?`,
        color: "#5cc9dd",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative; font-size: 22px; line-height: 50px'>
        <img style="width:25cm; height:29cm; margin-left: 100px" src='img/FriendsPlus/Page81/E2/1.png' />
        <div style='display: flex; margin-top: 20px'>
          <div><i>said </i></div><textarea id='0' rows='2'>
        </div>
        <div style='display: flex'>
        <i>told </i><textarea id='1' rows='2'>
        </div>
        `,
        answer: [
          "is followed by that without an object",
          "always has an object before that",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 8",
    id: "SB9-2024-U8-P81-E3",
    exerciseKey: "img/FriendsPlus/Page81/Key/E3answerKey.png",
    component: T6,
    audio: "Audios/2.18.mp3",
    textAlign: "center",
    // maxLength:5,
    // hideBtnFooter:true,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 300,
      paddingTop: 5,
      // fontWeight: 600,
    },
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 22,
      // height: 70,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "3",
        title: `<headphone name=2.18 src='Audios/2.18 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
        Listen to Clare Waston talking about her fear. Complete the sentences with the verbs you hear and then report what Clare said.`,
        color: "#5cc9dd",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative; font-size: 22px; line-height: 40px; margin-left: 50px'>
        <div style='width: 27cm; margin-top: 20px'>
          <div>
            <div><b>1 </b>&ensp;I # going out because it's scary.</div>
            <div><textarea id='8' rows='2'></div>

            <div><b>2 </b>&ensp; The doctors # a solution to my problem.</div>
            <div><textarea id='9' rows='2'></div>

            <div><b>3 </b>&ensp; I # lots of medicine.</div>
            <div><textarea id='10' rows='2'></div>

            <div><b>4 </b>&ensp; Therapy groups #.</div>
            <div><textarea id='11' rows='2'></div>

            <div><b>5 </b>&ensp; I # very depressed.</div>
            <div><textarea id='12' rows='2'></div>

            <div><b>6 </b>&ensp; I now # a new virtual reality treatment.</div>
            <div><textarea id='13' rows='2'></div>

            <div><b>7 </b>&ensp; I # beaches, shopping centres and streets.</div>
            <div><textarea id='14' rows='2'></div>

            <div><b>8 </b>&ensp; I # better.</div>
            <div><textarea id='15' rows='2'></div>
          </div>
        </div>
        `,
        answer: [
          "stop",
          "haven't found",
          "took",
          "didn't help",
          "am",
          "am now undergoing",
          "can visit",
          "am getting",
          "Clare said she stopped going out because it was scary. | Clare said that she stopped going out because it was scary.",
          "Clare said the doctors hadn't found a solution to her problem. | Clare said that the doctors hadn't found a solution to her problem.",
          "Clare said she had taken lots of medicine. | Clare said that she had taken lots of medicine.",
          "Clare said therapy groups hadn't helped. | Clare said that therapy groups hadn't helped.",
          "Clare said she was very depressed. | Clare said that she was very depressed.",
          "Clare said she was then undergoing a new virtual reality treatment. | Clare said that she was then undergoing a new virtual reality treatment.",
          "Clare said she could visit beaches, shopping centres and streets. | Clare said that she could visit beaches, shopping centres and streets.",
          "Clare said she was getting better. | Clare said that she was getting better.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "SB9-2024-U8-P81-E4",
    // exerciseKey: "img/FriendsPlus/Page81/Key/E3answerKey.png",
    component: T6,
    // maxLength: 30,
    recorder: true,
    hideBtnFooter: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 800,
      fontSize: 22,
      height: 120,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "4",
        title: `<img style='height:1.1cm; width: 3.8cm; margin-bottom: 5px' src='img/FriendsPlus/icon/3.jpg'/>
        Work in pairs. Take turns to make true and false reported statements. Guess the true statements.`,
        color: "#5cc9dd",
        left: 50,
        width: "27cm",
      },
    ],
    questions: [
      {
        title: `
        <div style='margin-left: 50px' >
        <img style="width:21.5cm; height: 6cm; " src='img/FriendsPlus/Page81/E4/1.jpg' />
        </div>
        
        `,
        answer: [""],
      },
    ],
  },
  5: {
    unit: "Unit 8",
    id: "SB9-2024-U8-P81-E5",
    // exerciseKey: "img/FriendsPlus/Page71/Key/E2answerKey.png",
    component: T6,
    //  maxLength: 25,
    hideBtnFooter: true,
    // recorder: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 22,
      paddingTop: 5,
      // height: 200,
    },
    titleQuestion: [
      {
        num: "",
        title: `<div style='margin-bottom: 10px'><span style='color: #1C5161 ;font-weight: 900; border: 2px solid darkgray; border-radius: 15px; padding: 5px'>Finished?</span></div> 
        Reported three things that friends or family members have said to you today. Use <i>say</i> or <i>tell</i>.`,
        color: "#203c8f",
        left: 50,
        width: "26cm",
      },
    ],

    questions: [
      {
        title: `
        <textarea id='0' rows='10'>
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
