import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  6: {
    unit: "Unit 7",
    id: "SB9-2024-U7-P72-E6",
    // audio: "img/FriendsPlus/Page110/Audio/audio-e2-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "65%",
      marginLeft: 150,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page72/E6/1.jpg" }]],
  },
  1: {
    unit: "Unit 7",
    id: "SB9-2024-U7-P72-E1",
    exerciseKey: "img/FriendsPlus/Page72/Key/E1answerKey.png",
    component: T6,
    textAlign: "center",
    maxLength: 1,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      width: 100,
      // textTransform: 'uppercase',
      // borderStyle: "dotted",
      textAlign: "center",
      paddingTop: 5,
    },
    hintBox: [
      {
        src: [
          "although",
          "became",
          "'ve become",
          "despite",
          "in spite",
          "started",
          "'ve started",
          "never",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Read texts A and B quickly. Then answer questions 1–6.`,
        color: "#d2232b",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative; font-size: 22px; line-height: 40px'>
	        <img src="img/FriendsPlus/Page72/E1/1.jpg" style='height:30cm;padding-bottom:20px '/>
          <div style="position: absolute; top: 889px; left: 482px">
            Which text … <br>
            <b>1</b>&ensp;only presents fact? #<br>
            <b>2</b>&ensp;contains facts and opinions? #<br>
            <b>3</b>&ensp;has a chatty tone? #<br>
            <b>4</b>&ensp;has a formal tone? #<br>
            <b>5</b>&ensp;comes from an online encyclopedia? #<br>
            <b>6</b>&ensp;is a personal blog?#
          </div>   
        </div> 
      
        
        `,
        answer: ["A", "B", "B", "A", "A", "B"],
      },
    ],
  },
  2: {
    unit: "Unit 7",
    id: "SB9-2024-U7-P72-E2",
    audio: "Audios/2.11.mp3",
    exerciseKey: "img/FriendsPlus/Page72/Key/E2answerKey.png",
    component: T6,
    // hideBtnFooter:true,
    stylesTextInput: { width: 850, paddingTop: 10, fontSize: 22 },
    hintBox: [
      {
        src: [
          "But it’s up to you",
          "So I’m leaning towards the film club",
          "What are your options",
          "The only bad thing about that club",
          "What are you up to",
          "Have you considered another basketball club",
          "It’s a tricky decision",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `<headphone name=2.11 src='Audios/2.11 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
         Read and listen to the texts. Answer the 
          questions.`,
        color: "#d2232b",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative; font-size: 22px; line-height: 40px'>
        <img src="img/FriendsPlus/Page72/E1/1.jpg" style='height:28cm;padding-bottom:20px '/>
          <div style="width:26cm">
            <b>1</b>&ensp;When was the first Wembley Stadium opened? <br> #.<br>
            <b>2</b>&ensp;What are the distinctive features of Wembley Stadium? <br> #.<br>
            <b>3</b>&ensp;Who are the Gunners? <br> #<br>
            <b>4</b>&ensp;Why is the Undercroft skatepark important? <br> #.<br>
            <b>5</b>&ensp;What do people typically eat at Wimbledon? <br> #.<br>
            <b>6</b>&ensp;What is unique about the Wimbledon Grand Slam tournament? <br> #.<br>
          </div>   
        </div> 
        
      
        
        `,
        answer: [
          "It was opened in 1923",
          "its partially retractable roof and its arch which is 133 metres high and 315 metres wide",
          "'The Gunners' is another name for the Arsenal football team|The Gunners is another name for the Arsenal football team",
          "It's been the heart of Britain's skateboard community for over forty years",
          "strawberries and cream",
          "It is the only Grand Slam tournament that is played on grass",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 7",
    id: "SB9-2024-U7-P72-E3",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page72/Key/E3answerKey.png",
    component: T6,
    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 23,
      height: 27,
      width: 300,
      // textTransform: 'uppercase',
      // borderStyle: "dotted",
      textAlign: "center",
      paddingTop: 5,
    },
    hintBox: [
      {
        src: [
          "But it’s up to you",
          "So I’m leaning towards the film club",
          "What are your options",
          "The only bad thing about that club",
          "What are you up to",
          "Have you considered another basketball club",
          "It’s a tricky decision",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `<prefix text='VOCABULARY PLUS' icon=''></prefix> Find the comparative or 
          superlative forms for 1–6 in the texts.`,
        color: "#d2232b",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative; font-size: 23px; line-height: 40px'>
	        <img src="img/FriendsPlus/Page72/E1/1.jpg" style='height:28cm;padding-bottom:20px '/>
          <div style=" position: absolute; top: 820px; left: 455px;">
            <b>1</b>&ensp;large#<br>
            <b>2</b>&ensp;legendary#<br>
            <b>3</b>&ensp;talented#<br>
            <b>4</b>&ensp;big#<br>
            <b>5</b>&ensp;good#<br>
            <b>6</b>&ensp;tall #<br>
          </div>   
        </div> 
      
        
        `,
        answer: [
          "the (second) largest | the largest | the second largest",
          "(Arsenal's)most legendary | Arsenal's most legendary | most legendary",
          "the most talented",
          "the biggest",
          "the best",
          "taller",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 7",
    id: "SB9-2024-U7-P72-E4",
    // audio: "",
    // exerciseKey: "img/FriendsPlus/Page72/Key/E4answerKey.png",
    component: T6,
    isHello: true,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 23,
      height: 27,
      width: 200,
      // textTransform: 'uppercase',
      // borderStyle: "dotted",
      textAlign: "center",
      paddingTop: 5,
    },
    hideBtnFooter: true,
    hintBox: [
      {
        src: [
          "busy",
          "colourful",
          "cool",
          "crowded",
          "distinctive",
          "expensive",
          "high",
          "industrial",
          "large",
          "spectacular",
          "unusual",
          "well-known",
        ],
        width: 880,
        borderColor: "#d2232b",
        // marginLeft: 100,
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `Complete sentences 1–6 about places you know. 
          Use the adjectives in the box and your own ideas.`,
        color: "#d2232b",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id='0'></hintbox>
        <div style='margin-top:20px;line-height:50px'>
          <b>1</b>&ensp;#in#isn’t as# as# .<br>
          <b>2</b>&ensp;#is more#than# .<br>
          <b>3</b>&ensp;The#is the#in Europe.<br>
          <b>4</b>&ensp;#is the#in the world.<br>
          <b>5</b>&ensp;The most#in my town / city is# .<br>
          <b>6</b>&ensp;The#is as#as#.<br>
        </div> 
      
        
        `,
        answer: [
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
        ],
      },
    ],
  },

  5: {
    unit: "Unit 7",
    id: "SB9-2024-U7-P72-E5",
    component: T6,
    recorder: true,
    hideBtnFooter: true,
    // textAlign:"center",
    stylesTextInput: { width: 860, paddingTop: 10 },
    titleQuestion: [
      {
        num: "5",
        title: `<prefix></prefix> Work in pairs. Choose some 
          ‘must-see’ sports venues in Việt Nam. What events 
          are held there? Say why these venues are special, 
          and why people should visit them.`,
        color: "#d2232b",
        left: 50,
        width: "25cm",
      },
    ],
    hintBox: [
      {
        src: [
          "But it’s up to you",
          "So I’m leaning towards the film club",
          "What are your options",
          "The only bad thing about that club",
          "What are you up to",
          "Have you considered another basketball club",
          "It’s a tricky decision",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],

    // hideBtnFooter: true,
    questions: [
      {
        title: `
        
        <img style="width:20cm; height:8cm; margin-left: 50px" src='img/FriendsPlus/Page72/E5/1.jpg' />
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
