import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  8: {
    unit: "Progress Review 2",
    id: "SB9-2024-PR2-P48-E8",
    exerciseKey: "img/FriendsPlus/Page48/Key/E8answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "left",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 200,
      paddingTop: 5,
    },
    hintBox: [
      {
        src: [
          "belong to",
          "come across",
          "keep",
          "forget",
          "look forward",
          "recognise",
          "remind",
          "leave",
        ],
        width: 650,
        borderColor: "#4F3C94",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Complete the sentences with the correct form of the words in brackets.`,
        color: "#4F3C94",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
   
        <div style='display: flex; font-size: 22px; line-height: 50px; width: 26cm'>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br>
          <b>2</b>&ensp;<br>
          <b>3</b>&ensp;<br>
          <b>4</b>&ensp;<br>
          <b>5</b>&ensp;<br>
          <b>6</b>&ensp;<br>
          
        </div>

        <div style='width: 24cm'>
         <div>We felt # when we learned about the science of emotions. (surprise)</div>

         <div>Some colours are # for people. (relax)</div>

         <div>There was a real feeling of # at the concert. (excite)</div>

         <div>You can tell when people feel #. (bore)</div>

         <div>The students seemed # when they opened the exam paper. (worry)</div>

         <div>My brother had a look of # on his face. (annoy)</div>
        </div>
        </div>
        
        `,
        answer: [
          "surprised",
          "relaxing",
          "excitement",
          "bored",
          "worried",
          "annoyance",
        ],
      },
    ],
  },
  9: {
    unit: "Progress Review 2",
    id: "SB9-2024-PR2-P48-E9",
    exerciseKey: "img/FriendsPlus/Page48/Key/E9answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "left",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 160,
      paddingTop: 5,
    },
    hintBox: [
      {
        src: ["blooded", "eyed", "haired", "left", "open", "well"],
        width: 700,
        borderColor: "#4F3C94",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "9",
        title: `Complete the compound adjectives with the words.`,
        color: "#4F3C94",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='display: flex; font-size: 22px; line-height: 50px; width: 26cm'>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br>
          <b>2</b>&ensp;<br>
          <b>3</b>&ensp;<br>
          <b>4</b>&ensp;<br>
          <b>5</b>&ensp;<br>
          <b>6</b>&ensp;<br>
          
        </div>

        <div style='width: 24cm'>
         <div>He’s a good boy. He’s #-behaved.</div>

         <div>She’s tall and blue-# like her dad.</div>

         <div>I’m #-handed. I always write with that hand.</div>

         <div>There’s a beautiful long-# rabbit in the zoo.</div>

         <div>Is he #-minded? Does he like new ideas?</div>

         <div>That creature is a reptile. It’s cold-# .</div>
        </div>
        </div>
        
        `,
        answer: ["well", "eyed", "left", "haired", "open", "blooded"],
      },
    ],
  },
  10: {
    unit: "Progress Review 2",
    id: "SB9-2024-PR2-P48-E10",
    exerciseKey: "img/FriendsPlus/Page48/Key/E10answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "left",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 200,
      paddingTop: 5,
    },
    hintBox: [
      {
        src: [
          "can't have",
          "could be",
          "may feel",
          "may hear",
          "might discover",
          "must have",
        ],
        width: 880,
        borderColor: "#4F3C94",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "10",
        title: `Complete the sentences with the words.`,
        color: "#4F3C94",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='display: flex; font-size: 22px; line-height: 50px; width: 26cm'>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br><br>
          <b>2</b>&ensp;<br><br>
          <b>3</b>&ensp;<br>
          <b>4</b>&ensp;<br>
          <b>5</b>&ensp;<br>
          <b>6</b>&ensp;<br>
          
        </div>

        <div style='width: 25cm'>
         <div>Evidence shows that elephants # 
         feelings. Experts say that they express sadness in a number of different ways.</div>

         <div>Scientists think that when dolphins jump, 
         they # happy, but they aren’t sure.</div>

         <div>If you listen carefully, you #  
         the dolphins making noises to communicate.</div>

         <div>People feel that microorganisms # 
         emotions because they’re too small.</div>

         <div>It’s possible that scientists # 
         more about animal emotions in the future.</div>

         <div>Some small creatures # 
         unhappy at certain times, but we don’t 
         know for sure.</div>
        </div>
        </div>
        
        `,
        answer: [
          "must have",
          "could be",
          "may hear",
          "can't have",
          "might discover",
          "may feel",
        ],
      },
    ],
  },
  // 11: {
  //   unit: "Progress Review 2",
  //   id: "SB9-2024-PR2-P48-E11",
  //   exerciseKey: "img/FriendsPlus/Page48/Key/E11answerKey.png",
  //   audio: "Audios/1.31.mp3",
  //   component: Circle_Write,
  //   inputSize: 500,
  //   textAlign: "center",
  //   stylesTextInput: { width: 170, paddingTop: 10 },
  //   titleQuestion: [
  //     {
  //       num: "11",
  //       title: `<headphone name=1.31 src='Audios/1.31 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
  //       Listen to three situations. Match 1–8 to a–h.`,
  //       color: "#4F3C94",
  //       left: 50,
  //       width: "27cm",
  //     },
  //   ],
  //   // hideBtnFooter: true,
  //   question: {
  //     leftContent: 50,
  //     DrawLines: {
  //       multipleLine: false,
  //       boxMatch: [
  //         //LEFT
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "26px",
  //             left: "445px",
  //             width: "25px",
  //             height: "25px",
  //             borderRadius: "50%",
  //             border: "1px solid #2eb6e1",
  //             background: "white",
  //           },
  //         }, // 0
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "62px",
  //             left: "449px",
  //             width: "25px",
  //             height: "25px",
  //             borderRadius: "50%",
  //             border: "1px solid #2eb6e1",
  //             background: "white",
  //           },
  //         }, // 1
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "97px",
  //             left: "407px",
  //             width: "25px",
  //             height: "25px",
  //             borderRadius: "50%",
  //             border: "1px solid #2eb6e1",
  //             background: "white",
  //           },
  //         }, // 2
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "131px",
  //             left: "430px",
  //             width: "25px",
  //             height: "25px",
  //             borderRadius: "50%",
  //             border: "1px solid #2eb6e1",
  //             background: "white",
  //           },
  //         }, // 3
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "166px",
  //             left: "428px",
  //             width: "25px",
  //             height: "25px",
  //             borderRadius: "50%",
  //             border: "1px solid #2eb6e1",
  //             background: "white",
  //           },
  //         }, // 3
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "200px",
  //             left: "474px",
  //             width: "25px",
  //             height: "25px",
  //             borderRadius: "50%",
  //             border: "1px solid #2eb6e1",
  //             background: "white",
  //           },
  //         }, // 3

  //         //RIGHT
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "36px",
  //             left: "638px",
  //             width: "25px",
  //             height: "25px",
  //             borderRadius: "50%",
  //             border: "1px solid #2eb6e1",
  //             background: "white",
  //           },
  //         }, // 0
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "69px",
  //             left: "638px",
  //             width: "25px",
  //             height: "25px",
  //             borderRadius: "50%",
  //             border: "1px solid #2eb6e1",
  //             background: "white",
  //           },
  //         }, // 0
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "106px",
  //             left: "638px",
  //             width: "25px",
  //             height: "25px",
  //             borderRadius: "50%",
  //             border: "1px solid #2eb6e1",
  //             background: "white",
  //           },
  //         }, // 0
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "140px",
  //             left: "638px",
  //             width: "25px",
  //             height: "25px",
  //             borderRadius: "50%",
  //             border: "1px solid #2eb6e1",
  //             background: "white",
  //           },
  //         }, // 0
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "175px",
  //             left: "638px",
  //             width: "25px",
  //             height: "25px",
  //             borderRadius: "50%",
  //             border: "1px solid #2eb6e1",
  //             background: "white",
  //           },
  //         }, // 0
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "209px",
  //             left: "638px",
  //             width: "25px",
  //             height: "25px",
  //             borderRadius: "50%",
  //             border: "1px solid #2eb6e1",
  //             background: "white",
  //           },
  //         }, // 0
  //       ],
  //       answers: ["0-7", "1-6", "10-2", "11-3", "4-8", "5-9"],
  //       initialValue: [],
  //     },
  //     Write: {
  //       inputStyle: { width: 70 },
  //       answers: [],
  //       initialValue: [],
  //     },
  //     Circle: {
  //       initialWordStyle: {
  //         padding: "1px 4px",
  //         border: "2px solid",
  //         borderColor: "transparent",
  //         fontWeight: "bold",
  //       },
  //       selectWordStyle: {
  //         fontWeight: "bold",
  //         padding: "1px 4px",
  //         borderRadius: "50%",
  //         color: "black",
  //         border: "2px solid",
  //         borderColor: "#00aeef",
  //       },
  //       limitSelect: 1,
  //       listWords: [
  //         "different / the_same",
  //         "base / continuous", //1
  //         "use_to / used_to", //2
  //         "use_to / used_to ",
  //       ],
  //       answers: [""],
  //       initialValue: [],
  //     },
  //     Layout: `
  //     <div style='position: relative;'>
  //      <img style="width: 22cm; height: 7cm" src='img/FriendsPlus/Page46/E2/1.jpg' />

  //       <input id='0' type= 'boxMatch' />
  // 		  <input id='1' type= 'boxMatch' />
  // 		  <input id='2' type= 'boxMatch' />
  // 		  <input id='3' type= 'boxMatch' />
  //       <input id='4' type= 'boxMatch' />
  //       <input id='5' type= 'boxMatch' />

  //       <input id='6' type= 'boxMatch' />
  // 		  <input id='7' type= 'boxMatch' />
  // 		  <input id='8' type= 'boxMatch' />
  //       <input id='9' type= 'boxMatch' />
  //       <input id='10' type= 'boxMatch' />
  //       <input id='11' type= 'boxMatch' />

  //      </div>

  //     `,
  //   },
  // },
  11: {
    unit: "Progress Review 2",
    id: "SB9-2024-PR2-P48-E11",
    exerciseKey: "img/FriendsPlus/Page48/Key/E11answerKey.png",
    audio: "Audios/1.31.mp3",
    component: T6,
    maxLength: 1,
    // hideBtnFooter:true,
    textAlign: "left",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 60,
      paddingTop: 5,
    },
    hintBox: [
      {
        src: [
          "can't have",
          "could be",
          "may feel",
          "may hear",
          "might discover",
          "must have",
        ],
        width: 700,
        borderColor: "#4F3C94",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "11",
        title: `<headphone name=1.31 src='Audios/1.31 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
        Complete the sentences with the words.`,
        color: "#4F3C94",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display: flex; font-size: 22px; line-height: 50px; width: 26cm; margin-left: 100px'>
        <div style='margin-right: 50px'>
          <b>1</b>&ensp; The swimming instructor is<br>
          <b>2</b>&ensp; The swimmers should<br>
          <b>3</b>&ensp; Martha sounds<br>
          <b>4</b>&ensp; Martha wants Katie to<br>
          <b>5</b>&ensp; Katie thinks Martha should<br>
          <b>6</b>&ensp; The drama teacher is very<br>
          <b>7</b>&ensp; Jack needs to<br>
          <b>8</b>&ensp; The other students have to<br>
        </div>

        <div style='margin-right: 10px'>
          <b>a</b>&ensp; patient.<br>
          <b>b</b>&ensp; calm down.<br>
          <b>c</b>&ensp; excited.<br>
          <b>d</b>&ensp; concentrate.<br>
          <b>e</b>&ensp; speed up.<br>
          <b>f</b>&ensp; annoyed.<br>
          <b>g</b>&ensp; cheer up.<br>
          <b>h</b>&ensp; slow down.<br>
        </div>
        </div>

        <div style='font-size: 22px; line-height: 50px; width: 26cm; margin-left: 100px'>
        <b>Answer:</b><br>
        <div style='display: flex; '>
          <div style='margin-right: 100px'>
          <b>1</b>&ensp;- #<br>
          <b>2</b>&ensp;- #<br>
          <b>3</b>&ensp;- #<br>
          <b>4</b>&ensp;- #<br>
          </div>
          <div>
          <b>5</b>&ensp;- #<br>
          <b>6</b>&ensp;- #<br>
          <b>7</b>&ensp;- #<br>
          <b>8</b>&ensp;- #<br>
          </div>
        </div>
        </div>
        
        `,
        answer: ["c", "h", "f", "g", "b", "a", "e", "d"],
      },
    ],
  },
};

export default json;
