import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  8: {
    unit: "Progress Review 3",
    id: "SB9-2024-PR3-P68-E8",
    exerciseKey: "img/FriendsPlus/Page68/Key/E8answerKey.png",
    // audio: "Audios/2.08.mp3",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "left",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 23,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 50,
      paddingTop: 5,
      fontWeight: 600,
    },
    hintBox: [
      {
        src: [
          "Finally",
          "Furthermore",
          "suggests",
          "I believe",
          "in favour of",
          "In the first place",
        ],
        width: 850,
        borderColor: "#4F3C94",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Complete the words for types of people using 
        sentences 1–6. Then solve the mystery word.`,
        color: "#4F3C94",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative; font-size: 21px; line-height: 45px; margin-left: 90px'>
          <img style="width:21cm; height:13cm" src='img/FriendsPlus/Page68/E8/1.jpg' />
          
          <div style='position: absolute; top: 51px; left: 250px; '>#</div>
          <div style='position: absolute; top: 51px; left: 314px; '>#</div>
          <div style='position: absolute; top: 51px; left: 378px; '>#</div>
          <div style='position: absolute; top: 51px; left: 442px; '>#</div>
          <div style='position: absolute; top: 51px; left: 505px; '>#</div>

          <div style='position: absolute; top: 118px; left: 186px; '>#</div>
          <div style='position: absolute; top: 118px; left: 250px; '>#</div>
          <div style='position: absolute; top: 118px; left: 314px; '>#</div>
          <div style='position: absolute; top: 118px; left: 378px; '>#</div>
          <div style='position: absolute; top: 118px; left: 442px; '>#</div>

          <div style='position: absolute; top: 189px; left: 123px; '>#</div>
          <div style='position: absolute; top: 189px; left: 186px; '>#</div>
          <div style='position: absolute; top: 189px; left: 250px; '>#</div>
          <div style='position: absolute; top: 189px; left: 314px; '>#</div>
          <div style='position: absolute; top: 189px; left: 378px; '>#</div>
          <div style='position: absolute; top: 189px; left: 442px; '>#</div>
          <div style='position: absolute; top: 189px; left: 505px; '>#</div>

          <div style='position: absolute; top: 259px; left: 250px; '>#</div>
          <div style='position: absolute; top: 259px; left: 314px; '>#</div>
          <div style='position: absolute; top: 259px; left: 378px; '>#</div>
          <div style='position: absolute; top: 259px; left: 442px; '>#</div>
          <div style='position: absolute; top: 259px; left: 505px; '>#</div>
          <div style='position: absolute; top: 259px; left: 569px; '>#</div>
          <div style='position: absolute; top: 259px; left: 633px; '>#</div>

          <div style='position: absolute; top: 329px; left: 186px; '>#</div>
          <div style='position: absolute; top: 329px; left: 250px; '>#</div>
          <div style='position: absolute; top: 329px; left: 314px; '>#</div>
          <div style='position: absolute; top: 329px; left: 378px; '>#</div>
          <div style='position: absolute; top: 329px; left: 442px; '>#</div>
          <div style='position: absolute; top: 329px; left: 505px; '>#</div>
          <div style='position: absolute; top: 329px; left: 569px; '>#</div>
          <div style='position: absolute; top: 329px; left: 633px; '>#</div>
          <div style='position: absolute; top: 329px; left: 697px; '>#</div>

          <div style='position: absolute; top: 397px; left: 60px; '>#</div>
          <div style='position: absolute; top: 397px; left: 123px; '>#</div>
          <div style='position: absolute; top: 397px; left: 186px; '>#</div>
          <div style='position: absolute; top: 397px; left: 250px; '>#</div>
          <div style='position: absolute; top: 397px; left: 314px; '>#</div>
          <div style='position: absolute; top: 397px; left: 378px; '>#</div>
          <div style='position: absolute; top: 397px; left: 442px; '>#</div>
          <div style='position: absolute; top: 397px; left: 505px; '>#</div>
          <div style='position: absolute; top: 397px; left: 569px; '>#</div>

    

          <div style='display: flex; font-size: 22px; line-height: 50px; width: 26cm'>
          <div style='margin-right: 10px'>
            <b>1</b>&ensp;<br>
            <b>2</b>&ensp;<br>
            <b>3</b>&ensp;<br>
            <b>4</b>&ensp;<br>
            <b>5</b>&ensp;<br>
            <b>6</b>&ensp;<br>

          </div>

          <div style=''>
           <div>I tend to do things alone. I don’t like groups.</div>

           <div>I’m not keen on conforming to society.</div>

           <div>My head isn’t in the clouds. I definitely like to 
           live in the real world!</div>

           <div>I often use my imagination and I like stories.</div>

           <div>I’m not very positive about the future.</div>

           <div>I’m always at the centre of everything and I 
           love talking to all my friends.</div>
          </div>
          </div>
          <div>Mystery word: <input id=42 width='400px' /></div>
          </div>
        
        `,
        answer: [
          "l",
          "o",
          "n",
          "e",
          "r",
          "r",
          "e",
          "b",
          "e",
          "l",
          "r",
          "e",
          "a",
          "l",
          "i",
          "s",
          "t",
          "d",
          "r",
          "e",
          "a",
          "m",
          "e",
          "r",
          "p",
          "e",
          "s",
          "s",
          "i",
          "m",
          "i",
          "s",
          "t",
          "e",
          "x",
          "t",
          "r",
          "o",
          "v",
          "e",
          "r",
          "t",
          "leader",
        ],
      },
    ],
  },
  9: {
    unit: "Progress Review 3",
    id: "SB9-2024-PR3-P68-E9",
    exerciseKey: "img/FriendsPlus/Page68/Key/E9answerKey.png",
    // audio: "Audios/2.08.mp3",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "left",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 200,
      paddingTop: 5,
    },
    hintBox: [
      {
        src: ["come", "design", "enter", "learn", "leave", "swim", "watch"],
        width: 850,
        borderColor: "#4F3C94",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "9",
        title: `Complete the idioms with the correct 
        word. Then match 1–4 with a–d.`,
        color: "#4F3C94",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display: flex; font-size: 22px; line-height: 50px; width: 26cm'>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br>
          <b>2</b>&ensp;<br>
          <b>3</b>&ensp;<br>
          <b>4</b>&ensp;<br>
          
        </div>

        <div style='width: 26cm'>
         <div>She is a(n) # person. <input id=4 width='50px'/></div>

         <div>Don’t judge a # by its cover. <input id=5 width='50px'/></div>

         <div>Stand out from the #. <input id=6 width='50px'/></div>

         <div>He is a(n) #. <input id=7 width='50px'/></div>

        </div>
        </div>

        <div style='display: flex; font-size: 22px; line-height: 50px; width: 26cm'>
        <div style='margin-right: 10px'>
          <b>a</b>&ensp;<br>
          <b>b</b>&ensp;<br>
          <b>c</b>&ensp;<br>
          <b>d</b>&ensp;<br>
          
        </div>

        <div style='width: 26cm'>
         <div>A person who wakes up, arrives to work, etc. before the usual time.</div>

         <div>A person who is practical and close to reality.</div>

         <div>Appearances don’t always tell you everything.</div>

         <div>Try not to be the same as other people.</div>

        </div>
        </div>
        
        `,
        answer: [
          "down-to-earth",
          "b",
          "book",
          "c",
          "crowd",
          "d",
          "early bird",

          "a",
        ],
      },
    ],
  },
  10: {
    unit: "Progress Review 3",
    id: "SB9-2024-PR3-P68-E10",
    exerciseKey: "img/FriendsPlus/Page68/Key/E10answerKey.png",
    component: T6,
    // audio: "Audios/1.33.mp3",
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 23,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 180,
      paddingTop: 5,
    },
    selectStyle: {
      height: 35,
      width: 56,
      textAlign: "center",
      fontSize: 21,
    },
    selectOptionRandom: true,
    selectOptionValues: ["R", "E"],
    titleQuestion: [
      {
        num: "10",
        title: `Correct the bold words in the sentences. Then 
        write <i>R</i> (reflexive) or <i>E</i> (emphatic).`,
        color: "#4F3C94",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `

        <div style='display: flex; font-size: 23px;  width: 27cm'>
        
        <div>
        <div style='margin-bottom: 24px'><b>1</b>&ensp;</div><br>
        <div style='margin-bottom: 24px'><b>2</b>&ensp;</div><br>
        <div style='margin-bottom: 25px'><b>3</b>&ensp;</div><br>
        <div style='margin-bottom: 25px'><b>4</b>&ensp;</div><br>
        <div style='margin-bottom: 25px'><b>5</b>&ensp;</div><br>
        <div style='margin-bottom: 20px'><b>6</b>&ensp;</div><br>
        </div>

        <div style=''>
          <div>
            <div style="">I made this paella <b> yourself </b>. #&ensp; <select id=6></select></div>

            <div style="">We enjoyed <b> themselves </b> on the adventure 
            holiday. #&ensp;<select id=7></select></div> 

            <div style="">The students carried the big tents <b> yourselves </b>. 
            Nobody helped them. #&ensp;<select id=8></select></div>

            <div style="">Aren’t you going to introduce <b> ourselves </b> to 
            everyone? #&ensp; <select id=9></select></div>

            <div style="">Susie cut <b> myself </b> in the kitchen. #&ensp; <select id=10></select></div>

            <div style="">The personality quiz <b> herself </b> is fun, but I don’t 
            agree with the answers! #&ensp; <select id=11></select></div>


          </div>
        </div>
        `,
        // InputRong: true,
        answer: [
          "myself",
          "ourselves",
          "themselves",
          "yourselves",
          "herself",
          "itself",
          "E",
          "R",
          "E",
          "R",
          "R",
          "E",
        ],
      },
    ],
  },
  11: {
    unit: "Progress Review 3",
    id: "SB9-2024-PR3-P68-E11",
    exerciseKey: "img/FriendsPlus/Page68/Key/E11answerKey.png",
    component: T6,
    audio: "Audios/2.09.mp3",
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 23,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 180,
      paddingTop: 5,
    },

    titleQuestion: [
      {
        num: "11",
        title: `<headphone name=2.09 src='Audios/2.09 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
        Listen to the radio show. Correct the information in <b>bold</b>.`,
        color: "#4F3C94",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `

        <div style='display: flex; font-size: 23px;  width: 27cm; line-height: 55px'>
        
        <div>
        <div style=''><b>1</b>&ensp;</div>
        <div style=''><b>2</b>&ensp;</div>
        <div style=''><b>3</b>&ensp;</div><br>
        <div style=''><b>4</b>&ensp;</div>
        <div style=''><b>5</b>&ensp;</div>
        <div style=''><b>6</b>&ensp;</div>
        </div>

        <div style=''>
          <div>The second person that you can hear on the radio show is a <b>student</b>. #</div>
          <div>Amira interviews <b>four</b> students. #</div>
          <div>Amira thinks that one benefit of volunteering 
          is that it helps with <b>independence</b>. #</div>
          <div>Rachel has become a lot more <b>responsible</b>
          during her time as a volunteer. #</div>
          <div>Sam thinks that you need to be really 
          <b>easy-going</b> to volunteer at a zoo. #</div>
          <div>The <b>presenters</b> are the last people to speak on 
          the show. #</div>
        </div>
        `,
        // InputRong: true,
        answer: [
          "presenter",
          "two",
          "confidence",
          "sociable",
          "hardworking",
          "students",
        ],
      },
    ],
  },
};

export default json;
