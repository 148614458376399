import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  5: {
    unit: "Unit 1",
    id: "SB9-2024-U1-P17-E5",
    // audio: "img/FriendsPlus/Page110/Audio/audio-e2-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "85%",
      // marginLeft: 150,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page17/E5/1.jpg" }]],
  },
  1: {
    unit: "Unit 1",
    id: "SB9-2024-U1-P17-E1",
    // audio: "Audios/1.09.mp3",
    exerciseKey: "img/FriendsPlus/Page17/Key/E1answerKey.png",
    component: T6,
    // textAlign: "center",
    // maxLength:5,
    textareaStyle: {
      // background: 'none',
      width: 800,
      fontSize: 22,
      // height: 70,
      paddingTop: 5,
    },

    titleQuestion: [
      {
        num: "1",
        title: `Read Mary’s blog post. Was this a good, bad or mixed experience for 
        her? Why?`,
        color: "#00A850",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='font-size: 22px; line-height: 30px'>
          <img style='height:13cm; margin-left: -30px' src="img/FriendsPlus/Page17/E1/1.jpg"/><br>
          <div style='font-weight: 600'>Was this a good, bad or mixed experience for 
          her?</div>
          <div><textarea id='0' rows='1'></div>
          <div style='font-weight: 600'>Why?</div>
          <div><textarea id='1' rows='2'></div>

        </div>
       
        `,
        answer: [
          "It was a mixed experience.",
          "Because there were some ups and downs.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 1",
    id: "SB9-2024-U1-P17-E2",
    // audio: "Audios/1.09.mp3",
    exerciseKey: "img/FriendsPlus/Page17/Key/E2answerKey.png",
    component: T6,
    // textAlign: "center",
    // maxLength:5,
    stylesTextInput: {
      background: "none",
      borderBottom: "1px solid",
      fontSize: 22,
      height: 27,
      width: 880,
      paddingTop: 5,
      // textTransform: 'uppercase',
      // textAlign: "center",
    },

    titleQuestion: [
      {
        num: "2",
        title: `Read the text again and answer the questions.`,
        color: "#00A850",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='font-size: 22px; line-height: 35px'>
          <img style='height:13cm; margin-left: -30px' src="img/FriendsPlus/Page17/E1/1.jpg"/><br>
          <div style='font-weight: 600'><b>1</b>&ensp; Who had the idea to go to a theme park?</div>
          <div>#</div>
          <div style='font-weight: 600'><b>2</b>&ensp; Why didn’t they go into the theme park?</div>
          <div>#</div>
          <div style='font-weight: 600'><b>3</b>&ensp; What were they doing when the rain stopped?</div>
          <div>#</div>
        </div>
       
        `,
        answer: [
          "Mary's dad",
          "Because it was raining",
          "Getting out of the car",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 1",
    id: "SB9-2024-U1-P17-E3",
    // audio: "Audios/3-07.mp3",
    exerciseKey: "img/FriendsPlus/Page17/Key/E3answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "3",
        title: `Check the meaning of the words in <span style='color: '></span>blue in Mary’s 
        blog post. Then choose the correct options in 1–5.`,
        color: "#00A850",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "afterwards / meanwhile",
          "just_as / until", //2
          "By_the_time / Finally", //3
          "while / before",
          "In_the_end / The_moment_that",
        ],
        answers: ["0-0", "1-4", "2-0", "3-4", "4-4"],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative; margin-left: 50px'>
       <div style='width: 25cm; line-height: 50px'>
         <div>
           <div style=""><b>1</b> &ensp;We went shopping and <input id='0' type='Circle' /> I went to a café.
           </div>
    
           <div style=""><b>2</b> &ensp;I stayed in the café <input id='1' type='Circle' /> it closed.
           </div>
    
           <div style=""><b>3&ensp;</b> 
           <input id='2' type='Circle' /> we arrived home, I was really tired.
           </div>
    
           <div style=""><b>4</b> &ensp;We had made some sandwiches <input id='3' type='Circle' /> we left home.
           </div>

           <div style=""><b>5&ensp;</b>
           <input id='4' type='Circle' /> we said goodbye, I felt sad.
           </div>
    
         </div>
         <img style="width:16.5cm; margin-top: 30px" src='img/FriendsPlus/Page17/E3/1.jpg' />
       </div>
    
       </div>
      
      `,
    },
  },
  4: {
    unit: "Unit 1",
    id: "SB9-2024-U1-P17-E4",
    // exerciseKey: "img/FriendsPlus/Page9/Key/E7answerKey.png",
    component: T6,
    //  maxLength: 30,
    // recorder: true,
    hideBtnFooter: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 800,
      fontSize: 22,
      // height: 120,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "4",
        title: `<img style='height:1.1cm; width: 4.8cm; margin-bottom: 5px' src='img/FriendsPlus/icon/6.jpg'/> 
        Follow the steps in the Writing Guide.`,
        color: "#00A850",
        left: 50,
        width: "26cm",
      },
    ],
    questions: [
      {
        title: `
        <img style="width:15cm; height:22cm; margin-left: 170px" src='img/FriendsPlus/Page17/E4/1.jpg' />
        <div style='margin-left: 50px'><textarea id='0' rows='8'></div>
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
