import DesignTypeIn from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Match_Write from "../../components/ExcerciseTypes/Match_Write";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  6: {
    unit: "Unit 5",
    id: "SB9-2024-U5-P50-E1",
    // audio: "img/FriendsPlus/Page110/Audio/audio-e6-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "55%",
      marginLeft: 250,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page50/E6/1.jpg" }]],
  },
  1: {
    unit: "Unit 4",
    id: "SB9-2024-U4-P40-E2",
    exerciseKey: "img/FriendsPlus/Page40/Key/E2answerKey.png",
    component: T6,
    audio: "Audios/1.25.mp3",
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "1px solid",
      fontSize: 23,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "left",
      width: 800,
      paddingTop: 5,
    },
    selectStyle: {
      height: 35,
      width: 106,
      textAlign: "center",
      fontSize: 22,
    },
    selectOptionRandom: true,
    selectOptionValues: ["true", "false"],
    titleQuestion: [
      {
        num: "2",
        title: `<headphone name=1.25 src='Audios/1.25 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
        Read and listen to the text. Write 
        <i>true</i> or <i>false</i> and correct the false sentences.`,
        color: "#D92F3B",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `

        <div style='position: relative; height: 600px; font-size: 23px'>
        <div style='display: flex; position: absolute; top: 15px; left: 38px; width: 25cm; line-height: 44px'>
          <div>
            <div style="display: flex"><b>1</b> &ensp;The author gets on well with her pets.&ensp; <select id=5></select></div>
            <div><input id=0/></div>

            <div style="display: flex"><b>2</b> &ensp;Studies today show that animals don’t have 
            thoughts or feelings. &ensp;<select id=6></select></div> 
            <div><input id=1/></div>

            <div style="display: flex"><b>3</b> &ensp;Chimpanzees show sympathy. &ensp;<select id=7></select></div>
            <div><input id=2/></div>

            <div style="display: flex"><b>4</b> &ensp;Small-brained animals don’t show sympathy. &ensp; <select id=8></select></div>
            <div><input id=3/></div>

            <div style="display: flex"><b>5</b> &ensp;In the future, we’ll know more about animals’ emotions. &ensp; <select id=9></select></div>
            <div><input id=4/></div>
          </div>
        </div>
        `,
        InputRong: true,
        answer: [
          "",
          "Studies today show that animals may have thoughts or feelings.",
          "",
          "Small-brained animals like crows and mice show sympathy.",
          "",
          "true",
          "false",
          "true",
          "false",
          "true",
        ],
      },
    ],
  },
};
export default json;
