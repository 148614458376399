import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  5: {
    unit: "Unit 7",
    id: "SB9-2024-U7-P77-E5",
    // audio: "img/FriendsPlus/Page110/Audio/audio-e6-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "65%",
      marginLeft: 150,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page77/E5/1.jpg" }]],
  },
  1: {
    unit: "Unit 7",
    id: "SB9-2024-U7-P77-E1",
    // audio:"Audios/2-14.mp3",
    exerciseKey: "img/FriendsPlus/Page77/Key/E1answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    stylesTextInput: {
      width: 28,
      fontSize: 17,
      background: "none",
      paddingTop: 5,
      fontWeight: "bold",
      padding: 2,
    },
    textareaStyle: {
      width: 1000,
      paddingTop: 5,
      resize: "none",
      marginBottom: -70,
    },
    hintBox: [
      {
        src: [
          "are discovered",
          "hasn’t been removed",
          "might be hidden",
          "was examined",
          "was killed",
          "will be displayed",
        ],
        width: 500,
        borderColor: "#01a951",
        marginLeft: 200,
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Read the tourist information leaflet and answer 
          the questions.`,
        color: "#01a951",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display:flex;justify-content:space-between;margin-bottom:30px'>
          <img  style='height:15cm' src="img/FriendsPlus/Page77/E1/1.jpg"/>
          <img  style='height:15cm' src="img/FriendsPlus/Page77/E1/2.jpg"/>
        </div>
        <div style='position:relative'>
          <b>1</b>&ensp;Which street is the leaflet about?<br><textarea id=0 rows=2></textarea><br>
          <b>2</b>&ensp;When were the landmarks built or installed?<br><textarea id=1 rows=2></textarea><br>
          <b>3</b>&ensp;What style is each building?<br><textarea id=2 rows=2></textarea><br>
          <b>4</b>&ensp;Where is the visitor centre and what can you see there?<br><textarea id=3 rows=2></textarea><br>
          <b>5</b>&ensp;Where can you buy some wonderful souvenirs?<br><textarea id=4 rows=2></textarea><br>
        </div>
        `,
        answer: [
          "The leaflet is about Công Xã Paris Street.",
          "The Central Post Office was built between 1886 and 1891. The Notre Dame Cathedral was built in the late 19th century.",
          "The Central Post Office is in the Gothic and Renaissance styles. The Notre Dame Cathedral is in the neo-Romanesque style.",
          "The visitor center is near the entrance of the Central Post Office. You can see there maps and brochures that will help you explore the area.",
          "You can buy some wonderful souvenirs at the Central Post Office.",
        ],
        // notSymbol: true,
      },
    ],
  },

  2: {
    unit: "Unit 7",
    id: "SB9-2024-U7-P77-E2",
    // audio:"Audios/2-14.mp3",
    exerciseKey: "img/FriendsPlus/Page77/Key/E2answerKey.png",
    component: T6,
    textAlign: "center",
    // maxLength:5,
    hideBtnFooter: true,
    stylesTextInput: { width: 320, paddingTop: 10 },
    hintBox: [
      {
        src: ["finish", "leave", "study", "not read", "close", "not use"],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 150,
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Study the key phrases and find them in the text.`,
        color: "#01a951",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img  style='height:75mm;margin:0px 100px' src="img/FriendsPlus/Page77/E2/1.jpg"/>
        
        <div style='display:flex;justify-content:space-between;margin-bottom:30px'>
          <img  style='height:15cm' src="img/FriendsPlus/Page77/E1/1.jpg"/>
          <img  style='height:15cm' src="img/FriendsPlus/Page77/E1/2.jpg"/>
        </div>
        `,
        answer: [
          "She prefers photo B.|She prefers photo B. / Tegan prefers photo B.|Tegan prefers photo B.",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 7",
    id: "SB9-2024-U7-P77-E3",
    checkDuplicated: true,
    video: "",
    exerciseKey: "img/FriendsPlus/Page77/Key/E3answerKey.png",
    component: T6,
    inputSize: 500,

    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 23,
      height: 27,
      textAlign: "center",
      width: 100,
      paddingTop: 5,
      fontWeight: 600,
    },
    textAlign: "center",
    hintBox: [
      {
        src: [
          "belong to",
          "come across",
          "keep",
          "forget",
          "look forward",
          "recognise",
          "remind",
          "leave",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 150,
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Study the table. Then add the words in blue
          from the texts to the table.`,
        color: "#01a951",
        left: 50,
        width: "28cm",
        // prefix:{text:'PRONUNCIATION: Sentence stress'}
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <table style="border: 3px solid rgb(1,169,81);background:rgb(194,226,202);width: 55%; border-collapse: collapse; margin-bottom: 20px;text-align: center; margin-left: 150px">
          <tr>
            <th style="border: 3px solid rgb(1,169,81); padding: 8px; background-color:rgb(79,188,123); color: white">Direction</th>
            <th style="border: 3px solid rgb(1,169,81); padding: 8px; background-color:rgb(79,188,123); color: white">Location</th>
          </tr>
          <tr>
            <td style="border-left: 3px solid rgb(1,169,81); border-right:3px solid rgb(1,169,81); padding: 8px;">left</td>
            <td style="border-right: 3px solid rgb(1,169,81); padding: 8px;">next to</td>
          </tr>
          <tr>
            <td style="border-left: 3px solid rgb(1,169,81); border-right:3px solid rgb(1,169,81); padding: 8px;">down</td>
            <td style="border-right: 3px solid rgb(1,169,81); padding: 8px;">under</td>
          </tr>
          <tr>
            <td style="border-left: 3px solid rgb(1,169,81); border-right:3px solid rgb(1,169,81); padding: 8px;">around</td>
            <td style="border-right: 3px solid rgb(1,169,81); padding: 8px;">there</td>
          </tr>
          <tr>
            <td style="border-left: 3px solid rgb(1,169,81); border-right:3px solid rgb(1,169,81); padding: 8px;">#</td>
            <td style="border-right: 3px solid rgb(1,169,81); padding: 8px;">#</td>
          </tr>
          <tr>
            <td style="border-left: 3px solid rgb(1,169,81); border-right:3px solid rgb(1,169,81); padding: 8px;">#</td>
            <td style="border-right: 3px solid rgb(1,169,81); padding: 8px;">#</td>
          </tr>
          <tr>
            <td style="border-left: 3px solid rgb(1,169,81); border-right:3px solid rgb(1,169,81); padding: 8px;">#</td>
            <td style="border-right: 3px solid rgb(1,169,81); padding: 8px;">#</td>
          </tr>
          <tr>
            <td style="border-left: 3px solid rgb(1,169,81); border-right:3px solid rgb(1,169,81); padding: 8px;">#</td>
            <td style="border-right: 3px solid rgb(1,169,81); padding: 8px;">#</td>
          </tr>
        </table>
        <div style='display:flex;justify-content:space-between;margin-bottom:30px'>
          <img  style='height:15cm' src="img/FriendsPlus/Page77/E1/1.jpg"/>
          <img  style='height:15cm' src="img/FriendsPlus/Page77/E1/2.jpg"/>
        </div>
        `,

        answer: [
          "|up",
          "in|across|near",
          "|up",
          "in|here|across|near",
          "|up",
          "in|here|across|near",
          "|up",
          "in|here|across|near",
        ],
        InputRong: true,
      },
    ],
  },
  4: {
    unit: "Unit 7",
    id: "SB9-2024-U7-P77-E4",
    exerciseKey: "img/FriendsPlus/Page77/Key/E4answerKey.png",
    component: T6,
    // maxLength:4,
    isHello: true,
    textAlign: "center",
    textareaStyle: {
      width: 500,
      paddingTop: 3,
    },
    stylesTextInput: {
      width: 200,
      fontSize: 23,
      background: "none",
      borderBottom: "none",
      paddingTop: 5,
    },
    hintBox: [
      {
        src: [
          "cover",
          "discover",
          "examine",
          "keep",
          "label make",
          "put",
          "take",
          "not touch",
          "unearth",
          "write",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 100,
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: ` Complete the newspaper article. Use past, present 
          and future passive forms of the verbs in the box.`,
        color: "#01a951",
        left: 50,
        width: "28cm",
        prefix: [
          {
            text: "WRITE",
            icon: "fas fa-pen",
          },
        ],
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
          <div style='display:flex'>
            <div>
              <textarea id=0 rows=10></textarea>
            </div>
            <img style='height:18cm' src="img/FriendsPlus/Page77/E4/1.jpg"/>
          </div>
      
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
