import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Culture",
    id: "SB9-2024-Cul-P92-E1",
    exerciseKey: "img/FriendsPlus/Page92/Key/E1answerKey.png",
    component: T6,
    // audio: "Audios/3.02.mp3",
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      // textAlign: "center",
      width: 500,
      paddingTop: 5,
      // fontWeight: 600,
    },
    textareaStyle: {
      // background: 'none',
      width: 600,
      fontSize: 22,
      // height: 70,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "1",
        title: `Look at the posters and the title of the article. 
        What do you think the text on the poster means?`,
        color: "#5cc9dd",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative; font-size: 22px'>
          <img src="img/FriendsPlus/Page92/E1/1.jpg" style='height:21cm; margin-top: 50px'/>
          <div style=" position: absolute; top: 10px; left: 9px; ">
            <b> What do you think the text on the poster means?</b><br><textarea id='0' rows='2'>.<br>

          </div>
        </div> 
      
        
        `,
        answer: [""],
      },
    ],
  },
  2: {
    unit: "Culture",
    id: "SB9-2024-Cul-P92-E2",
    exerciseKey: "img/FriendsPlus/Page92/Key/E2answerKey.png",
    component: T6,
    audio: "Audios/3.04.mp3",
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 50,
      paddingTop: 5,
      maxLength: 1,
      fontWeight: 600,
    },
    textareaStyle: {
      // background: 'none',
      width: 600,
      fontSize: 22,
      // height: 70,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "2",
        title: `<headphone name=3.04 src='Audios/3.04 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
        Read and listen to the article. Check the meaning of the adjective / noun + noun 
        combinations in <span style='color: #5cc9dd'>blue</span>. Then match 1–6 with a–f to make new combinations.`,
        color: "#5cc9dd",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page92/E1/1.jpg" style='height:21cm; margin-top: 50px'/>
        <div style='position: relative; font-size: 22px; line-height: 45px; margin-left: 100px'>
          <img src="img/FriendsPlus/Page92/E2/1.jpg" style='height:6cm; margin-top: 50px'/>
          <div style="display: flex">
            <div style='margin-right: 50px'>
              <b>1</b> - # <br>
              <b>2</b> - # <br>
              <b>3</b> - # <br>
            </div>
            <div>
              <b>4</b> - # <br>
              <b>5</b> - # <br>
              <b>6</b> - # <br>
            </div>

          </div>
        </div> 
      
        
        `,
        answer: ["e", "f", "b", "c", "d", "a"],
      },
    ],
  },
  3: {
    unit: "Culture",
    id: "SB9-2024-Cul-P92-E3",
    exerciseKey: "img/FriendsPlus/Page92/Key/E3answerKey.png",
    component: T6,
    // audio: "Audios/1.33.mp3",
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "1px solid",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "left",
      width: 880,
      paddingTop: 5,
    },
    selectStyle: {
      height: 35,
      width: 106,
      textAlign: "center",
      fontSize: 21,
    },
    selectOptionRandom: true,
    selectOptionValues: ["true", "false"],
    titleQuestion: [
      {
        num: "3",
        title: `Read the article again. Write <i>true</i> or <i>false</i> and 
        correct the false sentences.`,
        color: "#5cc9dd",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `

        <div style='position: relative; font-size: 22px'>
        <img src="img/FriendsPlus/Page92/E1/1.jpg" style='height:20cm'/>
        <div style='display: flex;  width: 27cm; line-height: 44px; margin-top: 20px'>
          <div>
            <div style="display: flex"><b>1</b> &ensp;American shops are very busy on Black Friday.&ensp; <select id=6></select></div>
            <div>#.</div>

            <div style="display: flex"><b>2</b> &ensp;Retailers in many countries offer lower prices on 
            Thanksgiving Day. &ensp;<select id=7></select></div> 
            <div>#.</div>

            <div style="display: flex"><b>3</b> &ensp;The <i>Adbusters</i> organisation wants people to 
            participate in Black Friday shopping.
             &ensp;<select id=8></select></div>
            <div>#.</div>

            <div style="display: flex"><b>4</b> &ensp;Buy Nothing Day is always in November.
             &ensp;<select id=9></select></div>
            <div>#.</div>

            <div style="display: flex"><b>5</b> &ensp;Kalle Lasn thinks that consumerism causes 
            environmental problems. &ensp; <select id=10></select></div>
            <div>#.</div>

            <div style="display: flex"><b>6</b> &ensp;The organisers of Buy Nothing Day don’t expect 
            people to change their way of life. &ensp; <select id=11></select></div>
            <div>#.</div>

          </div>
        </div>
        `,
        InputRong: true,
        answer: [
          "",
          "They offer lower prices on Black Friday",
          "It wants people not to take part",
          "",
          "",
          "They expect people to make some changes to their lifestyle",
          "true",
          "false",
          "false",
          "true",
          "true",
          "false",
        ],
      },
    ],
  },
  4: {
    unit: "Culture",
    id: "SB9-2024-Cul-P92-E4",
    exerciseKey: "img/FriendsPlus/Page92/Key/E4answerKey.png",
    // audio: "Audios/2.21.mp3",
    component: T6,
    // textAlign: "center",
    // maxLength:5,
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 23,
      // height: 70,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "4",
        title: `<prefix text='YOUR CULTURE'></prefix>
        Work in pairs. Ask and answer the questions about Việt Nam.`,
        color: "#5cc9dd",
        left: 50,
        width: "27cm",
      },
    ],
    hideBtnFooter: true,
    // giaiThua: true,
    questions: [
      {
        title: `
        
        <div style='font-size: 23px; line-height: 40px; width: 26cm; margin-top: 20px'>
          <div style='font-weight: 600'><b>1</b>&ensp;What is the busiest shopping period in 
          Việt Nam?</div>
          <div><textarea id='0' rows='2'></div>

          <div style='font-weight: 600'><b>2</b>&ensp;Do retailers promote special offers on Black 
          Friday in Việt Nam?</div>
          <div><textarea id='1' rows='2'></div>

        </div>
       
        `,
        answer: [""],
      },
    ],
  },
  5: {
    unit: "Culture",
    id: "SB9-2024-Cul-P92-E5",
    exerciseKey: "img/FriendsPlus/Page92/Key/E5answerKey.png",
    // audio: "Audios/2.21.mp3",
    component: T6,
    recorder: true,
    // textAlign: "center",
    // maxLength:5,
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 23,
      // height: 70,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "5",
        title: `<prefix text='USE IT!'></prefix>
        Work in small groups. Give a presentation to convince people to 
        participate in Buy Nothing Day or Black Friday.`,
        color: "#5cc9dd",
        left: 50,
        width: "27cm",
      },
    ],
    hideBtnFooter: true,
    // giaiThua: true,
    questions: [
      {
        title: `
        <img style="width:15.5cm; height:12cm; margin-left: 100px" src='img/FriendsPlus/Page92/E5/1.jpg' />
        <div style='font-size: 23px; line-height: 40px; width: 26cm; margin-top: 20px'>
          <div><textarea id='0' rows='8'></div>

        </div>
       
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
