import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  5: {
    unit: "Unit 5",
    id: "SB9-2024-U5-P57-E5",
    // audio: "img/FriendsPlus/Page110/Audio/audio-e2-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "100%",
      // marginLeft: 150,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page57/E5/1.jpg" }]],
  },
  1: {
    unit: "Unit 5",
    id: "SB9-2024-U5-P57-E1",
    // audio: "Audios/1.09.mp3",
    exerciseKey: "img/FriendsPlus/Page57/Key/E1answerKey.png",
    component: T6,
    // textAlign: "center",
    // maxLength:5,
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 22,
      // height: 70,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "1",
        title: `Read the passage and answer the questions.`,
        color: "#00A850",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    isHello: true,
    KeyMau: true,
    questions: [
      {
        title: `
        <div style='font-size: 22px; line-height: 30px; width: 26cm'>
          <img style='height:22cm; margin-left: 130px' src="img/FriendsPlus/Page57/E1/1.jpg"/><br>
          <div style='font-weight: 600'><b>1</b>&ensp; How many reasons does the writer give in
          favour of the importance of English in the field of science?</div>
          <div><textarea id='0' rows='1'></div>

          <div style='font-weight: 600'><b>2</b>&ensp;Which of the opinions do you agree or disagree with?</div>
          <div><textarea id='1' rows='2'></div>

          <div style='font-weight: 600'><b>3</b>&ensp;Which conditional sentences does the writer use?</div>
          <div><textarea id='2' rows='2'></div>

        </div>
       
        `,
        answer: [
          "three",
          "Students' own answers.",
          "If there were no common language, rather simple to learn like English, how would those scientists work together? (Second conditional)",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 5",
    id: "SB9-2024-U5-P57-E2",
    exerciseKey: "img/FriendsPlus/Page57/Key/E2answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "2",
        title: `Find the key phrases in the text. Which phrases …`,
        color: "#00A850",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          //LEFT
          {
            boxMatchStyle: {
              position: "absolute",
              top: "157px",
              left: "396px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "187px",
              left: "396px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "220px",
              left: "396px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 2

          //RIGHT
          {
            boxMatchStyle: {
              position: "absolute",
              top: "136px",
              left: "497px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "166px",
              left: "497px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "197px",
              left: "497px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "227px",
              left: "497px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "260px",
              left: "497px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "290px",
              left: "497px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: ["0-3", "0-8", "1-7", "2-5", "2-6", "2-4"],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "different / the_same",
          "base / continuous", //1
          "use_to / used_to", //2
          "use_to / used_to ",
        ],
        answers: [],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative;'>
       <img style="width: 27cm; height: 10cm" src='img/FriendsPlus/Page57/E2/1.jpg' />
    
        <input id='0' type= 'boxMatch' />
			  <input id='1' type= 'boxMatch' />
			  <input id='2' type= 'boxMatch' />

			  <input id='3' type= 'boxMatch' />
        <input id='4' type= 'boxMatch' />
        <input id='5' type= 'boxMatch' />
        <input id='6' type= 'boxMatch' />
        <input id='7' type= 'boxMatch' />
        <input id='8' type= 'boxMatch' />
				
       </div>
      
      `,
    },
  },
  3: {
    unit: "Unit 5",
    id: "SB9-2024-U5-P57-E3",
    exerciseKey: "img/FriendsPlus/Page57/Key/E3answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      width: 400,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "3",
        title: `Study the words in <span style='color: #5cc9dd'>blue</span> in the passage. 
        Then complete the sentences.`,
        color: "#00A850",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img style='height:22cm; margin-left: 130px' src="img/FriendsPlus/Page57/E1/1.jpg"/><br>
        <div style='display: flex; font-size: 22px; line-height: 50px; width: 27cm'>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br>
          <b>2</b>&ensp;<br>
          <b>3</b>&ensp;<br>
          <b>4</b>&ensp;<br>
          
        </div>

        <div style=''>
         <div>Some doctors suggest (experiment with AI) #.</div>

         <div>Many scientists suggest (create more satellites) #.</div>

         <div>World scholars suggest (carry out further research) #.</div>

         <div>Researchers suggest (develop submarine technology) #.</div>

        </div>
        </div>
        
        `,
        answer: [
          "experimenting with AI",
          "creating more satellites",
          "carrying out further research",
          "developing submarine technology",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 5",
    id: "SB9-2024-U5-P57-E4",
    // exerciseKey: "img/FriendsPlus/Page9/Key/E7answerKey.png",
    component: T6,
    //  maxLength: 30,
    // recorder: true,
    hideBtnFooter: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 800,
      fontSize: 22,
      // height: 120,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "4",
        title: `<img style='height:1.1cm; width: 4.8cm; margin-bottom: 5px' src='img/FriendsPlus/icon/6.jpg'/> 
        Follow the steps in the Writing Guide.`,
        color: "#00A850",
        left: 50,
        width: "26cm",
      },
    ],
    questions: [
      {
        title: `
        <img style="width:14cm; height:23cm; margin-left: 170px" src='img/FriendsPlus/Page57/E4/1.jpg' />
        <div style='margin-left: 50px'><textarea id='0' rows='8'></div>
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
