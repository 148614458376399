import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  7: {
    unit: "Unit 5",
    id: "SB9-2024-U5-P54-E7",
    // audio: "img/FriendsPlus/Page110/Audio/audio-e2-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "100%",
      // marginLeft: 150,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page54/E7/1.jpg" }]],
  },
  1: {
    unit: "Unit 5",
    id: "SB9-2024-U5-P54-E1",
    // exerciseKey: "img/FriendsPlus/Page42/Key/E1answerKey.png",
    component: T6,
    // audio: "Audios/1.26.mp3",
    textAlign: "center",
    // maxLength:1,
    hideBtnFooter: true,
    recorder: true,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 20,
      height: 27,
      width: 250,
      // textTransform: 'uppercase',
      // borderStyle: "dotted",
      textAlign: "center",
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "1",
        title: `Read the live chat and check
        the meaning of the words in <span style='color: #5cc9dd'>blue</span>.`,
        color: "#F78024",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative; font-size: 20px; line-height: 35px'>
	        <img src="img/FriendsPlus/Page54/E1/1.jpg" style='height:24cm; width: 21cm; margin-left: -20px '/>
          
        </div> 
        `,
        answer: [],
      },
    ],
  },
  2: {
    unit: "Unit 5",
    id: "SB9-2024-U5-P54-E2",
    // exerciseKey: "img/FriendsPlus/Page42/Key/E1answerKey.png",
    component: T6,
    // audio: "Audios/1.26.mp3",
    textAlign: "center",
    // maxLength:1,
    hideBtnFooter: true,
    recorder: true,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 20,
      height: 27,
      width: 880,
      // textTransform: 'uppercase',
      // borderStyle: "dotted",
      textAlign: "center",
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "2",
        title: `Think about questions a–g. Do you 
        know any of the answers? Compare your ideas with a partner's.`,
        color: "#F78024",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative; font-size: 20px; line-height: 35px'>
	        <img src="img/FriendsPlus/Page54/E1/1.jpg" style='height:24cm; width: 21cm; margin-left: -20px '/><br><br>
          <b>Answer:</b><br>
          <div>#</div>
        </div> 
        `,
        answer: [],
      },
    ],
  },
  3: {
    unit: "Unit 5",
    id: "SB9-2024-U5-P54-E3",
    audio: "Audios/1.34.mp3",
    exerciseKey: "img/FriendsPlus/Page54/Key/E3answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    // hideBtnFooter: true,
    stylesTextInput: { width: 100, paddingTop: 10 },

    titleQuestion: [
      {
        num: "3",
        title: `<headphone name=1.34 src='Audios/1.34 tieu_de.mp3' color='black'  typeImg="sound2"></headphone> 
        Listen to the podcast. Which of questions a–g do the experts 
        answer? Did you hear any of your answers?`,
        color: "#F78024",
        left: 50,
        width: "27cm",
      },
    ],

    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "0px 7px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 7px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 7,
        listWords: ["a) <br> b) <br> c) <br> d) <br> e) <br> f) <br> g)"],
        answers: ["0-0", "0-4", "0-12", "0-16"],
        initialValue: [],
      },
      Layout: `

      <div style='position:relative; line-height:43.4px;  width: 26cm; margin-left: 80px; font-size: 23px'>
        <img src="img/FriendsPlus/Page54/E3/1.jpg" style='height:27cm; width: 22cm; margin-left: -20px '/><br><br>
        <div style='position: absolute; top: 739px; left: 32px; line-height: 35px; z-index: 999;'>
          <div style='justify-content:space-around;width:20cm; height: 1.1cm'><input id='0' type='Circle'/></div><br>
        </div>
       
      </div>

      `,
    },
  },
  4: {
    unit: "Unit 5",
    id: "SB9-2024-U5-P54-E4",
    audio: "Audios/1.34.mp3",
    exerciseKey: "img/FriendsPlus/Page54/Key/E4answerKey.png",
    component: T6,
    textAlign: "center",
    // maxLength: 1,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      width: 300,
      paddingTop: 5,
    },

    titleQuestion: [
      {
        num: "4",
        title: `<headphone name=1.34 src='Audios/1.34 tieu_de.mp3' color='black'  typeImg="sound2"></headphone> 
        Read 1–6. Then listen again 
        and complete the sentences with information from the podcast.`,
        color: "#F78024",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    // isHello: true,
    // KeyMau: true,
    questions: [
      {
        title: `
      
        <div style='margin-left: 50px'>
        <div style='display: flex; font-size: 22px; line-height: 50px; width: 27cm'>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br>
          <b>2</b>&ensp;<br>
          <b>3</b>&ensp;<br>
          <b>4</b>&ensp;<br>
          <b>5</b>&ensp;<br>
          <b>6</b>&ensp;<br>
          
        </div>

        <div style=''>
         <div>In four billion years, our sun will be about # hotter.</div>
         <div>You can’t hear music or voices in space because it’s a #.</div>
         <div>The presenter mentions a video of 
         an astronaut playing the guitar in the #.</div>
         <div>The meteor in 2015 was # miles from Earth.</div>
         <div>The meteor was travelling at # kilometres an hour.</div>
         <div># are more dangerous than sharks – they kill # people a year.</div>
        </div>
        </div>
        
        </div>
        `,
        answer: [
          "30%",
          "vacuum",
          "International Space Station",
          "4.6 million",
          "65,000",
          "Cows",
          "over 20",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 5",
    id: "SB9-2024-U5-P54-E5",
    audio: "Audios/1.35.mp3",
    exerciseKey: "img/FriendsPlus/Page54/Key/E5answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    recorder: true,
    // hideBtnFooter: true,
    stylesTextInput: { width: 100, paddingTop: 10 },

    titleQuestion: [
      {
        num: "5",
        title: `<headphone name=1.35 src='Audios/1.35 tieu_de.mp3' color='black'  typeImg="sound2"></headphone> 
        <span style='color: #F78024'>PRONUNCIATION: The letters ea</span> Listen to the words and 
        then repeat. In which two words 
        are the letters <i>ea</i> pronounced in the same way?`,
        color: "#F78024",
        left: 50,
        width: "27cm",
      },
    ],

    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "0px 7px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 7px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 7,
        listWords: ["death disease earth threat"],
        answers: ["0-0", "0-6"],
        initialValue: [],
      },
      Layout: `

      <div style='position:relative; line-height:43.4px;  width: 12cm; margin-left: 285px; font-size: 23px; margin-top: 20px'>
        <div style='padding: 19px 0px 0px 25px; border: 2px solid orange; border-radius: 33px; line-height: 35px'>
          <div style='justify-content:space-around;width: 12cm; height: 1.1cm'><input id='0' type='Circle'/></div><br>
        </div>
       
      </div>

      `,
    },
  },
  6: {
    unit: "Unit 5",
    id: "SB9-2024-U5-P54-E6",
    // exerciseKey: "img/FriendsPlus/Page42/Key/E1answerKey.png",
    component: T6,
    // audio: "Audios/1.26.mp3",
    textAlign: "center",
    // maxLength:1,
    hideBtnFooter: true,
    recorder: true,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 20,
      height: 27,
      width: 250,
      // textTransform: 'uppercase',
      // borderStyle: "dotted",
      textAlign: "center",
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "6",
        title: `<img style='height:1.1cm; width: 4cm; margin-bottom: 5px' src='img/FriendsPlus/icon/9.jpg'/>
        Make as many 
        questions as you can using the <span style='color: #5cc9dd'>blue</span>
        words. Then share your questions 
        with the class. Which questions would you send to a live chat?`,
        color: "#F78024",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative; font-size: 20px; line-height: 35px; margin-left: 100px'>
	        <img src="img/FriendsPlus/Page54/E1/1.jpg" style='height:24cm; width: 21cm '/>
          <img src="img/FriendsPlus/Page54/E6/1.jpg" style='height:4cm; width: 15cm; margin-left: 80px'/>
          
        </div> 
        `,
        answer: [],
      },
    ],
  },
};

export default json;
