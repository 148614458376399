import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import url from "../../configs/url";

const json = {
  3: {
    unit: "Unit 3",
    id: "SB9-2024-U3-P31-E3",
    exerciseKey: "img/FriendsPlus/Page31/Key/E3answerKey.png",
    // audio: "Audios/1.17.mp3",
    component: Circle_Write,
    inputSize: 500,
    stylesTextInput: { width: 100, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "belong to",
          "come across",
          "keep",
          "forget",
          "look forward",
          "recognise",
          "remind",
          "leave",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 150,
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Choose the correct words in the 
        consumer survey.`,
        color: "#203c8f",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [""],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "spend / promote",
          "saving / renting",
          "value / cost",
          "rip-off / seller",
          "brand / price",
          "cost / afford",
          "products / consumers",
          "advertisements / businesses",
        ],
        answers: ["0-0", "1-0", "2-0", "3-0", "4-0", "5-4", "6-0", "7-0"],
        initialValue: [],
      },
      Layout: `
      <div style='position: realtive; height: 600px; font-size: 22px'>
        <div style='position: relative;  margin-left: 100px'>
        <img style="width:18cm; height:16cm" src='img/FriendsPlus/Page31/E3/1.jpg' />
        <div style='position: absolute; top: 135px; left: -47px; line-height: 42px; width:16cm; margin-left: 100px'>
          <b>1</b>&ensp;What do you <input id='0' type='Circle'/> most money on?<br>
          <b>2</b>&ensp;Are you <input id='1' type='Circle'/> for anything at the moment?<br>
          <b>3</b>&ensp;Which shops or products are good <input id='2' type='Circle'/>
          and which are a <input id='3' type='Circle'/>?<br>
          <b>4</b>&ensp;What luxury <input id='4' type='Circle'/> would you buy if you 
          could <input id='5' type='Circle'/> it?<br>
          <b>5</b>&ensp;What <input id='6' type='Circle'/> are popular with your age group at the moment?<br>
          <b>6</b>&ensp;Which companies have the best marketing and <input id='7' type='Circle'/>?<br>
        </div>
        </div>
      </div>
     
      `,
    },
  },
  4: {
    unit: "Unit 3",
    id: "SB9-2024-U3-P31-E4",
    exerciseKey: "img/FriendsPlus/Page31/Key/E4answerKey.png",
    audio: "Audios/1.18.mp3",
    video: url.cndUrl + "Videos/Unit 3.mp4 - Ex 4 (trang 31).mp4",
    component: T6,
    //  //  maxLength: 25,
    // hideBtnFooter: true,
    textAlign: "left",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 800,
      paddingTop: 5,
      fontSize: 22,
      // height: 100,
    },
    titleQuestion: [
      {
        num: "4",
        title: `<headphone name=1.18 src='Audios/1.18 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
        Watch or listen to three people talking about their spending habits. Answer the questions.`,
        color: "#203c8f",
        left: 50,
        width: "27cm",
      },
    ],
    questions: [
      {
        title: `
        
      <div style='margin-left:0px; font-size: 22px; margin-top: 20px; line-height: 50px'>
        <div><div><b>1</b>&ensp;What is Sam saving for?</div>
        <textarea id='0' rows='1'></div>
        <div><div><b>2</b>&ensp;What is Tegan’s one luxury?</div>
        <textarea id='1' rows='1'></div>
        <div><div><b>3</b>&ensp;Where does Daniel buy his games?</div>
        <textarea id='2' rows='1'></div>
        
      </div>
        
        `,
        answer: ["a car", "a pair of sunglasses", "a shop in town or online"],
      },
    ],
  },
  5: {
    unit: "Unit 3",
    id: "SB9-2024-U3-P31-E5",
    // exerciseKey: "img/FriendsPlus/Page9/Key/E7answerKey.png",
    component: T6,
    //  maxLength: 30,
    recorder: true,
    hideBtnFooter: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 800,
      fontSize: 22,
      height: 120,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "5",
        title: `<img style='height:1.1cm; width: 3.8cm; margin-bottom: 5px' src='img/FriendsPlus/icon/7.jpg'/>
        Work in groups. Ask and answer the questions in the consumer survey. Use the key phrases.`,
        color: "#203c8f",
        left: 50,
        width: "26cm",
      },
    ],
    questions: [
      {
        title: `
        <div style='margin-left: 130px' >
        <img style="width:15cm; height:10cm; margin-left: 30px" src='img/FriendsPlus/Page31/E5/1.jpg' /><br>
        <img style="width:20cm; height:16cm; margin-left: -30px" src='img/FriendsPlus/Page31/E5/2.png' />
        </div>
        
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
