import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import url from "../../configs/url";

const json = {
  7: {
    unit: "Unit 4",
    id: "SB9-2024-U4-P44-E7",
    // audio: "img/FriendsPlus/Page110/Audio/audio-e2-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "100%",
      marginBottom: 150,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page44/E7/1.jpg" }]],
  },
  1: {
    unit: "Unit 4",
    id: "SB9-2024-U4-P44-E1",
    audio: "Audios/1.28.mp3",
    exerciseKey: "img/FriendsPlus/Page44/Key/E1answerKey.png",
    component: T6,
    //  //  maxLength: 25,
    // hideBtnFooter: true,
    textAlign: "left",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 400,
      paddingTop: 5,
      fontSize: 22,
      // height: 100,
    },
    titleQuestion: [
      {
        num: "1",
        title: `<headphone name=1.28 src='Audios/1.28 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
        Read and listen to the dialogue. How is Brad feeling? Why?`,
        color: "#DC1E74",
        left: 50,
        width: "27cm",
      },
    ],
    questions: [
      {
        title: `
        
      <div style='position: relative; margin-left:0px; font-size: 22px; margin-top: 20px; line-height: 45px'>
      <img style="width:26cm; height:19cm" src='img/FriendsPlus/Page44/E1/1.jpg' />

      <div style='position: absolute; top: 432px; left: 523px'>
        <div><div>How is Brad feeling?</div>
        <textarea id='0' rows='2'></div>
        <div><div>Why?</div>
        <textarea id='1' rows='2'></div>
        </div>
        
      </div>
        
        `,
        answer: [
          "Brad is feeling sad. | Brad is feeling fed up.",
          "Because he didn't get into the swimming team.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 4",
    id: "SB9-2024-U4-P44-E2",
    audio: "Audios/1.28.mp3",
    // exerciseKey: "img/FriendsPlus/Page44/Key/E1answerKey.png",
    component: T6,
    recorder: true,
    hideBtnFooter: true,
    textAlign: "left",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 400,
      paddingTop: 5,
      fontSize: 22,
      // height: 100,
    },
    titleQuestion: [
      {
        num: "2",
        title: `<headphone name=1.28 src='Audios/1.28 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
        <span style='color: #DC1E74'>PRONUNCIATION: Intonation</span> When 
        we speak, we can convey our feelings with the tone of our voice. Read the Key Phrases. Then listen again and practise the dialogue. Try to use a sympathetic tone of voice.`,
        color: "#DC1E74",
        left: 50,
        width: "27cm",
      },
    ],
    questions: [
      {
        title: `
        
      <div style='position: relative; margin-left:0px; font-size: 22px; margin-top: 20px; line-height: 45px'>
      
      <img style="width:26cm; height:22cm" src='img/FriendsPlus/Page44/E2/1.jpg' />
        
        `,
        answer: [""],
      },
    ],
  },
  3: {
    unit: "Unit 4",
    id: "SB9-2024-U4-P44-E3",
    audio: "Audios/1.29.mp3",
    video: url.cndUrl + "Videos/Unit 4.mp4 - Ex 3,4 (trang 44).mp4",
    exerciseKey: "img/FriendsPlus/Page44/Key/E3answerKey.png",
    component: T6,
    //  //  maxLength: 25,
    // hideBtnFooter: true,
    textAlign: "left",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 850,
      paddingTop: 5,
      fontSize: 22,
      // height: 100,
    },
    titleQuestion: [
      {
        num: "3",
        title: `<headphone name=1.29 src='Audios/1.29 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
        Watch or listen to another dialogue. Answer the questions with a partner.`,
        color: "#DC1E74",
        left: 50,
        width: "27cm",
      },
    ],
    questions: [
      {
        title: `
        
      <div style='position: relative; margin-left:0px; font-size: 22px; margin-top: 20px; line-height: 45px'>

    
        <div><div>What’s Jan’s problem?</div>
        <textarea id='0' rows='2'></div>

        <div><div>What does Ned say about London?</div>
        <textarea id='1' rows='2'></div>
        </div>

        <div><div>How does Jan feel at the end?</div>
        <textarea id='2' rows='2'></div>
        
        
      </div>
        
        `,
        answer: [
          "Her family might have to move house.",
          "It's quite far, but cool.",
          "She feels a bit better.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 4",
    id: "SB9-2024-U4-P44-E4",
    audio: "Audios/1.29.mp3",
    video: url.cndUrl + "Videos/Unit 4.mp4 - Ex 3,4 (trang 44).mp4",
    exerciseKey: "img/FriendsPlus/Page44/Key/E4answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "4",
        title: `<headphone name=1.29 src='Audios/1.29 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
        Watch or listen again. Which key phrases do you hear?`,
        color: "#DC1E74",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 4px",
          // border: "2px solid",
          borderColor: "transparent",
          // fontWeight: "bold",
        },
        selectWordStyle: {
          // fontWeight: "bold",
          // padding: "1px 4px",
          // borderRadius: "50%",
          color: "black",
          borderBottom: "2px solid #00aeef",
          borderColor: "#00aeef",
        },
        limitSelect: 8,
        listWords: [
          "I'm_(really)_sorry_/_surprised_/_amazed_to_hear_that. <br> You_must_feel … <br> I_can_imagine. <br> Cheer_up._/_Calm_down. <br> Look_on_the_bright_side. <br> Don't_worry. <br> It's_not_the_end_of_the_world. <br> You_just_have_to …",
        ],
        answers: ["0-0", "0-10", "0-18", "0-4", "0-30", "0-22"],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative; width: 25cm; font-size: 22px; line-height: 38px; margin-left: 100px'>
        <img style="width:20cm; height:16cm" src='img/FriendsPlus/Page44/E4/1.jpg' />
        <div style='position: absolute; top: 136px; left: 45px'>
           <input id='0' type='Circle' />
        </div>
      </div>
      
      `,
    },
  },
  5: {
    unit: "Unit 4",
    id: "SB9-2024-U4-P44-E5",
    exerciseKey: "img/FriendsPlus/Page44/Key/E5answerKey.png",
    component: T6,
    maxLength: 1,
    // recorder: true,
    // hideBtnFooter: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 800,
      fontSize: 22,
      height: 120,
      paddingTop: 5,
    },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 23,
      height: 27,
      textTransform: "uppercase",
      textAlign: "center",
      width: 70,
      paddingTop: 10,
    },
    titleQuestion: [
      {
        num: "5",
        title: `Read tasks A and B. Which situation did you 
        watch or hear in exercise 3?`,
        color: "#DC1E74",
        left: 50,
        width: "26cm",
      },
    ],
    questions: [
      {
        title: `
        <div style=' margin-left: 0px; font-size: 23px' >
        <img style="width:15cm; height: 14cm; margin-left: 50px" src='img/FriendsPlus/Page44/E5/1.jpg' />
        <div>Which situation did you watch or hear in exercise 3?&ensp;Task #</div>
        </div>
        
        `,
        answer: ["A"],
      },
    ],
  },
  6: {
    unit: "Unit 4",
    id: "SB9-2024-U4-P44-E6",
    // exerciseKey: "img/FriendsPlus/Page9/Key/E7answerKey.png",
    component: T6,
    // maxLength: 30,
    recorder: true,
    hideBtnFooter: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 800,
      fontSize: 22,
      height: 120,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "6",
        title: `<img style='height:1.1cm; width: 3.8cm; margin-bottom: 5px' src='img/FriendsPlus/icon/5.jpg'/>
        Work in pairs. Act out situation A or B.`,
        color: "#DC1E74",
        left: 50,
        width: "26cm",
      },
    ],
    questions: [
      {
        title: `
        <div style='display: flex; margin-left: 100px' >
        <img style="width:15cm; height: 14cm; margin-left: 50px" src='img/FriendsPlus/Page44/E5/1.jpg' />
        </div>
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
