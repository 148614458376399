import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  7: {
    unit: "Unit 4",
    id: "SB9-2024-U4-P40-E7",
    // audio: "img/FriendsPlus/Page110/Audio/audio-e2-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "100%",
      marginLeft: 0,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page40/E7/1.jpg" }]],
  },
  1: {
    unit: "Unit 4",
    id: "SB9-2024-U4-P40-E1",
    exerciseKey: "img/FriendsPlus/Page40/Key/E1answerKey.png",
    audio: "",
    video: "",
    component: Circle_Write,
    inputSize: 500,
    // hideBtnFooter: true,
    stylesTextInput: { width: 100, paddingTop: 10 },

    titleQuestion: [
      {
        num: "1",
        title: `Scan the blog. Choose from a–c.`,
        color: "#D92F3B",
      },
    ],

    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "0px 7px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 7px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: ["a <br> b <br> c"],
        answers: ["0-4"],
        initialValue: [],
      },
      Layout: `

      <div style=''>
      <img style="width:26cm; height:29cm" src='img/FriendsPlus/Page40/E1/1.jpg' />
      </div>
      <div style='position:relative; line-height:43.4px; font-size: 21px; width: 25cm'>
        
        <div style='position:relative; margin-bottom: 100px; margin-left: 50px'>
          <div style='height: 1.1cm'><input id='0' type='Circle'/></div>
        </div>
        
        <div style='position:absolute;top:0px;left:95px'>
          <div>It's by a philosopher who is talking about the history of philosophy.</div>
          <div>It's by a person who is interested in the subject because she likes animals.</div>
          <div>It's by a scientist who is talking about her 
          work and advertising a book.</div>
          
        </div>
      </div>

      `,
    },
  },
  2: {
    unit: "Unit 4",
    id: "SB9-2024-U4-P40-E2",
    exerciseKey: "img/FriendsPlus/Page40/Key/E2answerKey.png",
    component: T6,
    audio: "Audios/1.25.mp3",
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "1px solid",
      fontSize: 23,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "left",
      width: 800,
      paddingTop: 5,
    },
    selectStyle: {
      height: 35,
      width: 106,
      textAlign: "center",
      fontSize: 22,
    },
    selectOptionRandom: true,
    selectOptionValues: ["true", "false"],
    titleQuestion: [
      {
        num: "2",
        title: `<headphone name=1.25 src='Audios/1.25 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
        Read and listen to the text. Write 
        <i>true</i> or <i>false</i> and correct the false sentences.`,
        color: "#D92F3B",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `

        <div style='position: relative; height: 600px; font-size: 23px'>
        <div style='display: flex; position: absolute; top: 15px; left: 38px; width: 25cm; line-height: 44px'>
          <div>
            <div style="display: flex"><b>1</b> &ensp;The author gets on well with her pets.&ensp; <select id=5></select></div>
            <div><input id=0/></div>

            <div style="display: flex"><b>2</b> &ensp;Studies today show that animals don’t have 
            thoughts or feelings. &ensp;<select id=6></select></div> 
            <div><input id=1/></div>

            <div style="display: flex"><b>3</b> &ensp;Chimpanzees show sympathy. &ensp;<select id=7></select></div>
            <div><input id=2/></div>

            <div style="display: flex"><b>4</b> &ensp;Small-brained animals don’t show sympathy. &ensp; <select id=8></select></div>
            <div><input id=3/></div>

            <div style="display: flex"><b>5</b> &ensp;In the future, we’ll know more about animals’ emotions. &ensp; <select id=9></select></div>
            <div><input id=4/></div>
          </div>
        </div>
        `,
        InputRong: true,
        answer: [
          "",
          "Studies today show that animals may have thoughts or feelings.",
          "",
          "Small-brained animals like crows and mice show sympathy.",
          "",
          "true",
          "false",
          "true",
          "false",
          "true",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 4",
    id: "SB9-2024-U4-P40-E3",
    exerciseKey: "img/FriendsPlus/Page40/Key/E3answerKey.png",
    // audio: "Audios/1.11.mp3",
    component: T6,
    textAlign: "left",
    // recorder: true,
    // maxLength: 1,
    textareaStyle: {
      // background: 'none',
      width: 850,
      paddingTop: 5,
      fontSize: 22,
      // height: 100,
    },
    stylesTextInput: {
      width: 750,
      paddingTop: 15,
      background: "none",
      borderBottom: "solid 1.5px",
      fontSize: 21,
      // fontWeight: 600,
    },

    titleQuestion: [
      {
        num: "3",
        title: `Read the text again and answer the questions.`,
        color: "#D92F3B",
        left: 50,
        width: "26cm",
      },
    ],
    character: /[,/and]/,
    // hideBtnFooter: true,
    // isHello: true,
    // KeyMau: true,
    questions: [
      {
        title: `
        <div style='position: relative; font-size: 22px'>
        <div style='margin-left: 0px'>
        <img style="width:26cm; height:28cm" src='img/FriendsPlus/Page40/E1/1.jpg' />
        </div>
          
          <div style=' margin-top: 10px; margin-left: 50px '>
              <div>
                <div><b>1&ensp;</b>How do people know more about animal 
                behaviour these days?</div>
                <textarea id='0' rows='2'>
              </div>
              <div>
                <div><b>2&ensp;</b>For how long do elephants visit their dead?</div>
                <textarea id='1' rows='2'>
              </div>
              <div>
                <div><b>3&ensp;</b>Which animals in the blog are big-brained 
                mammals?</div>
                <textarea id='2' rows='2'>
              </div>
              <div>
                <div><b>4&ensp;</b>Why does the author think that Descartes 
                was wrong?</div>
                <textarea id='3' rows='2'>
              </div>

          </div>
        </div>
        `,
        answer: [
          "Scientists have spent a lot of time researching animals.",
          "Months and sometimes years later.",
          "Elephants, whales and chimpanzees.",
          "Because scientific studies suggest that animals might have emotions. / She thinks the evidence suggests that animals must feel something.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 4",
    id: "SB9-2024-U4-P40-E4",
    exerciseKey: "img/FriendsPlus/Page40/Key/E4answerKey.png",
    component: T6,
    // audio: "Audios/1.19.mp3",
    // maxLength:4,
    // hideBtnFooter:true,
    // recorder: true,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      // height: 27,
      // textTransform: "uppercase",
      textAlign: "left",
      width: 130,
      paddingTop: 10,
    },
    textareaStyle: {
      // background: 'none',
      width: 800,
      paddingTop: 5,
      fontSize: 22,
      // height: 100,
    },
    titleQuestion: [
      {
        num: "4",
        title: `<span style='color: #D92F3B'><b>VOCABULARY PLUS</b></span>
        Scan the blog and complete the two-part adjectives in 1–6. How do all of the adjectives end?`,
        color: "#D92F3B",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative; font-size: 22px'>
        <img style="width:26cm; height:28cm" src='img/FriendsPlus/Page40/E1/1.jpg' />
        <div style='display: flex; position: absolute; top: 908px; left: 0px; width: 25cm; line-height: 38px'>
          <div>
            <div style="display: flex"><b>1</b> &ensp;four-#</div>
            <div style="display: flex"><b>2</b> &ensp;long-#</div>
            <div style="display: flex"><b>3</b> &ensp;one-#</div>
            
          </div>
          <div style='margin-left: 20px'>
            
            <div style="display: flex"><b>4</b> &ensp;broken-#</div>
            <div style="display: flex"><b>5</b> &ensp;big-#</div>
            <div style="display: flex"><b>6</b> &ensp;open-#</div>

          </div>
        </div>
          <div><b>How do all of the adjectives end?</b></div>
          <div>The adjectives all end with # .</div>
        </div>
        `,
        InputRong: true,
        answer: [
          "legged",
          "haired",
          "eyed",
          "hearted",
          "brained",
          "minded",
          "-ed",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 4",
    id: "SB9-2024-U4-P40-E5",
    exerciseKey: "img/FriendsPlus/Page40/Key/E5answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "left",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 150,
      paddingTop: 5,
    },
    hintBox: [
      {
        src: ["behave", "blood", "eye", "hair", "hand", "skin"],
        width: 650,
        borderColor: "#D92F3B",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Complete the adjectives in 1–6. Use the -ed
        form of the words in the box.`,
        color: "#D92F3B",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id='0'></hintbox><br>
        <div style='display: flex; font-size: 22px; line-height: 50px; width: 26cm'>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br>
          <b>2</b>&ensp;<br>
          <b>3</b>&ensp;<br>
          <b>4</b>&ensp;<br>
          <b>5</b>&ensp;<br>
          <b>6</b>&ensp;<br>
          
        </div>

        <div style='width: 25cm'>
         <div>Crocodiles are cold-# creatures.</div>
         <div>That blonde-# girl is my sister.</div>
         <div>It’s difficult for me to use that pen because I’m left-#.</div>
         <div>You’re such an excellent class. You’re all very well-#.</div>
         <div>I’m tall and blue-# like my father.</div>
         <div>I can’t stay in the sun for long because I’m very pale-#.</div>
        
        </div>
        </div>
        
        `,
        answer: ["blooded", "haired", "handed", "behaved", "eyed", "skinned"],
      },
    ],
  },
  6: {
    unit: "Unit 4",
    id: "SB9-2024-U4-P40-E6",
    // exerciseKey: "img/FriendsPlus/Page71/Key/E2answerKey.png",
    component: T6,
    //  //  maxLength: 25,
    hideBtnFooter: true,
    textAlign: "left",
    recorder: true,
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 900,
      paddingTop: 5,
      fontSize: 21,
      // height: 100,
    },
    titleQuestion: [
      {
        num: "6",
        title: `<img style="width:3.5cm; height:1cm; margin-bottom: 7px" src='img/FriendsPlus/icon/1.jpg' /> 
        Work in pairs. Ask and answer the questions.`,
        color: "#D92F3B",
        left: 50,
        width: "26cm",
      },
    ],
    questions: [
      {
        title: `
        
      <div style='margin-left:0px; font-size: 22px; margin-top: 20px; line-height: 50px'>
        <div><div><b>1</b>&ensp;Do you agree with the conclusion of the blog?</div>
        <textarea id='0' rows='2'></div>
        <div><div><b>2</b>&ensp;Have you heard of any other cases of animal 
        intelligence or emotions?</div>
        <textarea id='1' rows='2'></div>
        
      </div>
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
