import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  8: {
    unit: "Progress Review 4",
    id: "SB9-2024-PR4-P88-E8",
    exerciseKey: "img/FriendsPlus/Page88/Key/E8answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "left",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 40,
      paddingTop: 5,
    },
    hintBox: [
      {
        src: [
          "adapt / adaptation",
          "create / creation",
          "discover / discovery",
          "explore / exploration",
          "imagine / imagination",
          "invent / invention",
        ],
        width: 850,
        borderColor: "#4F3C94",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Complete the sentences with the correct adjectives.`,
        color: "#4F3C94",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display: flex; font-size: 22px; line-height: 50px; width: 27cm'>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br>
          <b>2</b>&ensp;<br><br>
          <b>3</b>&ensp;<br>
          <b>4</b>&ensp;<br><br>
          <b>5</b>&ensp;<br>
          <b>6</b>&ensp;<br>
          
        </div>

        <div style=''>
         <div>‘What’s the matter?’ ‘My sister used my 
         camera. I wasn’t very h# # # # about it.</div>

         <div>Do you prefer rock or reggae songs?’ ‘I think 
         I’m t# # # # # # # at classical music.’</div>

         <div>‘Are you afraid of snakes?’ ‘Yes, I’m really 
         s# # # # # of them.’</div>

         <div>‘I haven’t studied for the exam!’ ‘Oh dear. 
         Are you very w# # # # # # about it?’ ‘Yes, I am!’</div>

         <div>‘Do you see your cousins very often?’ ‘Yes, 
         they’re really nice. I’m very k# # # on them.’</div>

         <div>‘Thy Ca is g# # # at running.’ ‘Yes, I think 
         she’ll win a gold medal in SEA Games 
         women's marathon one day.’</div>
        </div>
        </div>
        
        `,
        answer: [
          "a",
          "p",
          "p",
          "y",
          "h",
          "r",
          "i",
          "l",
          "l",
          "e",
          "d",
          "c",
          "a",
          "r",
          "e",
          "d",
          "o",
          "r",
          "r",
          "i",
          "e",
          "d",
          "e",
          "e",
          "n",
          "o",
          "o",
          "d",
        ],
      },
    ],
  },
  9: {
    unit: "Progress Review 4",
    id: "SB9-2024-PR4-P88-E9",
    exerciseKey: "img/FriendsPlus/Page88/Key/E9answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "left",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 23,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 130,
      paddingTop: 5,
    },
    hintBox: [
      {
        src: [
          "adapt / adaptation",
          "create / creation",
          "discover / discovery",
          "explore / exploration",
          "imagine / imagination",
          "invent / invention",
        ],
        width: 850,
        borderColor: "#4F3C94",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "9",
        title: `Complete the words in the sentences.`,
        color: "#4F3C94",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display: flex; font-size: 23px; line-height: 50px; width: 27cm'>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br>
          <b>2</b>&ensp;<br>
          <b>3</b>&ensp;<br>
          <b>4</b>&ensp;<br>
          <b>5</b>&ensp;<br>
          
        </div>

        <div style=''>
         <div>We were very sad when we read the 
         newspaper article about the <b>fa</b>#
         accident.</div>

         <div>This magazine says that roller coaster rides 
         aren’t dangerous or <b>ri</b># at all.</div>

         <div>I’m afraid of small spiders. I know it’s silly 
         and <b>ri</b># !</div>

         <div>My brother is not <b>br</b># enough to try 
         getting on the dangerous ride.</div>

         <div>Do you think you’ll feel <b>fr</b># when you 
         go paragliding next week?</div>
        </div>
        </div>
        
        `,
        answer: ["tal", "sky", "diculous", "ave", "ightened"],
      },
    ],
  },
  10: {
    unit: "Progress Review 4",
    id: "SB9-2024-PR4-P88-E10",
    exerciseKey: "img/FriendsPlus/Page88/Key/E10answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      // textAlign: "center",
      width: 250,
      paddingTop: 5,
    },
    hintBox: [
      {
        src: [
          "adapt / adaptation",
          "create / creation",
          "discover / discovery",
          "explore / exploration",
          "imagine / imagination",
          "invent / invention",
        ],
        width: 850,
        borderColor: "#4F3C94",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "10",
        title: `Complete the sentences with <i>said</i> or <i>told</i> and reported speech.`,
        color: "#4F3C94",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display: flex; font-size: 22px; line-height: 45px; width: 28.5cm'>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br><br>
          <b>2</b>&ensp;<br><br>
          <b>3</b>&ensp;<br><br>
          <b>4</b>&ensp;<br><br>
          <b>5</b>&ensp;<br><br>
          <b>6</b>&ensp;<br><br>
          
        </div>

        <div style=''>
         <div>'We're going to find him.'</div>
         <div>The police # me that they # him.</div>

         <div>'You must be careful.'</div>
         <div>Our teacher # us that we # careful.</div>

         <div>'I'll find the thief.'</div>
         <div>The detective # that he # the thief.</div>

         <div>'We saw the robbery.'</div>
         <div>They # the man that they # the robbery.</div>

         <div>'I can catch the vandal.'</div>
         <div>The officer # that she # the vandal.</div>

         <div>'I love crime novels.'</div>
         <div>Valentina # that she # crime novels.</div>

        </div>
        </div>
        
        `,
        answer: [
          "told",
          "were going to find",
          "told",
          "had to be",
          "said",
          "would find",
          "told",
          "'d seen",
          "said",
          "could catch",
          "said",
          "loved",
        ],
      },
    ],
  },
  11: {
    unit: "Progress Review 4",
    id: "SB9-2024-PR4-P88-E11",
    exerciseKey: "img/FriendsPlus/Page88/Key/E11answerKey.png",
    audio: "Audios/2.25.mp3",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "left",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 220,
      paddingTop: 5,
      fontWeight: 600,
    },
    hintBox: [
      {
        src: [
          "By contrast",
          "could be",
          "the first picture",
          "I prefer photo",
          "it looks like",
          "It's hard to",
          "maybe it's",
          "not sure",
          "two pictures show",
        ],
        width: 900,
        borderColor: "#4F3C94",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "11",
        title: `<headphone name=2.25 src='Audios/2.25 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
        Listen to the PE teacher talking to the 
        girls’ volleyball team. Complete the teacher’s 
        notes with injury vocabulary.`,
        color: "#4F3C94",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative; margin-top: 20px; margin-left: 0px'>
          <img style="width:25cm; height:15cm" src='img/FriendsPlus/Page88/E11/1.jpg' />
          
          <div style='position: absolute; top: 83px; left: 51px; font-size: 22px; line-height: 56px; width: 23.5cm'>
          OK – Emma, Cara, Rachel, Ava, Yasmin and Louisa<br>
          Hannah has <sup><b>1</b></sup># her hand. <br>
          Safira has <sup><b>2</b></sup># her finger badly.<br>
          Caitlin hasn’t <sup><b>3</b></sup># her leg, but she’s <sup><b>4</b></sup>#.<br>
          Jennie has got a big <sup><b>5</b></sup># on her left shoulder.<br>
          Ella has <sup><b>6</b></sup># her ankle and she’s <sup><b>7</b></sup># her knee.<br>
          Aisha has got a bad <sup><b>8</b></sup># on her right arm.<br>
         
          </div>
        </div>
        
        `,
        answer: [
          "burned",
          "cut",
          "broken",
          "injured",
          "bruise",
          "sprained",
          "injured",
          "cut",
        ],
      },
    ],
  },
};

export default json;
