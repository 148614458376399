import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    unit: "Culture",
    id: "SB9-2024-Cul-P90-E1",
    exerciseKey: "img/FriendsPlus/Page90/Key/E1answerKey.png",
    component: T6,
    audio: "Audios/3.02.mp3",
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      // textAlign: "center",
      width: 500,
      paddingTop: 5,
      // fontWeight: 600,
    },
    titleQuestion: [
      {
        num: "1",
        title: `<headphone name=3.02 src='Audios/3.02 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
        Study the photos and the title of the 
        text. Then read and listen to the text. Who is the 
        man in the photo? What objects are in the boxes?`,
        color: "#5cc9dd",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative; font-size: 22px'>
          <img src="img/FriendsPlus/Page90/E1/1.jpg" style='height:21cm'/>
          <div style=" position: absolute; top: 10px; left: 9px; ">
            <b> Who is the man in the photo?</b><br>#.<br>

            <b>What objects are in the boxes?</b><br>#.<br>
          </div>
        </div> 
      
        
        `,
        answer: [
          "Pop artist, Andy Warhol",
          "Many objects from his everyday life",
        ],
      },
    ],
  },
  2: {
    unit: "Culture",
    id: "SB9-2024-Cul-P90-E2",
    exerciseKey: "img/FriendsPlus/Page90/Key/E2answerKey.png",
    component: T6,
    // hideBtnFooter:true,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 300,
      paddingTop: 5,
      fontWeight: 600,
    },
    hintBox: [
      {
        src: [
          "But it’s up to you",
          "So I’m leaning towards the film club",
          "What are your options",
          "The only bad thing about that club",
          "What are you up to",
          "Have you considered another basketball club",
          "It’s a tricky decision",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Read the text again. Match the words in <span style='color: #5cc9dd'>blue</span>
        in the text with the definitions.`,
        color: "#5cc9dd",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative; font-size: 22px; line-height: 40px'>
        <img src="img/FriendsPlus/Page90/E1/1.jpg" style='height:21cm'/>
          <div style="width:26cm; display: flex; margin-left: 50px; margin-top: 20px">
          <div style='margin-right: 50px'>
            <b>1</b>&ensp;attention from newspapers and television<br> #<br>
            <b>2</b>&ensp;not unusual, different or special<br> #<br>
            <b>3</b>&ensp;amazing, very surprising<br> #<br>
          </div>
          <div>
            <b>4</b>&ensp;a person who records historical events<br> #<br>
            <b>5</b>&ensp;strange or unusual<br> #<br>
            <b>6</b>&ensp;a person who collects a lot of things<br> #<br>
          
          </div>
          </div>   
        </div> 
        
      
        
        `,
        answer: [
          "spotlight",
          "ordinary",
          "astonishing",
          "chronicler",
          "bizarre",
          "hoarder",
        ],
      },
    ],
  },
  3: {
    unit: "Culture",
    id: "SB9-2024-Cul-P90-E3",
    exerciseKey: "img/FriendsPlus/Page90/Key/E3answerKey.png",
    component: T6,
    // audio: "Audios/1.33.mp3",
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "1px solid",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "left",
      width: 880,
      paddingTop: 5,
    },
    selectStyle: {
      height: 35,
      width: 106,
      textAlign: "center",
      fontSize: 21,
    },
    selectOptionRandom: true,
    selectOptionValues: ["true", "false"],
    titleQuestion: [
      {
        num: "3",
        title: `Read the text again. Write <i>true</i> or <i>false</i> and 
        correct the false sentences.`,
        color: "#5cc9dd",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `

        <div style='position: relative; font-size: 22px'>
        <img src="img/FriendsPlus/Page90/E1/1.jpg" style='height:21cm'/>
        <div style='display: flex;  width: 27cm; line-height: 44px; margin-top: 20px'>
          <div>
            <div style="display: flex"><b>1</b> &ensp;Andy Warhol was famous for his Cubist art. &ensp; <select id=6></select></div>
            <div>#.</div>

            <div style="display: flex"><b>2</b> &ensp;Each box contained valuable objects.
             &ensp;<select id=7></select></div> 
            <div>#.</div>

            <div style="display: flex"><b>3</b> &ensp;Warhol made his first time capsule in 1974.
             &ensp;<select id=8></select></div>
            <div>#.</div>

            <div style="display: flex"><b>4</b> &ensp;A fan bought one of the boxes for $30,000.
             &ensp;<select id=9></select></div>
            <div>#.</div>

            <div style="display: flex"><b>5</b> &ensp;Some of the boxes contained pieces of Andy’s 
            art. &ensp; <select id=10></select></div>
            <div>#.</div>

            <div style="display: flex"><b>6</b> &ensp;In contrast to the time capsules, Warhol’s art is 
            only concerned with famous people. &ensp; <select id=11></select></div>
            <div>#.</div>

          </div>
        </div>
        `,
        InputRong: true,
        answer: [
          "He was famous for his pop art",
          "They contained ordinary objects that appear worthless",
          "",
          "A fan paid $30,000 to help open the box",
          "",
          "It is concerned with everyday objects",
          "false",
          "false",
          "true",
          "false",
          "true",
          "false",
        ],
      },
    ],
  },
  4: {
    unit: "Culture",
    id: "SB9-2024-Cul-P90-E4",
    exerciseKey: "img/FriendsPlus/Page90/Key/E4answerKey.png",
    // audio: "Audios/2.21.mp3",
    component: T6,
    // textAlign: "center",
    // maxLength:5,
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 23,
      // height: 70,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "4",
        title: `<prefix text='YOUR CULTURE'></prefix>
        Work in pairs. Ask and answer the questions about Việt Nam.`,
        color: "#5cc9dd",
        left: 50,
        width: "27cm",
      },
    ],
    hideBtnFooter: true,
    // giaiThua: true,
    questions: [
      {
        title: `
        
        <div style='font-size: 23px; line-height: 40px; width: 26cm; margin-top: 20px'>
          <div style='font-weight: 600'><b>1</b>&ensp;Can you think of any famous collections in 
          museums in Việt Nam?</div>
          <div><textarea id='0' rows='2'></div>

          <div style='font-weight: 600'><b>2</b>&ensp;If Andy Warhol lived in Việt Nam today, what 
          everyday objects would he put in his boxes?</div>
          <div><textarea id='1' rows='2'></div>

        </div>
       
        `,
        answer: [""],
      },
    ],
  },
  5: {
    unit: "Culture",
    id: "SB9-2024-Cul-P90-E5",
    exerciseKey: "img/FriendsPlus/Page90/Key/E5answerKey.png",
    // audio: "Audios/2.21.mp3",
    component: T6,
    recorder: true,
    // textAlign: "center",
    // maxLength:5,
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 23,
      // height: 70,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "5",
        title: `<prefix text='USE IT!'></prefix>
        Give a presentation about a time capsule.`,
        color: "#5cc9dd",
        left: 50,
        width: "27cm",
      },
    ],
    hideBtnFooter: true,
    // giaiThua: true,
    questions: [
      {
        title: `
        <img style="width:17cm; height:9cm; margin-left: 100px" src='img/FriendsPlus/Page90/E5/1.jpg' />
        <div style='font-size: 23px; line-height: 40px; width: 26cm; margin-top: 20px'>
          <div><textarea id='0' rows='8'></div>

        </div>
       
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
