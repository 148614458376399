import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 8",
    id: "SB9-2024-U8-P83-E1",
    // audio: "Audios/3-07.mp3",
    exerciseKey: "img/FriendsPlus/Page83/Key/E1answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "1",
        title: `Study the direct and reported questions. Then 
        choose the correct options to complete the rules.`,
        color: "#5cc9dd",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "the_same / different",
          "changes / stays_the_same", //2
          "all_questions / Yes/No_questions",
          "all_questions / Wh-_questions",
        ],
        answers: ["0-4", "2-4", "4-4", "1-0", "3-4"],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative;'>
      <img style="width:18cm; height:8.1cm; margin-left: 100px" src='img/FriendsPlus/Page83/E1/1.jpg' />
       <img style="width:23.4cm; height:10cm; margin-left: 100px" src='img/FriendsPlus/Page83/E1/2.jpg' />
       <div style='display: flex; position: absolute; top: 389px; left: 135px; width: 22cm; line-height: 50px; font-size: 23px'>
         <div>
           <div style=""><b>1</b> &ensp;The word order is usually <input id='0' type='Circle' />
           in direct and reported questions. 
           </div>
    
           <div style=""><b>2</b> &ensp;The tense <input id='1' type='Circle' /> in reported questions.
           </div>

           <div style=""><b>3</b> &ensp;We can use if or whether to report <input id='2' type='Circle' />.
           </div>

           <div style=""><b>4</b> &ensp;We use Wh- words to report <input id='3' type='Circle' />.
           </div>

         </div>
       </div>
    
       </div>
      
      `,
    },
  },
  2: {
    unit: "Unit 8",
    id: "SB9-2024-U8-P83-E2",
    // audio: "Audios/3-07.mp3",
    exerciseKey: "img/FriendsPlus/Page83/Key/E2answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "2",
        title: `Choose the correct words.`,
        color: "#5cc9dd",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "am / was",
          "was / had", //2
          "have / had",
          "am / was",
          "am / was",
          "have / had",
        ],
        answers: ["0-4", "1-4", "2-4", "3-4", "4-4", "5-4"],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative;'>
          <div>When I got home, my mum asked me …</div>  

           <div style=""><b>1</b> &ensp;why I <input id='0' type='Circle' /> late. 
           </div>
    
           <div style=""><b>2</b> &ensp;where I <input id='1' type='Circle' /> been.
           </div>

           <div style=""><b>3</b> &ensp;if I <input id='2' type='Circle' /> seen a good film.
           </div>

           <div style=""><b>4</b> &ensp;whether I <input id='3' type='Circle' /> hungry.
           </div>

           <div style=""><b>5</b> &ensp;what I <input id='4' type='Circle' /> doing the next day.
           </div>

           <div style=""><b>6</b> &ensp;if I <input id='5' type='Circle' /> done my homework.
           </div>
    
       </div>
      
      `,
    },
  },
  3: {
    unit: "Unit 8",
    id: "SB9-2024-U8-P83-E3",
    exerciseKey: "img/FriendsPlus/Page83/Key/E3answerKey.png",
    component: T6,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      width: 800,
      paddingTop: 5,
    },
    textareaStyle: {
      // background: 'none',
      width: 800,
      fontSize: 22,
      // height: 70,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "3",
        title: `Report the questions.`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='font-size: 22px; line-height: 45px; width: 27cm'>
          <div>
          'Did you see a good film?' Tom asked Lucy.<br>
          <i style='color: gray'>Tom asked Lucy if she had seen a good film.</i>
          </div>
        
          <div style=''>
           <div><b>1</b>&ensp; ‘Where are you going?’ Ellie asked Sam.</div>
           <div><textarea id='0' rows='2'></div>

           <div><b>2</b>&ensp; ‘Do you like rap?’ Dad asked me.</div>
           <div><textarea id='1' rows='2'></div>

           <div><b>3</b>&ensp; ‘Can you help me?’ Joe asked his sister.</div>
           <div><textarea id='2' rows='2'></div>

           <div><b>4</b>&ensp; ‘What time did you leave?’ Tim asked Eric.</div>
           <div><textarea id='3' rows='2'></div>

           <div><b>5</b>&ensp; ‘Will you go with me?’ my friend asked me.</div>
           <div><textarea id='4' rows='2'></div>

           <div><b>6</b>&ensp; ‘How did you get home?’ Mum asked me.</div>
           <div><textarea id='5' rows='2'></div>

          </div>
        </div>
        `,
        answer: [
          "Ellie asked Sam where he was going.",
          "My dad asked me if I liked rap. | My dad asked me whether I liked rap.",
          "Joe asked his sister if she could help him. | Joe asked his sister whether she could help him.",
          "Tim asked Eric what time he had left.",
          "My friend asked me if I would go with him. | My friend asked me whether I would go with him. | My friend asked me if I would go with her. | My friend asked me whether I would go with her.",
          "Mum asked me how I had got home.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "SB9-2024-U8-P83-E4",
    exerciseKey: "img/FriendsPlus/Page83/Key/E4answerKey.png",
    component: T6,
    maxLength: 1,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 23,
      height: 27,
      textAlign: "center",
      // textTransform: "uppercase",
      width: 50,
      paddingTop: 5,
      fontWeight: 600,
    },
    titleQuestion: [
      {
        num: "4",
        title: `Study the direct and reported sentences in the 
        table. Then match sentences 1–4 with types of sentences a–d.`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative; margin-left: 80px'>
        <img style="width:20cm; height:12cm" src='img/FriendsPlus/Page83/E4/1.jpg' />

        <div style='position: absolute; top: 141px; left: 72px'>#</div>
        <div style='position: absolute; top: 216px; left: 216px'>#</div>
        <div style='position: absolute; top: 290px; left: 233px'>#</div>
        <div style='position: absolute; top: 363px; left: 75px'>#</div>
        </div>
        `,
        answer: ["b", "a", "d", "c"],
      },
    ],
  },
  5: {
    unit: "Unit 8",
    id: "SB9-2024-U8-P83-E5",
    exerciseKey: "img/FriendsPlus/Page83/Key/E5answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 23,
      height: 27,
      // textTransform: "uppercase",
      width: 250,
      paddingTop: 5,
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "5",
        title: `Complete the sentences with the correct form of the verbs in brackets.`,
        color: "#5cc9dd",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    giaiThua: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='display: flex; font-size: 23px; line-height: 50px; width: 27cm'>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br>
          <b>2</b>&ensp;<br>
          <b>3</b>&ensp;<br>
          <b>4</b>&ensp;<br>
          <b>5</b>&ensp;<br>
          <b>6</b>&ensp;<br>
          
        </div>

        <div style=''>
         <div>The teacher offered # me a dictionary. (lend)</div>
         <div>Marcus suggested that we # at 5.00 p.m. (meet)</div>
         <div>She told Sam # his mobile phone to class. (not bring)</div>
         <div>Mum asked us # her tidy the room. (help)</div>
         <div>The judge told the prisoner #. (stand up)</div>
         <div>Dad offered # me to the cinema. (drive)</div>

        </div>
        
        `,
        answer: [
          "to lend",
          "meet",
          "not to bring",
          "to help",
          "to stand up",
          "to drive",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 8",
    id: "SB9-2024-U8-P83-E6",
    exerciseKey: "img/FriendsPlus/Page83/Key/E6answerKey.png",
    component: T6,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      width: 800,
      paddingTop: 5,
    },
    textareaStyle: {
      // background: 'none',
      width: 800,
      fontSize: 22,
      // height: 70,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "6",
        title: `Rewrite the sentences in reported speech.`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='font-size: 22px; line-height: 45px; width: 27cm'>
        
          <div style=''>
           <div><b>1</b>&ensp; ‘Help me with my homework, please,’ Sally asked me.</div>
           <div><textarea id='0' rows='2'></div>

           <div><b>2</b>&ensp; ‘Why don’t we watch a film tonight?’ Steve suggested.</div>
           <div><textarea id='1' rows='2'></div>

           <div><b>3</b>&ensp; ‘Wait outside,’ the police officer told us.</div>
           <div><textarea id='2' rows='2'></div>

           <div><b>4</b>&ensp; ‘I can make dinner this evening,’ Mary said.</div>
           <div><textarea id='3' rows='2'></div>

           <div><b>5</b>&ensp; ‘Don’t make noise,’ the teacher told her students.</div>
           <div><textarea id='4' rows='2'></div>

           <div><b>6</b>&ensp; ‘Please don’t give us homework,’ we asked the teacher.</div>
           <div><textarea id='5' rows='2'></div>

          </div>
        </div>
        `,
        answer: [
          "Sally asked me to help her with her homework.",
          "Steve suggested that we watch a film.",
          "The police officer told us to wait outside.",
          "Mary offered to make dinner.",
          "The teacher told her students not to make noise.",
          "We asked the teacher not to give us homework.",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 8",
    id: "SB9-2024-U8-P83-E7",
    // exerciseKey: "img/FriendsPlus/Page71/Key/E2answerKey.png",
    component: T6,
    //  maxLength: 25,
    hideBtnFooter: true,
    recorder: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 23,
      paddingTop: 5,
      // height: 200,
    },
    titleQuestion: [
      {
        num: "",
        title: `<div style='margin-bottom: 10px'><span style='color: #1C5161 ;font-weight: 900; border: 2px solid darkgray; border-radius: 15px; padding: 5px'>Finished?</span></div> 
        Write five questions, requests or offers. Then work in pairs and report your partner's questions.`,
        color: "#5cc9dd",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,

    questions: [
      {
        title: `
        <img style="width:17cm; height:3cm" src='img/FriendsPlus/Page83/E7/1.jpg' />
        <div>
        <textarea id='0' rows='10'>
        </div>
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
