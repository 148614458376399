import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 5",
    id: "SB9-2024-U5-P55-E1",
    exerciseKey: "img/FriendsPlus/Page55/Key/E1answerKey.png",
    component: T6,
    audio: "Audios/1.33.mp3",
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "1px solid",
      fontSize: 23,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "left",
      width: 800,
      paddingTop: 5,
    },
    selectStyle: {
      height: 35,
      width: 126,
      textAlign: "center",
      fontSize: 21,
    },
    selectOptionRandom: true,
    selectOptionValues: ["zero", "first", "second"],
    titleQuestion: [
      {
        num: "1",
        title: `Study sentences a–g and match them to the 
        types of conditional 1–3.`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `

        <div style='position: relative; font-size: 22px'>
        <img style="width:14cm; height:9cm; margin-left: 100px" src='img/FriendsPlus/Page55/E1/1.jpg' />
        <div style='display: flex;  width: 25cm; line-height: 30px; margin-top: 20px'>
          <div>
            <div style="display: flex"><b>a</b> &ensp;Sound doesn't travel if there isn't any air.&ensp; <select id=0></select></div>

            <div style="display: flex"><b>b</b> &ensp;Diseases will become resistant to antibiotics 
            if we aren't careful. &ensp;<select id=1></select></div> 

            <div style="display: flex"><b>c</b> &ensp;If it rains, we can stay at home. &ensp;<select id=2></select></div>

            <div style="display: flex"><b>d</b> &ensp;If she studies hard, she may pass the exam. &ensp; <select id=3></select></div>

            <div style="display: flex"><b>e</b> &ensp;If you don't hurry, you might miss the last 
            train. &ensp; <select id=4></select></div>

            <div style="display: flex"><b>f</b> &ensp;If I had more time, I could visit you. &ensp; <select id=5></select></div>

            <div style="display: flex"><b>g</b> &ensp;If he were here, he would help us. &ensp; <select id=6></select></div>

          </div>
        </div>
        `,
        // InputRong: true,
        answer: [
          "zero",
          "first",
          "first",
          "first",
          "second",
          "second",
          "second",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 5",
    id: "SB9-2024-U5-P55-E2",
    exerciseKey: "img/FriendsPlus/Page55/Key/E2answerKey.png",
    component: T6,
    // audio: "Audios/1.33.mp3",
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 24,
      height: 27,
      width: 150,
      // textTransform: 'uppercase',
      // borderStyle: "dotted",
      textAlign: "center",
      paddingTop: 5,
    },
    hintBox: [
      {
        src: ["past", "present", "will"],
        width: 350,
        borderColor: "#5cc9dd",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Complete the table with the words in the box.`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id='0'></hintbox>
        <div style='position: relative; font-size: 22px'>
        <img style="width:21cm; height:9cm; margin-left: 30px" src='img/FriendsPlus/Page55/E2/1.jpg' />
        <div style='position: absolute; top: 141px; left: 452px'><sup><b>1</b></sup>#</div>
        <div style='position: absolute; top: 200px; left: 455px'><sup><b>2</b></sup>#</div>
        <div style='position: absolute; top: 253px; left: 252px'><sup><b>3</b></sup>#</div>
        </div>
        `,
        // InputRong: true,
        answer: ["present", "will", "past"],
      },
    ],
  },
  3: {
    unit: "Unit 5",
    id: "SB9-2024-U5-P55-E3",
    exerciseKey: "img/FriendsPlus/Page55/Key/E3answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "3",
        title: `Study the examples of conditionals in the
        visitor’s guide. Choose the correct verb forms.`,
        color: "#5cc9dd",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "eat / eaten",
          "won't / wouldn't", //2
          "burns / burnt",
          "share / shared",
          "grows / grew",
          "they'll / they'd_be",
        ],
        answers: ["0-0", "1-0", "2-0", "3-4", "4-0", "5-0"],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative; font-size: 22px'>
       <img style="width:20cm; height:18cm; margin-left: 100px" src='img/FriendsPlus/Page55/E3/1.jpg' />
       <div style='display: flex; position: absolute; top: 76px; left: 138px; width: 14.5cm; line-height: 40px'>
         <div>
           <div style=""><b>•</b> &ensp;Welcome to Earth! We're between the planets Venus and Mars.</div>
    
           <div style=""><b>•</b> &ensp;To survive, humans need food and water - but if we <sup><b>1</b></sup><input id='0' type='Circle' /> too much food, we become ill.
           </div>

           <div style=""><b>•</b> &ensp;Most plants here need water, food and light. If you keep a plant in the dark, it <sup><b>2</b></sup><input id='1' type='Circle' /> grow.</div>

           <div style=""><b>•</b> &ensp;We love being outside in the sun, but if we don't use special protection, our skin <sup><b>3</b></sup><input id='2' type='Circle' />!</div>

           <div style=""><b>•</b> &ensp;We're friendly, but people here can be selfish. If we <sup><b>4</b></sup><input id='3' type='Circle' /> things more, it would be a better place.</div>

           <div style=""><b>•</b> &ensp; There are a lot of us here. If our population <sup><b>5</b></sup><input id='4' type='Circle' /> more, we'll need a new place to live.</div>

           <div style=""><b>•</b> &ensp;A lot of other species are near extinction. If we don't protect them, <sup><b>6</b></sup><input id='5' type='Circle' /> disappear.</div>
    
         </div>
       </div>
    
       </div>
      
      `,
    },
  },
  4: {
    unit: "Unit 5",
    id: "SB9-2024-U5-P55-E4",
    exerciseKey: "img/FriendsPlus/Page55/Key/E4answerKey.png",
    component: T6,
    // audio: "Audios/1.33.mp3",
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 23,
      height: 27,
      width: 200,
      // textTransform: 'uppercase',
      // borderStyle: "dotted",
      textAlign: "center",
      paddingTop: 5,
    },
    // hintBox: [
    //   {
    //     src: ["past", "present", "will"],
    //     width: 350,
    //     borderColor: "#5cc9dd",
    //     marginLeft: 0,
    //   },
    // ],
    titleQuestion: [
      {
        num: "4",
        title: `Study the examples and complete the rule.`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative; font-size: 23px; margin-left: 100px'>
        <img style="width:14cm; height:7cm; margin-left: 30px" src='img/FriendsPlus/Page55/E4/1.jpg' />
        <img style="width:17cm; height:7cm; margin-left: 30px" src='img/FriendsPlus/Page55/E4/2.jpg' />
        <div style='position: absolute; top: 333px; left: 73px; line-height: 51px; width: 15cm'>We use the # tense to make wishes 
        about a present situation which is not currently true or does not happen.</div>
        </div>
        `,
        // InputRong: true,
        answer: ["simple past"],
      },
    ],
  },
  5: {
    unit: "Unit 5",
    id: "SB9-2024-U5-P55-E5",
    exerciseKey: "img/FriendsPlus/Page55/Key/E5answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    // textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 23,
      height: 27,
      // textTransform: "uppercase",
      width: 800,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "5",
        title: `Rewrite the sentences.`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display: flex; font-size: 23px; line-height: 55px; width: 27cm'>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br><br>
          <b>2</b>&ensp;<br><br>
          <b>3</b>&ensp;<br><br>
          <b>4</b>&ensp;<br><br>
          
        </div>

        <div style=''>
         <div>He wants to speak to aliens from space.</div>
         <div>He wishes # .</div>

         <div>His English is not good enough to study space science.</div>
         <div>He wishes # .</div>

         <div>They can't afford explorations to discover new species.</div>
         <div>They wish # .</div>

         <div>She is not strong enough for the journey to Sơn Đoòng cave.</div>
         <div>She wishes # .</div>

        </div>
        </div>
        
        `,
        answer: [
          "he could speak to aliens from space",
          "his English were good enough to study space science",
          "they could afford explorations to discover new species",
          "she were strong enough for the journey to Sơn Đoòng cave",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 5",
    id: "SB9-2024-U5-P55-E6",
    exerciseKey: "img/FriendsPlus/Page55/Key/E6answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      width: 290,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "6",
        title: `<img style='height:1.1cm; width: 4cm; margin-bottom: 5px' src='img/FriendsPlus/icon/8.jpg'/>
        Complete questions 1–6 with the correct verb forms.`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display: flex; font-size: 22px; line-height: 50px; width: 27cm'>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br>
          <b>2</b>&ensp;<br>
          <b>3</b>&ensp;<br><br>
          <b>4</b>&ensp;<br>
          <b>5</b>&ensp;<br>
          <b>6</b>&ensp;<br>
          
        </div>

        <div style=''>
         <div>If you # (be) a millionaire, what would you do?</div>

         <div>If you travelled to Mars, what things from Earth # (you / miss)?</div>

         <div>If you could only take three species of 
         animal to another planet, which # (you save)?</div>

         <div>If you travel in the future, where # (you / go)?</div>

         <div>Do you wish you # (become) the best student in the school? </div>

         <div>Do you wish you # (own) a car or rely on public transport?</div>

        </div>
        </div>
        
        `,
        answer: [
          "were",
          "would you miss",
          "would you save",
          "will you go",
          "became | would become",
          "owned | would own",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 5",
    id: "SB9-2024-U5-P55-E7",
    // exerciseKey: "img/FriendsPlus/Page71/Key/E2answerKey.png",
    component: T6,
    //  maxLength: 25,
    hideBtnFooter: true,
    recorder: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 22,
      paddingTop: 5,
      // height: 200,
    },
    titleQuestion: [
      {
        num: "",
        title: `<div style='margin-bottom: 10px'><span style='color: #1C5161 ;font-weight: 900; border: 2px solid darkgray; border-radius: 15px; padding: 5px'>Finished?</span></div> 
        Work in pairs. Ask and answer the questions in exercise 6.`,
        color: "#5cc9dd",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,

    questions: [
      {
        title: `
        <img style="width:26.5cm; height:12cm" src='img/FriendsPlus/Page55/E7/2.png' />
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
