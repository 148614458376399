import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import url from "../../configs/url";
const json = {
  5: {
    unit: "Unit 1",
    id: "SB9-2024-U1-P16-E5",
    // audio: "img/FriendsPlus/Page110/Audio/audio-e2-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "90%",
      // marginLeft: 150,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page16/E5/1.jpg" }]],
  },
  1: {
    unit: "Unit 1",
    id: "SB9-2024-U1-P16-E1",
    // audio: "Audios/1.04.mp3",
    exerciseKey: "img/FriendsPlus/Page16/Key/E1answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "1",
        title: `<img style='height:1.1cm; width: 1.3cm; margin-bottom: 5px' src='img/FriendsPlus/icon/4.jpg'/> Look at the photos and read sentences a–d. 
        Which three sentences describe the experience 
        of the boy in the photos? Compare your ideas in pairs.`,
        color: "#DC1E74",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 3,
        listWords: ["a <br> b <br> c <br> d"],
        answers: ["0-0", "0-4", "0-12"],
        initialValue: [],
      },
      Layout: `
  <div style='font-size: 23px; margin-left: 50px; margin-bottom: 100px'>
   <img style="width:19cm; height:9cm" src='img/FriendsPlus/Page16/E1/1.jpg' />
   
   <div style='display: flex; width: 26cm; line-height: 45px'>
      <div style='font-weight: 600; margin-left: 50px'><input id='0' type='Circle' /></div>  
      <div style='margin-left: 20px'>
       <div style="">When I got home, I saw a beautiful brand new bike in the living room.</div>

       <div style="">The first thing I did was to go outside and try 
       out my new bike.</div>
       <div style="">We were swimming in the sea when I realised 
       that I’d lost my watch.</div>
       <div style="">There I was, all dirty and crying, and my brand 
       new bike was damaged.</div>

       </div>
     </div>
        
    </div>
      
      `,
    },
  },

  2: {
    unit: "Unit 1",
    id: "SB9-2024-U1-P16-E1",
    audio: "Audios/1.09.mp3",
    video: url.cndUrl + "Videos/Unit 1.mp4 - Ex 2, 3 (trang 16).mp4",
    exerciseKey: "img/FriendsPlus/Page16/Key/E2answerKey.png",
    component: T6,
    // textAlign: "center",
    // maxLength:5,
    stylesTextInput: {
      width: 880,
      paddingTop: 5,
      background: "none",
      borderBottom: "1px solid",
      fontSize: 22,
      // fontWeight: 600,
    },

    titleQuestion: [
      {
        num: "2",
        title: `<headphone name=1.09 src='Audios/1.09 tieu_de.mp3' color='black'  typeImg="sound2"></headphone> Watch or listen to Theo’s anecdote 
        and check your answers to exercise 1. What happened to Theo?`,
        color: "#DC1E74",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='margin-bottom:40px;font-size: 22px; line-height: 45px'>
          <img style='height:12.5cm' src="img/FriendsPlus/Page16/E2/1.png"/><br>
          <div style='font-weight: 600'>What happened to Theo?</div>
          <div>#</div>
        </div>
       
        `,
        answer: ["He fell off his new bike."],
      },
    ],
  },
  3: {
    unit: "Unit 1",
    id: "SB9-2024-U1-P16-E1",
    audio: "Audios/1.09.mp3",
    video: url.cndUrl + "Videos/Unit 1.mp4 - Ex 2, 3 (trang 16).mp4",
    exerciseKey: "img/FriendsPlus/Page16/Key/E3answerKey.png",
    component: T6,
    // maxLength:4,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 23,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 280,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "3",
        title: `<headphone name=1.09 src='Audios/1.09 tieu_de.mp3' color='black'  typeImg="sound2"></headphone> When we tell an anecdote, we 
        need to give details that help the other person understand (time, place, people and events). 
        Watch or listen again. Complete 1–4 in the Key Phrases.`,
        color: "#DC1E74",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative; font-size: 23px'>
        <img style="width:22.5cm; height:12.5cm" src='img/FriendsPlus/Page16/E3/1.jpg' />
        <div style='display: flex; position: absolute; top: 145px; left: 44px; width: 20cm; line-height: 45px'>
          <div>
            <div>Have I told you about the time I <sup><b>1&ensp;</b></sup># ?</div>
            <div>It was the day of <sup><b>2&ensp;</b></sup># .</div>
            <div>I remember feeling <sup><b>3&ensp;</b></sup># .</div>
            <div>And that’s it!</div>
            <div>All in all, it was / wasn’t a/an <sup><b>4&ensp;</b></sup># experience.</div>
            <div>Luckily / Thankfully / Sadly, …</div>

          </div>
        </div>
               
        </div>
        `,
        answer: [
          "got my first bicycle",
          "my tenth birthday",
          "really excited",
          "great",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 1",
    id: "SB9-2024-U1-P16-E4",
    exerciseKey: "img/FriendsPlus/Page9/Key/E7answerKey.png",
    component: T6,
    //  maxLength: 30,
    recorder: true,
    hideBtnFooter: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 800,
      fontSize: 22,
      height: 120,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "4",
        title: `<img style='height:1.2cm; width: 3.8cm; margin-bottom: 5px' src='img/FriendsPlus/icon/5.jpg'/>
        Work in pairs. Follow the steps in the Speaking Guide.`,
        color: "#DC1E74",
        left: 50,
        width: "26cm",
      },
    ],
    questions: [
      {
        title: `
        <img style="width:15cm; height:22cm; margin-left: 170px" src='img/FriendsPlus/Page16/E4/1.jpg' />
        
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
