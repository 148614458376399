import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 4",
    id: "SB9-2024-U4-P41-E1",
    exerciseKey: "img/FriendsPlus/Page41/Key/E1answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "left",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 200,
      paddingTop: 5,
    },
    hintBox: [
      {
        src: ["behave", "blood", "eye", "hair", "hand", "skin"],
        width: 650,
        borderColor: "#5cc9dd ",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Read examples 1–5. What do the modal verbs 
        in <span style='color: #5cc9dd'>blue</span> mean? Write <i>possibly, definitely</i> or <i>definitely not</i>.`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display: flex; font-size: 22px; line-height: 50px; width: 26cm'>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br>
          <b>2</b>&ensp;<br>
          <b>3</b>&ensp;<br>
          <b>4</b>&ensp;<br>
          <b>5</b>&ensp;<br>
          
        </div>

        <div style='width: 25cm'>
         <div>They <span style='color: rgb(17,205,239)'>could</span> be expressing sadness. &ensp;#</div>
         <div>The evidence suggests that animals <span style='color: rgb(17,205,239)'>must</span> feel something.&ensp;#</div>
         <div>They <span style='color: rgb(17,205,239)'>can't</span> simply be like robots.&ensp;#</div>
         <div>We <span style='color: rgb(17,205,239)'>may</span> know more in the future.&ensp;#</div>
         <div>They <span style='color: rgb(17,205,239)'>might</span>, perhaps, have emotions.&ensp;# </div>
        
        </div>
        </div>
        
        `,
        answer: [
          "possibly",
          "definitely",
          "definitely not",
          "possibly",
          "possibly",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 4",
    id: "SB9-2024-U4-P41-E2",
    exerciseKey: "img/FriendsPlus/Page41/Key/E2answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "2",
        title: `Choose the correct options to complete the rules.`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "possibility / certainty",
          "certain / not_certain", //2
          "change / don't_change",
          "always / never",
        ],
        answers: ["0-0", "1-0", "2-4", "3-4"],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative;'>
       <img style="width:20cm; height:11.5cm; margin-left: 50px" src='img/FriendsPlus/Page41/E2/1.jpg' />
       <div style='display: flex; position: absolute; top: 83px; left: 83px; width: 18cm; line-height: 42px'>
        <div style='margin-right: 15px'>
          <div><b>1</b></div><br>
          <div><b>2</b></div><br>
          <div><b>3</b></div><br>
          <div><b>4</b></div>
        </div> 
        <div>
           <div style="">We use <i>may, might</i> and <i>could</i> to expres <input id='0' type='Circle' />.</div>
    
           <div style="">We use <i>must</i> and <i>can't</i> when we are 
           <input id='1' type='Circle' /> about things.</div>
    
           <div>The modal verbs in exercise 1 <input id='2' type='Circle' /> in the <i>he, she</i> and <i>it</i> forms.</div>

           <div>We <input id='3' type='Circle' /> use <i>to</i> after modal verbs.</div>
         </div>
       </div>
    
       </div>
      
      `,
    },
  },
  3: {
    unit: "Unit 4",
    id: "SB9-2024-U4-P41-E3",
    exerciseKey: "img/FriendsPlus/Page41/Key/E3answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "left",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 220,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "3",
        title: `Complete the sentences with appropriate modal verbs.`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display: flex; font-size: 22px; line-height: 50px; width: 26cm'>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br>
          <b>2</b>&ensp;<br>
          <b>3</b>&ensp;<br>
          <b>4</b>&ensp;<br>
          <b>5</b>&ensp;<br>
          <b>6</b>&ensp;<br>
          
        </div>

        <div style='width: 25cm'>
         <div>She’s smiling, so she # be unhappy.</div>
         <div>I’m not sure, but they # live in Huế.</div>
         <div>You’re going to Canada? You # be excited!</div>
         <div>Orcas are rare here, but you # see one if you’re lucky.</div>
         <div>It # be frightening to see a shark when you’re swimming.</div>
         <div>England aren’t a great football team. They # not win the match tomorrow.</div>
        
        </div>
        </div>
        
        `,
        answer: [
          "can't",
          "may | might | could",
          "must",
          "may | might | could",
          "must",
          "may | might",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 4",
    id: "SB9-2024-U4-P41-E4",
    // exerciseKey: "img/FriendsPlus/Page41/Key/E4answerKey.png",
    component: T6,
    //  maxLength: 30,
    recorder: true,
    hideBtnFooter: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 800,
      fontSize: 22,
      height: 120,
      paddingTop: 5,
    },
    hintBox: [
      {
        src: [
          "be frightening",
          "be fun",
          "be intelligent",
          "be surprising",
          "have feelings",
          "have fun",
        ],
        width: 800,
        borderColor: "#5cc9dd ",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `<img style='height:1.2cm; width: 3.8cm; margin-bottom: 5px' src='img/FriendsPlus/icon/3.jpg'/>
        Work in pairs. Exchange opinions about ideas 1–6 using words from the box and the modal verbs.`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    questions: [
      {
        title: `
        <hintbox id='0'></hintbox>
        <img style="width:19cm; height:7.5cm; margin-left: 83px; margin-top: 40px;" src='img/FriendsPlus/Page41/E4/1.jpg' />
        
        
        `,
        answer: [""],
      },
    ],
  },
  5: {
    unit: "Unit 4",
    id: "SB9-2024-U4-P41-E5",
    // exerciseKey: "img/FriendsPlus/Page71/Key/E2answerKey.png",
    component: T6,
    //  maxLength: 25,
    hideBtnFooter: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 22,
      paddingTop: 5,
      // height: 200,
    },
    titleQuestion: [
      {
        num: "",
        title: `<div style='margin-bottom: 10px'><span style='color: #1C5161 ;font-weight: 900; border: 2px solid darkgray; border-radius: 15px; padding: 5px'>Finished?</span></div> 
        Write five sentences about the animal emotions using modal verbs.`,
        color: "#5cc9dd",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,

    questions: [
      {
        title: `
        <div style='font-size: 22px; line-height: 45px; margin-top: 20px'>
          <textarea id='0' rows='10'>
        </div>
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
