import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import url from "../../configs/url";

const json = {
  5: {
    unit: "Unit 6",
    id: "SB9-2024-U6-P64-E5",
    // audio: "img/FriendsPlus/Page110/Audio/audio-e2-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "100%",
      marginLeft: 50,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page64/E5/1.jpg" }]],
  },
  1: {
    unit: "Unit 6",
    id: "SB9-2024-U6-P64-E1",
    audio: "Audios/2.06.mp3",
    exerciseKey: "img/FriendsPlus/Page64/Key/E1answerKey.png",
    component: T6,
    //  //  maxLength: 30,
    recorder: true,
    // hideBtnFooter: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 550,
      fontSize: 23,
      // height: 120,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "1",
        title: `<headphone name=2.06 src='Audios/2.06 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
        Read and listen to the dialogue. What type of voluntary work does the interviewer offer 
        Caro? Practise the dialogue with a partner.`,
        color: "#DC1E74",
        left: 50,
        width: "27cm",
      },
    ],

    questions: [
      {
        title: `
    
        <div style='position: relative; margin-left: -50px'>
        <img style="width:27cm; height: 18.5cm" src='img/FriendsPlus/Page64/E1/1.jpg' />
        <div style='position: absolute; top: 420px; left: 499px; width: 15cm; font-size: 23px'>
        <div><b>What type of voluntary work does the interviewer offer Caro?</b></div>
        <textarea id='0' rows='3'>
        </div>
        </div>
        
        `,
        answer: ["A tennis mentoring programme"],
      },
    ],
  },
  2: {
    unit: "Unit 6",
    id: "SB9-2024-U6-P64-E2",
    // audio: "Audios/2.06.mp3",
    exerciseKey: "img/FriendsPlus/Page64/Key/E2answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "2",
        title: `Study the Key Phrases. Which key phrases 
        do we use when we need a few seconds to 
        think about our answers?`,
        color: "#DC1E74",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 4px",
          // border: "2px solid",
          borderColor: "transparent",
          // fontWeight: "bold",
        },
        selectWordStyle: {
          // fontWeight: "bold",
          // padding: "1px 4px",
          // borderRadius: "50%",
          color: "black",
          borderBottom: "2px solid #00aeef",
          borderColor: "#00aeef",
          fontWeight: 600,
        },
        limitSelect: 6,
        listWords: [
          "I'd_like_to_do_something_that_involves_… <br> I_see_myself_as_/_I_suppose_I'm_a(n)_… <br> That's_a_good_question. <br> Let_me_see. <br> I'd_say_/_Most_people_say_(that)_I'm_… <br> A_(challenging)_experience_in_my_life_was_when_…",
        ],
        answers: ["0-8", "0-12"],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative; width: 25cm; font-size: 23px; line-height: 45px; margin-left: 100px'>
      <img style="width:19cm; height:11.5cm" src='img/FriendsPlus/Page64/E2/1.jpg' />
        <div style='position: absolute; top: 107px; left: 46px'>
           <input id='0' type='Circle' />
        </div>
      </div>
      
      `,
    },
  },
  3: {
    unit: "Unit 6",
    id: "SB9-2024-U6-P64-E3",
    audio: "Audios/2.07.mp3",
    exerciseKey: "img/FriendsPlus/Page64/Key/E3answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "3",
        title: `<headphone name=2.07 src='Audios/2.07 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
        Watch or listen to the second dialogue. 
        What voluntary work does the interviewer offer Ned? Which key phrases do you hear?`,
        color: "#DC1E74",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: {
          width: 850,
          paddingTop: 10,
          background: "none",
          fontSize: 23,
        },
        answers: ["A French homework club"],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 4px",
          // border: "2px solid",
          borderColor: "transparent",
          // fontWeight: "bold",
        },
        selectWordStyle: {
          // fontWeight: "bold",
          // padding: "1px 4px",
          // borderRadius: "50%",
          color: "black",
          borderBottom: "2px solid #00aeef",
          borderColor: "#00aeef",
          fontWeight: 600,
        },
        limitSelect: 6,
        listWords: [
          "I'd_like_to_do_something_that_involves_… <br> I_see_myself_as_/_I_suppose_I'm_a(n)_… <br> That's_a_good_question. <br> Let_me_see. <br> I'd_say_/_Most_people_say_(that)_I'm_… <br> A_(challenging)_experience_in_my_life_was_when_…",
        ],
        answers: ["0-0", "0-4", "0-8", "0-12", "0-16"],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative; width: 25cm; font-size: 23px; line-height: 45px; margin-left: 60px'>
      <div><b>What voluntary work does the interviewer offer Ned?</b></div>
      <div><input id='0'/></div><br>
      <div><b>Which key phrases do you hear?</b></div>
      <div style='position: relative; margin-left: 100px'>
      <img style="width:19cm; height:11.5cm" src='img/FriendsPlus/Page64/E2/1.jpg' />
        <div style='position: absolute; top: 107px; left: 46px'>
           <input id='0' type='Circle' />
        </div>
      </div>
      </div>
      `,
    },
  },
  4: {
    unit: "Unit 6",
    id: "SB9-2024-U6-P64-E4",
    video: url.cndUrl + "Videos/Unit 6.mp4 - Ex 3 (trang 64).mp4",
    // exerciseKey: "img/FriendsPlus/Page64/Key/E5answerKey.png",
    component: T6,
    //  maxLength: 30,
    recorder: true,
    hideBtnFooter: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 800,
      fontSize: 22,
      height: 120,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "4",
        title: `<img style='height:1.2cm; width: 3.8cm; margin-bottom: 5px' src='img/FriendsPlus/icon/5.jpg'/>
        Work in pairs. Choose one of the volunteer opportunities and practise a dialogue: 
        one person is the interviewer and the other 
        person is the student. Use the key phrases. Then swap roles.`,
        color: "#DC1E74",
        left: 50,
        width: "27cm",
      },
    ],
    questions: [
      {
        title: `
        <img style="width: 26cm; height:16.5cm" src='img/FriendsPlus/Page64/E4/1.jpg' />
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
