import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Clil",
    id: "SB9-2024-Cl-P94-E1",
    exerciseKey: "img/FriendsPlus/Page94/Key/E1answerKey.png",
    audio: "Audios/3.06.mp3",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "1",
        title: `<headphone name=3.06 src='Audios/3.06 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
        Read and listen to the text. Where do you think this text is from?`,
        color: "#D92F3B",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: ["a <br> b <br> c"],
        answers: ["0-4"],
        initialValue: [],
      },
      Layout: `
  <div style='font-size: 23px; margin-left: 50px; margin-top: 20px;'>
   
   <div style='width: 26cm; line-height: 45px'>
   <div style='display: flex'>
      <div style='font-weight: 600; margin-left: 50px'><input id='0' type='Circle' /></div>  
      
      <div style='margin-left: 20px'>
        <div style="">a biology textbook</div>
        <div style="">a public information leaflet</div>
        <div style="">a scientific report</div>
       </div>
    </div>
        
    </div>
    <img style="width:25cm; height:22cm; margin-left: -50px" src='img/FriendsPlus/Page94/E1/1.jpg' />
    </div>
      `,
    },
  },
  2: {
    unit: "Clil",
    id: "SB9-2024-Cl-P94-E2",
    exerciseKey: "img/FriendsPlus/Page94/Key/E2answerKey.png",
    component: T6,
    // hideBtnFooter:true,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 300,
      paddingTop: 5,
      fontWeight: 600,
    },
    hintBox: [
      {
        src: [
          "But it’s up to you",
          "So I’m leaning towards the film club",
          "What are your options",
          "The only bad thing about that club",
          "What are you up to",
          "Have you considered another basketball club",
          "It’s a tricky decision",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Match the words in <span style='color: #5cc9dd'>blue</span> in the text with the definitions.`,
        color: "#D92F3B",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative; font-size: 22px; line-height: 40px'>
          <div style="width:26cm; margin-left: 50px; margin-top: 20px">
          
            <b>1</b>&ensp;destroy completely (verb)<br> #<br>
            <b>2</b>&ensp;a substance the body produces to fight disease (noun)<br> #<br>
            <b>3</b>&ensp;through the mouth (adverb)<br> #<br>

          </div> <br>
          
          <img style="width:25cm; height:22cm; margin-left: -20px" src='img/FriendsPlus/Page94/E1/1.jpg' />
        </div> 
        
        `,
        answer: ["eradicated", "antibodies", "orally"],
      },
    ],
  },
  3: {
    unit: "Clil",
    id: "SB9-2024-Cl-P94-E3",
    exerciseKey: "img/FriendsPlus/Page94/Key/E3answerKey.png",
    // audio: "Audios/2.04.mp3",
    component: T6,
    // textAlign: "center",
    // maxLength:5,
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 22,
      // height: 70,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "3",
        title: `Answer the questions.`,
        color: "#D92F3B",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    // giaiThua: true,
    questions: [
      {
        title: `
        <img style="width:25cm; height:22cm; margin-left: -20px" src='img/FriendsPlus/Page94/E1/1.jpg' />

        <div style='font-size: 22px; line-height: 30px; width: 26cm'>
          <div style='font-weight: 600'><b>1</b>&ensp;Which disease no longer exists?</div>
          <div><textarea id='0' rows='2'></div>

          <div style='font-weight: 600'><b>2</b>&ensp;How are most vaccines applied to the body?</div>
          <div><textarea id='1' rows='2'></div>

          <div style='font-weight: 600'><b>3</b>&ensp;What cells produce antibodies?</div>
          <div><textarea id='2' rows='2'></div>

          <div style='font-weight: 600'><b>4</b>&ensp;Who needs the yellow fever vaccine?</div>
          <div><textarea id='3' rows='2'></div>

        </div>
       
        `,
        answer: [
          "Smallpox",
          "By injection or orally (through the mouth)",
          "The white blood cells",
          "People who live in or visit a country where the disease exists",
        ],
      },
    ],
  },
  4: {
    unit: "Clil",
    id: "SB9-2024-Cl-P94-E4",
    exerciseKey: "img/FriendsPlus/Page94/Key/E4answerKey.png",
    // audio: "Audios/2.21.mp3",
    component: T6,
    // textAlign: "center",
    // maxLength:5,
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 23,
      // height: 70,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "4",
        title: `<img style='height:1.1cm; width: 3.8cm; margin-bottom: 5px' src='img/FriendsPlus/icon/1.jpg'/>
        Work in pairs. Ask and answer the questions.`,
        color: "#D92F3B",
        left: 50,
        width: "27cm",
      },
    ],
    hideBtnFooter: true,
    // giaiThua: true,
    questions: [
      {
        title: `
        
        <div style='font-size: 23px; line-height: 40px; width: 26cm; margin-top: 20px'>
          <div style='font-weight: 600'><b>1</b>&ensp;Which diseases are mentioned in the text? 
          What are they in Vietnamese?</div>
          <div><textarea id='0' rows='2'></div>

          <div style='font-weight: 600'><b>2</b>&ensp;Which vaccinations are compulsory in 
          Việt Nam? When do you have them?</div>
          <div><textarea id='1' rows='2'></div>

        </div>
       
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
