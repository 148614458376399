import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  5: {
    unit: "Unit 6",
    id: "SB9-2024-U6-P60-E5",
    // audio: "img/FriendsPlus/Page110/Audio/audio-e2-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "100%",
      marginLeft: 0,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page60/E5/1.jpg" }]],
  },
  1: {
    unit: "Unit 6",
    id: "SB9-2024-U6-P60-E1",
    exerciseKey: "img/FriendsPlus/Page60/Key/E1answerKey.png",
    audio: "Audios/2.03.mp3",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "1",
        title: `<headphone name=2.03 src='Audios/2.03 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
        Read and listen to the poem. 
        Choose the correct options in the summary below.`,
        color: "#D92F3B",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "clothes_and_image / identity / bullies",
          "afraid / brave / a_dreamer", //2
          "bullies / friends / everyone", //3
        ],
        answers: ["0-4", "1-4", "2-8"],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative; width: 25cm; line-height: 50px; font-size: 23px'>
        <img style="width:25cm; height: 29cm" src='img/FriendsPlus/Page60/E1/1.jpg' />
         <div>
           <div style=""><b>1</b>&ensp;The poem is about <input id='0' type='Circle' />.</div>
    
           <div style=""><b>2</b>&ensp;The author is <input id='1' type='Circle' />.</div>
    
           <div style=""><b>3</b>&ensp;The audience for this poem is <input id='2' type='Circle' />.</div>
         </div>
       
       </div>
      
      `,
    },
  },
  2: {
    unit: "Unit 6",
    id: "SB9-2024-U6-P60-E2",
    exerciseKey: "img/FriendsPlus/Page60/Key/E2answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    textareaStyle: {
      // background: "none",
      // borderBottom: "1px solid",
      fontSize: 22,
      // height: 27,
      resize: "none",
      // textTransform: "uppercase",
      textAlign: "left",
      width: 850,
      marginTop: -20,
      marginBottom: -20,
      paddingTop: 5,
    },
    selectStyle: {
      height: 35,
      width: 100,
      textAlign: "center",
      fontSize: 22,
    },
    selectOptionRandom: true,
    selectOptionValues: ["true", "false"],
    titleQuestion: [
      {
        num: "2",
        title: `Read the poem again. Which of ideas 1–6 
        are in the poem? Write <i>true</i> or <i>false</i> for 
        each idea and explain your answers using 
        lines from the poem.`,
        color: "#D92F3B",
        left: 50,
        width: "27cm",
      },
    ],
    character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `

        <div style='position: relative; font-size: 22px'>
          <div style="display: flex"><b>1</b> &ensp;The writer’s identity and personality are clear for other people. (Verse B) &ensp; <select id=6></select></div>
          <textarea id=0 rows=2 ></textarea>
          <div style="display: flex"><b>2</b> &ensp;It’s OK to be different. (Verse C)&ensp; <select id=7></select></div>
          <textarea id=1 rows=2 ></textarea>
          <div style="display: flex"><b>3</b> &ensp;Our personalities can have many sides. (Verse D) &ensp; <select id=8></select></div>
          <textarea id=2 rows=2 ></textarea>  
          <div style="display: flex"><b>4</b> &ensp;The writer thinks that clothes reflect personality. (Verse D) &ensp; <select id=9></select></div>
          <textarea id=3 rows=2 ></textarea>
          
          <div style="display: flex"><b>5</b> &ensp;You can avoid bullies if you stand out from the crowd. (Verse E)&ensp; <select id=10></select></div>
          <textarea id=4 rows=2 ></textarea>
          <div style="display: flex"><b>6</b> &ensp;The writer isn’t worried what people 
          think about him / her. (Verse F) &ensp; <select id=11></select></div>
          <textarea id=5 rows=2 ></textarea>
          
        </div>
        `,
        // InputRong: true,
        answer: [
          "Only I know what I am – Don’t judge this book by its cover",
          "We can't all be the same",
          "At different times we see ourselves in a different light",
          "So if I wear black clothes, it doesn't make me a poser / It's what's inside that counts and it's not always simple",
          "Don't be afraid to stand out from the crowd",
          "I might not conform to your ideas about me / I won't say I'm sorry",
          "false",
          "true",
          "true",
          "false",
          "false",
          "true",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 6",
    id: "SB9-2024-U6-P60-E3",
    exerciseKey: "img/FriendsPlus/Page60/Key/E3answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "3",
        title: `<span style='color: #D92F3B'>VOCABULARY PLUS</span>
        Match phrases 1–5 with the correct meanings a–e.`,
        color: "#D92F3B",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          //LEFT
          {
            boxMatchStyle: {
              position: "absolute",
              top: "23px",
              left: "435px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "57px",
              left: "435px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "91px",
              left: "435px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "126px",
              left: "435px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "159px",
              left: "435px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3

          //RIGHT
          {
            boxMatchStyle: {
              position: "absolute",
              top: "27px",
              left: "604px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "59px",
              left: "604px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "93px",
              left: "604px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "127px",
              left: "604px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "162px",
              left: "604px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3
        ],
        answers: ["0-6", "1-9", "2-5", "3-8", "4-7"],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "different / the_same",
          "base / continuous", //1
          "use_to / used_to", //2
          "use_to / used_to ",
        ],
        answers: [],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative;'>
       <img style="width:30cm; height:6cm" src='img/FriendsPlus/Page60/E3/1.jpg' />
    
        <input id='0' type= 'boxMatch' />
			  <input id='1' type= 'boxMatch' />
			  <input id='2' type= 'boxMatch' />
			  <input id='3' type= 'boxMatch' />
        <input id='4' type= 'boxMatch' />

        <input id='5' type= 'boxMatch' />
        <input id='6' type= 'boxMatch' />
        <input id='7' type= 'boxMatch' />
        <input id='8' type= 'boxMatch' />
        <input id='9' type= 'boxMatch' />
				
       </div>
      
      `,
    },
  },
  4: {
    unit: "Unit 6",
    id: "SB9-2024-U6-P60-E4",
    // exerciseKey: "img/FriendsPlus/Page32/Key/E4answerKey.png",
    component: T6,
    //  maxLength: 30,
    recorder: true,
    hideBtnFooter: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 22,
      // height: 120,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "4",
        title: `<img style='height:1.1cm; width: 4cm; margin-bottom: 5px' src='img/FriendsPlus/icon/1.jpg'/> 
        Choose your favourite lines from 
        the poem. Compare with a partner. Say why you feel that way.`,
        color: "#D92F3B",
        left: 50,
        width: "26cm",
      },
    ],

    questions: [
      {
        title: `
    
        <img style="width:17cm; height:6cm" src='img/FriendsPlus/Page60/E4/1.jpg' />
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
