import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Starter",
    id: "SB9-2024-Starter-P7-E1",
    exerciseKey: "img/FriendsPlus/Page7/Key/E1answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      textAlign: "center",
      width: 200,
      // fontWeight: 600,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "1",
        title: `Read answer a-c from the internet review survey on page 6. Complete them with the time expressions in brackets.`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img style="width:17.5cm; height:7cm" src='img/FriendsPlus/Page6/E4/1.jpg' />

        <div style='display: flex; font-size: 22px; width: 25cm; line-height: 45px'>
        <div style='margin-right: 15px'>
          <b>a</b>&ensp;<br><br>
          <b>b</b>&ensp;<br><br>
          <b>c</b>&ensp;<br>
          
        </div>

        <div style=''>
         <div>I don’t # follow celebrities on 
         social media, but # I’m following 
         Daisy Ridley because she’s here in Ireland 
         making a film. (at the moment / usually)
         </div>
         <div>I # reset my password, but # 
         I’m beginning to think that it’s 
        a good idea. (never / now)
         </div>
         <div>I write a blog # but I have 
         problems because my sister is # 
         borrowing my laptop and I can’t find it. 
         (always / every week)
         </div>
         
        </div>
        </div>
        
        `,
        answer: [
          "usually",
          "at the moment",
          "never",
          "now",
          "every week",
          "always",
        ],
      },
    ],
  },
  2: {
    unit: "Starter",
    id: "SB9-2024-Starter-P7-E2",
    exerciseKey: "img/FriendsPlus/Page7/Key/E2answerKey.png",
    // audio: "Audios/3-07.mp3",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: {
      // background: "none",
      // borderBottom: "none",
      // fontSize: 23,
      // height: 27,
      // // textTransform: "uppercase",
      // borderStyle: "dotted",
      // textAlign: "center",
      // width: 250,
      // paddingTop: 5,
      // fontWeight: 600,
    },
    titleQuestion: [
      {
        num: "2",
        title: `Match rules 1-3 with examples from a-c in exercises 1. Then complete the rules with <i>present simple</i> or <i>present continuous</i>.`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          //LEFT
          {
            boxMatchStyle: {
              position: "absolute",
              top: "21px",
              left: "635px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // a
          {
            boxMatchStyle: {
              position: "absolute",
              top: "87px",
              left: "635px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // a
          {
            boxMatchStyle: {
              position: "absolute",
              top: "151px",
              left: "635px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // b
          {
            boxMatchStyle: {
              position: "absolute",
              top: "194px",
              left: "635px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // b
          {
            boxMatchStyle: {
              position: "absolute",
              top: "244px",
              left: "635px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // c
          {
            boxMatchStyle: {
              position: "absolute",
              top: "304px",
              left: "635px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // c

          //RIGHT
          {
            boxMatchStyle: {
              position: "absolute",
              top: "47px",
              left: "812px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // b
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "812px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // c
          {
            boxMatchStyle: {
              position: "absolute",
              top: "289px",
              left: "812px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // c
        ],
        answers: ["0-6", "2-6", "4-6", "1-7", "3-7", "5-8"],
        initialValue: [],
      },
      Write: {
        inputStyle: {
          background: "none",
          // borderBottom: "1.5px dotted",
          fontSize: 21,
          height: 27,
          textAlign: "center",
          width: 250,
          paddingTop: 5,
          fontWeight: 600,
        },
        answers: ["present simple", "present continuous", "present continuous"],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [],
        answers: [],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative; font-size: 21px;'>
        <div style='display: flex; width: 25cm; line-height: 40px'>
        <div style='margin-right: 15px'>
          <div style='margin-bottom: 10px'><b>a</b></div>&ensp;<br><br>
          <div style='margin-bottom: 10px'><b>b</b></div>&ensp;<br>
          <div style='margin-bottom: 10px'><b>c</b></div>&ensp;<br>

        </div>

        <div style='width: 16cm'>
         <div style='margin-bottom: 10px'>I don’t usually follow celebrities on 
         social media,<br> but at the moment I’m following 
         Daisy Ridley because she’s here in Ireland 
         making a film.
         </div>
         <div style='margin-bottom: 10px'>I never reset my password,<br> but now 
         I’m beginning to think that it’s 
        a good idea.
         </div>
         <div style='margin-bottom: 10px'>I write a blog every week <br> but I have 
         problems because my sister is always 
         borrowing my laptop and I can’t find it.
         </div>

        </div>

        <div style='margin-left: 200px; margin-top: 27px'>
        <div style='color: rgb(56,73,137); font-weight: 600'>Rule 1</div><br><br>
        <div style='color: rgb(56,73,137); font-weight: 600'>Rule 2</div><br><br>
        <div style='color: rgb(56,73,137); font-weight: 600'>Rule 3</div>
        </div> 
        </div>
        <input id='0' type= 'boxMatch' />
			  <input id='1' type= 'boxMatch' />
        <input id='2' type= 'boxMatch' />
			  <input id='3' type= 'boxMatch' />
        <input id='4' type= 'boxMatch' />
			  <input id='5' type= 'boxMatch' />

        <input id='6' type= 'boxMatch' />
        <input id='7' type= 'boxMatch' />
			  <input id='8' type= 'boxMatch' />
        

        <img style="width:20cm; height:12cm" src='img/FriendsPlus/Page7/E2/1.jpg' />
        <div style='width: 18cm; display: flex; position: absolute; top: 459px; left: 38px; line-height: 40px'>
          <div style='margin-right: 15px'>
            <b>1</b><br><br>
            <b>2</b><br><br><br>
            <b>3</b><br><br>
          </div>
          <div>
            <div>In general, we use the <input id='0'/> 
            with frequency adverbs and expressions like 
            <i>sometimes, usually</i> and <i>every week</i>.</div>

            <div>We normally use the <input id='1'/ >
            with time expressions which indicate an 
            action in progress, like <i>now</i> and <i>at the moment</i>.</div>   

            <div>We can also use the <input id='2'/>
            with <i>always</i> when we talk about a habit or 
            repeated action which annoys us.</div>   

          </div>
        </div>

      </div>
      
      `,
    },
  },
  3: {
    unit: "Starter",
    id: "SB9-2024-Starter-P7-E3",
    exerciseKey: "img/FriendsPlus/Page7/Key/E3answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      width: 250,
      paddingTop: 5,
      background: "none",
      borderBottom: "dotted 2px",
      fontSize: 22,
      // fontWeight: 600,
    },
    titleQuestion: [
      {
        num: "3",
        title: `Complete the sentences with the correct form of the verbs in brackets. Use the present continuous or present simple.`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display: flex; font-size: 22px; line-height: 50px; width: 25cm'>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br><br>
          <b>2</b>&ensp;<br><br>
          <b>3</b>&ensp;<br>
          <b>4</b>&ensp;<br>
          <b>5</b>&ensp;<br>
          <b>6</b>&ensp;<br>
          
        </div>

        <div style=''>
         <div>I # (read) <i>Paper Towns</i> by John 
         Green at the moment and I #  
         (enjoy) it.
         </div>
         <div>My friend Sal # (update) her 
         profile photo quite often but I normally # 
          (change) mine once a month.
         </div>
         <div>This phone is fast! It usually #  
         (download) a film in minutes.
         </div>
         <div>What # (you / listen) to now?
         </div>
         <div>I # (not / usually / spend) much 
         time reading blogs.
         </div>
         <div>Our Wi-Fi is really slow because my brothers #  
         (always / play) games online.
         </div>
        </div>
        </div>
        
        `,
        answer: [
          "'m reading | am reading",
          "'m enjoying | am enjoying",
          "updates",
          "change",
          "downloads",
          "are you listening",
          "don't usually spend| do not usually spend",
          "are always playing",
        ],
      },
    ],
  },
  4: {
    unit: "Starter",
    id: "SB9-2024-Starter-P7-E4",
    exerciseKey: "img/FriendsPlus/Page7/Key/E4answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    // textAlign:"center",
    stylesTextInput: { width: 900, paddingTop: 5, fontSize: 22 },
    titleQuestion: [
      {
        num: "4",
        title: `Order the phrases to make meaningful sentences.`,
        color: "#5cc9dd",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
          <div style='margin-left:0px; font-size: 22px; line-height: 50px'>
            <b>1</b>&ensp;show Oanh / to get help / where / I will / . /<br>#<br>
            <b>2</b>&ensp;tell me / find / Could you / a Vietnamese restaurant / where to / ? /<br>#<br>
            <b>3</b>&ensp;for technical support / who / Yến asked me / 
            to contact / . /<br>#<br>
            <b>4</b>&ensp;to learn a / lent me a good / My brother / new 
            language quickly / book on how / . /<br>#<br>
            <b>5</b>&ensp;a healthy diet / to me how / The doctor 
            explained / processed foods for / to avoid / . / <br>#<br>
            <b>6</b>&ensp;know when / environmentally friendly project / 
            to start our / Do you / ? /<br>#<br>
          </div>
          
        
        `,
        answer: [
          "I will show Oanh where to get help.",
          "Could you tell me where to find a Vietnamese restaurant?",
          "Yến asked me who to contact for technical support.",
          "My brother lent me a good book on how to learn a new language quickly.",
          "The doctor explained to me how to avoid processed foods for a healthy diet.",
          "Do you know when to start our environmentally friendly project?",
        ],
      },
    ],
  },
  5: {
    unit: "Starter",
    id: "SB9-2024-Starter-P7-E5",
    exerciseKey: "img/FriendsPlus/Page7/Key/E5answerKey.png",
    component: T6,
    maxLength: 35,
    // hideBtnFooter:true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 180,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "5",
        title: `Complete the sentences with <i>to</i>-infinitive of the verbs in the box. There are two extra verbs.`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    hintBox: [
      {
        src: [
          "increase",
          "make",
          "improve",
          "play",
          "phone",
          "attend",
          "travel",
          "talk",
        ],
        width: 800,
        borderColor: "#5cc9dd",
        marginLeft: 0,
      },
    ],
    questions: [
      {
        title: `
        <hintbox id='0'></hintbox>
        <div style='display: flex; font-size: 22px; line-height: 45px; margin-top: 20px; width: 26cm'>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br>
          <b>2</b>&ensp;<br>
          <b>3</b>&ensp;<br>
          <b>4</b>&ensp;<br>
          <b>5</b>&ensp;<br><br>
          <b>6</b>&ensp;<br>
          
        </div>

        <div style=''>
         <div>We can't decide whether # a 
         presentation on renewable energy or not.
         </div>
         <div>Minh asked his teacher how # his public 
         speaking skills.
         </div>
         <div>Could you show me who # to if I want to 
         rewrite my assignment?
         </div>
         <div>It's not easy for him to learn how # golf.
         </div>
         <div>My brother is still at home because he 
         hesitates about whether # his 
         ex-girlfriend's graduation ceremony.
         </div>
         <div>My best friend always plans in advance where # 
         during her summer break.
         </div>
        </div>
        </div>
        
        `,
        answer: [
          "to make",
          "to improve",
          "to talk",
          "to play",
          "to attend",
          "to travel",
        ],
      },
    ],
  },
  6: {
    unit: "Starter",
    id: "SB9-2024-Starter-P7-E6",
    exerciseKey: "img/FriendsPlus/Page7/Key/E6answerKey.png",
    component: T6,
    //  maxLength: 30,
    hideBtnFooter: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      // borderStyle: "dotted",
      textAlign: "center",
      width: 900,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "6",
        title: `<prefix></prefix> Use the question words and the verbs in the box to write down five questions about a tourist attraction in Việt Nam.`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    hintBox: [
      {
        src: [
          "get",
          "start",
          "do",
          "go",
          "contact",
          "eat",
          "buy",
          "wear",
          "visit",
          "bring",
        ],
        width: 800,
        borderColor: "#5cc9dd",
        marginLeft: 0,
      },
    ],
    questions: [
      {
        title: `
        <hintbox id='0'></hintbox>
        <img style="width:18cm; height:12cm" src='img/FriendsPlus/Page7/E6/1.jpg' />
        <div style='font-size: 22px; line-height: 45px; margin-top: 20px'>
       
          <b>1</b>&ensp;#<br>
          <b>2</b>&ensp;#<br>
          <b>3</b>&ensp;#<br>
          <b>4</b>&ensp;#<br>
          <b>5</b>&ensp;#<br>
       
          
        </div>

        
        </div>
        
        `,
        answer: ["", "", "", "", ""],
      },
    ],
  },
};

export default json;
