import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import Select_Image from "../../components/ExcerciseTypes/Select/Select_Image";

const json = {
  1: {
    unit: "Clil",
    id: "SB9-2024-Cl-P96-E1",
    exerciseKey: "img/FriendsPlus/Page96/Key/E1answerKey.png",
    // audio: "Audios/2.21.mp3",
    component: T6,
    // recorder: true,
    // textAlign: "center",
    maxLength: 1,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 50,
      paddingTop: 5,
      fontWeight: 600,
    },
    titleQuestion: [
      {
        num: "1",
        title: `Read the first sentence of each paragraph in the 
        text about Mexico City and match the headings 
        a–c with the correct paragraphs 2–4.`,
        color: "#D92F3B",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    // giaiThua: true,
    questions: [
      {
        title: `
        
        <div style='font-size: 23px; line-height: 40px; width: 26cm; margin-top: 20px'>

        <div style=''>
        <b>Answer:</b><br>
        <div style='display: flex; justify-content: space-evenly'>
          <div><b>a</b> - #</div>
          <div><b>b</b> - #</div>
          <div><b>c</b> - #</div>
        </div><br>

        </div>
          <img style="width:16cm; height:1.4cm; margin-left: -20px" src='img/FriendsPlus/Page96/E1/2.jpg' /><br>
          <img style="width:27cm; height:22cm; margin-left: -20px" src='img/FriendsPlus/Page96/E1/1.jpg' />
        </div>
       
        `,
        answer: ["4", "3", "2"],
      },
    ],
  },
  2: {
    unit: "Clil",
    id: "SB9-2024-Cl-P96-E2",
    // exerciseKey: "img/FriendsPlus/Page96/Key/E1answerKey.png",
    audio: "Audios/3.07.mp3",
    component: T6,
    // recorder: true,
    // textAlign: "center",
    maxLength: 1,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 50,
      paddingTop: 5,
      fontWeight: 600,
    },
    titleQuestion: [
      {
        num: "2",
        title: `<headphone name=3.07 src='Audios/3.07 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
        Read and listen to the text. Check your 
        answers to exercise 1.`,
        color: "#D92F3B",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    // giaiThua: true,
    questions: [
      {
        title: `
        
        <div style='font-size: 23px; line-height: 40px; width: 26cm; margin-top: 20px'>


        </div>
          <img style="width:23cm; height:3.4cm; margin-left: -20px" src='img/FriendsPlus/Page96/E2/1.png' />
          <img style="width:16cm; height:1.4cm; margin-left: -20px" src='img/FriendsPlus/Page96/E1/2.jpg' /><br>
          <img style="width:27cm; height:22cm; margin-left: -20px" src='img/FriendsPlus/Page96/E1/1.jpg' />
        </div>
       
        `,
        answer: ["4", "3", "2"],
      },
    ],
  },
  3: {
    unit: "Clil",
    id: "SB9-2024-Cl-P96-E3",
    exerciseKey: "img/FriendsPlus/Page96/Key/E3answerKey.png",
    // audio: "Audios/2.04.mp3",
    component: T6,
    // textAlign: "center",
    // maxLength:5,
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 22,
      // height: 70,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "3",
        title: `Read the text again and answer the questions.`,
        color: "#D92F3B",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    // giaiThua: true,
    character: /[,/and]/,
    questions: [
      {
        title: `
        <img style="width:27cm; height:21cm; margin-left: -20px" src='img/FriendsPlus/Page96/E1/1.jpg' />

        <div style='font-size: 22px; line-height: 30px; width: 26cm'>
          <div style='font-weight: 600'><b>1</b>&ensp;What are the natural environmental risks that 
          people living in Mexico City face?</div>
          <div><textarea id='0' rows='2'></div>

          <div style='font-weight: 600'><b>2</b>&ensp;What do the houses in the shanty towns lack?</div>
          <div><textarea id='1' rows='2'></div>

          <div style='font-weight: 600'><b>3</b>&ensp;When is the air quality especially bad?</div>
          <div><textarea id='2' rows='2'></div>

          <div style='font-weight: 600'><b>4</b>&ensp;How is the city laid out?</div>
          <div><textarea id='3' rows='2'></div>

        </div>
       
        `,
        answer: [
          "volcanoes, earthquakes and flooding",
          "proper foundations, toilets and clean drinking water",
          "from November to May",
          "in squares (a chequerboard)",
        ],
      },
    ],
  },
  4: {
    unit: "Clil",
    id: "SB9-2024-Cl-P96-E4",
    exerciseKey: "img/FriendsPlus/Page96/Key/E4answerKey.png",
    // audio: "Audios/2.21.mp3",
    component: T6,
    // textAlign: "center",
    // maxLength:5,
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 23,
      // height: 70,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "4",
        title: `<img style='height:1.1cm; width: 3.8cm; margin-bottom: 5px' src='img/FriendsPlus/icon/1.jpg'/>
        Ask and answer the questions in pairs.`,
        color: "#D92F3B",
        left: 50,
        width: "27cm",
      },
    ],
    hideBtnFooter: true,
    // giaiThua: true,
    questions: [
      {
        title: `
        
        <div style='font-size: 23px; line-height: 40px; width: 26cm; margin-top: 20px'>
          <div style='font-weight: 600'><b>1</b>&ensp;What do you think the Mexican government 
          ought to do to improve the shanty towns?</div>
          <div><textarea id='0' rows='2'></div>

          <div style='font-weight: 600'><b>2</b>&ensp;How can they improve the air quality in the city?</div>
          <div><textarea id='1' rows='2'></div>

          <div style='font-weight: 600'><b>3</b>&ensp;What kind of public transport does a city need?</div>
          <div><textarea id='2' rows='2'></div>

          <div style='font-weight: 600'><b>4</b>&ensp;Would you like to live in a megacity? Why /
          Why not?</div>
          <div><textarea id='3' rows='2'></div>

        </div>
       
        `,
        answer: [],
      },
    ],
  },
};

export default json;
