import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  6: {
    unit: "Unit 1",
    id: "SB9-2024-U1-P14-E6",
    // audio: "img/FriendsPlus/Page110/Audio/audio-e2-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "100%",
      // marginLeft: 150,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page14/E6/1.jpg" }]],
  },
  1: {
    unit: "Unit 1",
    id: "SB9-2024-U1-P14-E1",
    exerciseKey: "img/FriendsPlus/Page14/Key/E1answerKey.png",
    component: T6,
    textAlign: "center",
    // maxLength:1,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 20,
      height: 27,
      width: 200,
      paddingTop: 5,
      // textTransform: 'uppercase',
      // borderStyle: "dotted",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "1",
        title: `Read the text and match the words in <span style='color:#5cc9dd'>blue</span> with 
        their opposites (1–7).`,
        color: "#F78024",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative; font-size: 20px; line-height: 35px'>
	        <img src="img/FriendsPlus/Page14/E1/1.jpg" style='height:24cm; width: 25cm '/>
          <div style="position: absolute; top: 495px; left: 518px">
          <div style='display: flex; margin-top: 30px'>
            <div>
              <div style=''>fragile - <i>tough</i></div>
              <div><b>1</b>&ensp;unpopular<br>#</div>
              <div><b>2</b>&ensp;worthless<br>#</div>
              <div><b>3</b>&ensp;old-fashioned<br>#</div>
              <div><b>4</b>&ensp;undamaged<br>#</div>
            </div>
            <div style='margin-left: 40px'>
              <div></div><br>
              <div><b>5</b>&ensp;rare<br>#</div>
              <div><b>6</b>&ensp;used<br>#</div>
              <div><b>7</b>&ensp;useless<br>#</div>
            </div>
          </div>
            
          </div>  
        </div> 
        `,
        answer: [
          "popular",
          "valuable",
          "fashionable",
          "damaged",
          "common",
          "brand new",
          "useful",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 1",
    id: "SB9-2024-U1-P14-E2",
    audio: "Audios/1.06.mp3",
    exerciseKey: "img/FriendsPlus/Page14/Key/E2answerKey.png",
    component: T6,
    textAlign: "center",
    // maxLength:1,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 23,
      height: 27,
      width: 160,
      paddingTop: 5,
      // textTransform: 'uppercase',
      // borderStyle: "dotted",
      textAlign: "center",
    },
    hintBox: [
      {
        src: [
          "<s>common</s>",
          "damaged",
          "fashionable",
          "fragile",
          "rare",
          "tough",
          "undamaged",
          "unpopular",
          "useful",
          "valuable",
        ],
        width: 880,
        borderColor: "#F78024",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `<headphone name=1.06 src='Audios/1.06 tieu_de.mp3' color='black'  typeImg="sound2"></headphone> <span style='color: #F78024'>PRONUNCIATION: Stress in adjectives</span> Listen and repeat. How many 
        syllables are there in each adjective? Copy 
        and complete the table with the adjectives.`,
        color: "#F78024",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id='0'></hintbox>
        <div style='position: relative; font-size: 23px; line-height: 35px'>
	        <img src="img/FriendsPlus/Page14/E2/1.jpg" style='height:11cm; width: 24cm '/>
          <div style="position: absolute; top: 95px; left: 62px; text-align: center; line-height: 54px">
          <div style='display: flex'>
            <div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
            </div>
            <div style='margin-left: 40px'>
              <div style='font-weight: 600; color: gray'>common</i></div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
            </div>
            <div style='margin-left: 40px'>
              <div>#</div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
            </div>
            <div style='margin-left: 40px'>
              <div>#</div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
            </div>
          </div>
            
          </div>  
        </div> 
        `,
        InputRong: true,
        answer: [
          "|rare | tough",
          "|rare | tough",
          "|rare | tough",
          "|rare | tough",
          "|rare | tough",
          "|damaged | fragile | useful",
          "|damaged | fragile | useful",
          "|damaged | fragile | useful",
          "|damaged | fragile | useful",
          "|undamaged | valuable",
          "|valuable | undamaged",
          "|undamaged | valuable",
          "|undamaged | valuable",
          "|undamaged | valuable",
          "|fashionable | unpopular",
          "|unpopular | fashionable",
          "|unpopular | fashionable",
          "|unpopular | fashionable",
          "|unpopular | fashionable",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 1",
    id: "SB9-2024-U1-P14-E3",
    audio: "Audios/1.07.mp3",
    exerciseKey: "img/FriendsPlus/Page14/Key/E3answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "3",
        title: `<headphone name=1.07 src='Audios/1.07 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>Listen to the beginning of a podcast.
            What is it about?`,
        color: "#F78024",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: ["a<br>  b<br>  c"],
        answers: ["0-8"],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative; margin-bottom: 200px'>
      <img style="width:15cm; height:5.5cm; margin-left: 80px" src='img/FriendsPlus/Page14/E3/2.jpg' />
       <div style='display: flex; position: absolute; top: 216px; left: 38px; width: 25cm; line-height: 44px'>
       <div><input id='0' type='Circle' /></div>  
       <div style='margin-left: 20px'>
           <div style="">a drama about the discovery of a time capsule</div>
           <div style="">a show about how to make a time capsule</div>
           <div style="">a documentary about different time capsules 
           that have been found</div>
         </div>
       </div>
       </div>
      
      `,
    },
  },
  4: {
    unit: "Unit 1",
    id: "SB9-2024-U1-P14-E4",
    exerciseKey: "img/FriendsPlus/Page14/Key/E4answerKey.png",
    component: T6,
    audio: "Audios/1.08.mp3",
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "1px solid",
      fontSize: 23,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "left",
      width: 800,
      paddingTop: 5,
    },
    selectStyle: {
      height: 35,
      width: 86,
      textAlign: "center",
      fontSize: 20,
    },
    selectOptionRandom: true,
    selectOptionValues: ["true", "false"],
    titleQuestion: [
      {
        num: "4",
        title: `<headphone name=1.08 src='Audios/1.08 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
        Now listen to the whole podcast. Write 
        <i>true</i> or <i>false</i> and correct the false statements.`,
        color: "#F78024",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `

        <div style='position: relative; height: 600px; font-size: 23px'>
        <div style='display: flex; position: absolute; top: 15px; left: 38px; width: 25cm; line-height: 44px'>
          <div>
            <div style="display: flex"><b>1</b> &ensp;Workers opened the Boston capsule.&ensp; <select id=5></select></div>
            <div><input id=0/></div>

            <div style="display: flex"><b>2</b> &ensp;The historic documents and rare coins 
            were almost undamaged. &ensp;<select id=6></select></div> 
            <div><input id=1/></div>

            <div style="display: flex"><b>3</b> &ensp;A university president found the Crypt of 
            Civilisation in the 1930s. &ensp;<select id=7></select></div>
            <div><input id=2/></div>

            <div style="display: flex"><b>4</b> &ensp;The opening date for the crypt is soon. &ensp; <select id=8></select></div>
            <div><input id=3/></div>

            <div style="display: flex"><b>5</b> &ensp;People put rare objects into the crypt. &ensp; <select id=9></select></div>
            <div><input id=4/></div>
          </div>
        </div>
               
        </div>
        `,
        InputRong: true,
        answer: [
          "Experts opened it.",
          "",
          "A university president had the idea for the crypt in the 1930s. | They finished building the crypt in 1940.",
          "It's in 8113.",
          "They put ordinary objects into it.",
          "false",
          "true",
          "false",
          "false",
          "false",
        ],
      },
    ],
  },

  5: {
    unit: "Unit 1",
    id: "SB9-2024-U1-P14-E4",
    exerciseKey: "img/FriendsPlus/Page14/Key/E2answerKey.png",
    component: T6,
    //  maxLength: 25,
    hideBtnFooter: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 800,
      fontSize: 22,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "5",
        title: `<img style="width:3.5cm; height:1cm; margin-bottom: 7px" src='img/FriendsPlus/Page14/E5/1.jpg' /> Think of things which 
        people sell. Write adverts for objects 
        and put them on your classroom wall.`,
        color: "#F78024",
        left: 50,
        width: "25cm",
      },
    ],
    questions: [
      {
        title: `
        <textarea id='0' rows='8'>
       
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
