import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 3",
    id: "SB9-2024-U3-P33-E1",
    // audio: "Audios/3-07.mp3",
    exerciseKey: "img/FriendsPlus/Page33/Key/E1answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "1",
        title: `Study the sentences a–d. Then choose the 
        correct options to complete the rules.`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "clause / noun_phrase",
          "clause / noun_phrase", //2
        ],
        answers: ["0-0", "1-4"],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative;'>
      <img style="width:16.5cm; height:8.5cm; margin-left: 100px" src='img/FriendsPlus/Page33/E1/1.jpg' />
       <img style="width:15cm; height:11cm; margin-left: 100px" src='img/FriendsPlus/Page33/E1/2.jpg' />
       <div style='display: flex; position: absolute; top: 72px; left: 138px; width: 15cm; line-height: 42px'>
         <div>
           <div style=""><b>1</b> &ensp;We use a <input id='0' type='Circle' /> after 
           <i>because / as / since</i> to indicate the reason for an action or event. 
           </div>
    
           <div style=""><b>2</b> &ensp;We use a <input id='1' type='Circle' /> after <i>because of</i>
           to indicate the cause of something.
           </div>
    
         </div>
       </div>
    
       </div>
      
      `,
    },
  },
  2: {
    unit: "Unit 3",
    id: "SB9-2024-U3-P33-E2",
    exerciseKey: "img/FriendsPlus/Page33/Key/E2answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      width: 160,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "2",
        title: `Complete the sentences with <i>because</i> or <i>because of</i>.`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display: flex; font-size: 22px; line-height: 50px; width: 27cm'>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br>
          <b>2</b>&ensp;<br>
          <b>3</b>&ensp;<br>
          <b>4</b>&ensp;<br>
          <b>5</b>&ensp;<br>
          
        </div>

        <div style=''>
         <div>My sister could not reach the destination on time # the terrible traffic jam.</div>
         <div>The final test was so easy # Olive knew all the answer.</div>
         <div># the language barrier, my uncle and my aunt got divorced.</div>
         <div>All flights to Đà Nẵng were cancelled # the harsh climate conditions.</div>
         <div># Tom's laziness, he can't get the high scores in the exam.</div>
        </div>
        </div>
        
        `,
        answer: [
          "because of",
          "because",
          "Because of",
          "because of",
          "Because of",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 3",
    id: "SB9-2024-U3-P33-E3",
    exerciseKey: "img/FriendsPlus/Page33/Key/E3answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    // textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 23,
      height: 27,
      // textTransform: "uppercase",
      width: 800,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "3",
        title: `Rewrite the sentences.`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display: flex; font-size: 23px; line-height: 55px; width: 27cm'>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br><br>
          <b>2</b>&ensp;<br><br>
          <b>3</b>&ensp;<br><br>
          <b>4</b>&ensp;<br><br>
          <b>5</b>&ensp;<br>
          
        </div>

        <div style=''>
         <div>She couldn't concentrate on her work because she was too tired.</div>
         <div>Because of # .</div>

         <div>My father didn't finish the project because of the lack of necessary materials.</div>
         <div>As # .</div>

         <div>I couldn't go to the party because of my late work.</div>
         <div>Because # .</div>

         <div>Mr Tuấn got a promotion because he worked hard.</div>
         <div>Because of # .</div>

         <div>Clare doesn't feel pleased because of her low salary.</div>
         <div>Since # .</div>
         
        </div>
        </div>
        
        `,
        answer: [
          "her tiredness, she couldn't concentrate on her work | being tired, she couldn't concentrate on her work",
          "my father lacked necessary materials, he didn't finish the project",
          "my work was late, I couldn't go to the party",
          "working hard, Mr Tuấn got a promotion",
          "the salary is low, Clare doesn't feel pleased",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 3",
    id: "SB9-2024-U3-P33-E4",
    // exerciseKey: "img/FriendsPlus/Page33/Key/E4answerKey.png",
    component: T6,
    // maxLength:4,
    hideBtnFooter: true,
    // textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 23,
      height: 27,
      // textTransform: "uppercase",
      width: 500,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "4",
        title: `<img style='height:1.1cm; width: 4cm; margin-bottom: 5px' src='img/FriendsPlus/icon/8.jpg'/>
        Complete the second parts of the sentences using adverbial clauses or phrase of reason.`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display: flex; font-size: 23px; line-height: 55px; width: 27cm'>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br>
          <b>2</b>&ensp;<br>
          <b>3</b>&ensp;<br>
          <b>4</b>&ensp;<br>
          <b>5</b>&ensp;<br>
          
        </div>

        <div style=''>
         <div>Susan failed the university entrance exam # .</div>
         <div>The team won the championship # .</div>
         <div>I couldn't attend the meeting # .</div>
         <div>Nam lost the opportunity at work # .</div>
         <div>The plane couldn't take off on time # .</div>
        </div>
        </div>
        
        `,
        answer: [
          "because of",
          "because",
          "Because of",
          "because of",
          "Because of",
        ],
      },
    ],
  },
};

export default json;
