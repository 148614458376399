import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import url from "../../configs/url";

const json = {
  7: {
    unit: "Unit 2",
    id: "SB9-2024-U2-P24-E7",
    // audio: "img/FriendsPlus/Page110/Audio/audio-e2-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "100%",
      marginBottom: 150,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page24/E7/1.jpg" }]],
  },
  1: {
    unit: "Unit 2",
    id: "SB9-2024-U2-P24-E1",
    exerciseKey: "img/FriendsPlus/Page24/Key/E1answerKey.png",
    component: T6,
    audio: "Audios/1.13.mp3",
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 20,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 150,
      paddingTop: 15,
    },
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 20,
      // height: 120,
      paddingTop: 5,
    },
    hintBox: [
      {
        src: ["advice", "choose", "good idea", "options", "reckon"],
        width: 700,
        borderColor: "#DC1E74",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `<headphone name=1.13 src='Audios/1.13 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
        Complete the dialogue with the words 
        and phrases in the box. Then listen and check. 
        What suggestion does Liam make to Rosa?`,
        color: "#DC1E74",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id='0'></hintbox><br>
        <div style='position: relative;'>
          <img style="width:24.5cm; height:17.5cm" src='img/FriendsPlus/Page24/E1/1.jpg' />
          <div style=' position: absolute; top: 15px; left: 14px; width: 13cm; line-height: 28px; font-size: 20px'>
          
          <div style='display: flex'>
            <div style='width: 1.8cm'>Liam</div>
            <div style='width: 11.2cm'>Hey, Rosa. What are you up to?</div>
          </div>
          <div style='display: flex'>
            <div style='width: 1.8cm'>Rosa</div>
            <div style='width: 11.2cm'>Oh, I’m still trying to <sup><b>1&ensp;</b></sup><input id=0/> my last two subjects for fifth year.</div>
          </div>
          <div style='display: flex'>
            <div style='width: 1.8cm'>Liam</div>
            <div style='width: 11.2cm'>Seriously? Haven’t you done that yet? There’s not much time left.</div>
          </div>
          <div style='display: flex'>
            <div style='width: 1.8cm'>Rosa</div>
            <div style='width: 11.2cm'>I know. I’ve been thinking about it for ages, 
            but I can’t make up my mind.</div>
          </div>
          <div style='display: flex'>
            <div style='width: 1.8cm'>Liam</div>
            <div style='width: 11.2cm'>OK. What are your <sup><b>2&ensp;</b></sup><input id=1/> ?</div>
          </div>
          <div style='display: flex'>
            <div style='width: 1.8cm'>Rosa</div>
            <div style='width: 11.2cm'>Well, I’ve just chosen German, but I don’t know whether to do business or art. 
            Which do you <sup><b>3&ensp;</b></sup><input id=2/> is best?</div>
          </div>
          <div style='display: flex'>
            <div style='width: 1.8cm'>Liam</div>
            <div style='width: 11.2cm'>It’s up to you, Rosa. They’re totally 
            different subjects.</div>
          </div>
          <div style='display: flex'>
            <div style='width: 1.8cm'>Rosa</div>
            <div style='width: 11.2cm'>Yes, I know. I’m in two minds. The good 
            thing about art is that I know I enjoy it, 
            but then business is probably more useful. 
            So I’m sort of leaning towards business.</div>
          </div>
          <div style='display: flex'>
            <div style='width: 1.8cm'>Liam</div>
            <div style='width: 11.2cm'>Have you thought of speaking to Mr Bartley 
            about this? He gave me some really good 
            <sup><b>4&ensp;</b></sup><input id=3/> .</div>
          </div>
          <div style='display: flex'>
            <div style='width: 1.8cm'>Rosa</div>
            <div style='width: 11.2cm'><sup><b>5&ensp;</b></sup><input id=4/> ! Thanks, Liam.</div>
          </div>
          <div style='display: flex'>
            <div style='width: 1.8cm'>Liam</div>
            <div style='width: 11.2cm'>No problem.</div>
          </div>

          
        </div>
          
        </div><br>
        <div style='font-size: 20px'>
            <div><b>What suggestion does Liam make to Rosa?</b></div>
            <div><textarea id='5' rows='2'></div>
        </div>
        `,
        answer: [
          "choose",
          "options",
          "reckon",
          "advice",
          "Good idea",
          "Liam suggests that Rosa should speak to Mr Bartley.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "SB9-2024-U2-P24-E2",
    audio: "Audios/1.13.mp3",
    // exerciseKey: "img/FriendsPlus/Page9/Key/E7answerKey.png",
    component: T6,
    //  //  maxLength: 30,
    recorder: true,
    hideBtnFooter: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 22,
      // height: 120,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "2",
        title: `<headphone name=1.13 src='Audios/1.13 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
        Listen again and practise the dialogue.`,
        color: "#DC1E74",
        left: 50,
        width: "27cm",
      },
    ],

    questions: [
      {
        title: `
    
        <div style='margin-left: -50px'>
        <img style="width:27cm; height: 16.5cm" src='img/FriendsPlus/Page24/E2/1.png' />
        </div>
        
        `,
        answer: [""],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "SB9-2024-U2-P24-E3",
    audio: "Audios/1.14.mp3",
    // exerciseKey: "img/FriendsPlus/Page9/Key/E7answerKey.png",
    component: T6,
    //  //  maxLength: 30,
    recorder: true,
    hideBtnFooter: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 22,
      // height: 120,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "3",
        title: `<headphone name=1.14 src='Audios/1.14 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
        <span style='color:#DC1E74'><b>PRONUNCIATION: Rising and falling 
        intonation</b></span>&ensp; Decide whether these key phrases 
        have rising or falling intonation. Then listen and 
        repeat.`,
        color: "#DC1E74",
        left: 50,
        width: "27cm",
      },
    ],

    questions: [
      {
        title: `
    
        <div style='margin-left: 50px'>
        <img style="width:19cm; height:11cm" src='img/FriendsPlus/Page24/E3/1.jpg' />
        </div>
        
        `,
        answer: [""],
      },
    ],
  },

  4: {
    unit: "Unit 2",
    id: "SB9-2024-U2-P24-E4",
    audio: "Audios/1.15.mp3",
    video: url.cndUrl + "Videos/Unit 2.mp4 - Ex 4,5 (trang 24).mp4",
    exerciseKey: "img/FriendsPlus/Page24/Key/E4answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "4",
        title: `<headphone name=1.15 src='Audios/1.15 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
        Watch or listen to a second dialogue. Which of the key phrases do you hear?`,
        color: "#DC1E74",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 4px",
          // border: "2px solid",
          borderColor: "transparent",
          // fontWeight: "bold",
        },
        selectWordStyle: {
          // fontWeight: "bold",
          // padding: "1px 4px",
          // borderRadius: "50%",
          color: "black",
          borderBottom: "2px solid #00aeef",
          borderColor: "#00aeef",
        },
        limitSelect: 7,
        listWords: [
          "It's_up_to_you. <br> I'm_in_two_minds. <br> I'm_leaning_towards_… <br> What_are_your_options? <br> Which_do_you_reckon_(is_best)? <br> Have_you_thought_of_/_considered_that_…_? <br> The_good_thing_about_…_is_that_… ",
        ],
        answers: ["0-4", "0-16", "0-24", "0-20"],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative; width: 25cm; font-size: 23px; line-height: 38px; margin-left: 180px'>
        <img style="width:19cm; height:11cm" src='img/FriendsPlus/Page24/E4/1.jpg' />
        <div style='position: absolute; top: 111px; left: 32px'>
           <input id='0' type='Circle' />
        </div>
      </div>
      
      `,
    },
  },
  5: {
    unit: "Unit 2",
    id: "SB9-2024-U2-P24-E4",
    audio: "Audios/1.15.mp3",
    video: url.cndUrl + "Videos/Unit 2.mp4 - Ex 4,5 (trang 24).mp4",
    exerciseKey: "img/FriendsPlus/Page24/Key/E5answerKey.png",
    component: T6,
    //  //  maxLength: 25,
    // hideBtnFooter: true,
    textAlign: "left",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 900,
      paddingTop: 5,
      fontSize: 22,
      // height: 100,
    },
    titleQuestion: [
      {
        num: "5",
        title: `<headphone name=1.15 src='Audios/1.15 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
        Watch or listen again and answer the questions.`,
        color: "#DC1E74",
        left: 50,
        width: "27cm",
      },
    ],
    questions: [
      {
        title: `
        
      <div style='margin-left:0px; font-size: 22px; margin-top: 20px; line-height: 50px'>
        <div><div><b>1</b>&ensp;Why can't Victoria and Michael go to both 
        festivals?</div>
        <textarea id='0' rows='2'></div>
        <div><div><b>2</b>&ensp;What are the disadvantages of The Electric 
        Picnic, according to Victoria?</div>
        <textarea id='1' rows='2'></div>
        <div><div><b>3</b>&ensp;What’s the advantage of going to Longitude 
        with Lily?</div>
        <textarea id='2' rows='2'></div>
        
      </div>
        
        `,
        answer: [
          "They don't have enough money.|They do not have enough money.",
          "It's for older people, it's too big.|It is for older people, it's too big.|It is for older people, it is too big.",
          "She's got two spaces in her car.|She has got two spaces in her car.",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 2",
    id: "SB9-2024-U2-P24-E4",
    // exerciseKey: "img/FriendsPlus/Page9/Key/E7answerKey.png",
    component: T6,
    //  maxLength: 30,
    recorder: true,
    hideBtnFooter: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 800,
      fontSize: 22,
      height: 120,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "6",
        title: `<img style='height:1.2cm; width: 3.8cm; margin-bottom: 5px' src='img/FriendsPlus/icon/5.jpg'/>
        Read tasks A and B. Then prepare 
        and act out one of the dialogues with a partner. 
        Use some of the key phrases.`,
        color: "#DC1E74",
        left: 50,
        width: "26cm",
      },
    ],
    questions: [
      {
        title: `
        <div style='display: flex; margin-left: -30px' >
        <img style="width:15cm; height:9cm; margin-left: 0px" src='img/FriendsPlus/Page24/E3/1.jpg' />
        <img style="width:12cm; height:15cm; margin-left: 30px" src='img/FriendsPlus/Page24/E6/1.jpg' />
        </div>
        
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
