import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  8: {
    unit: "Progress Review 1",
    id: "SB9-2024-PR1-P28-E8",
    exerciseKey: "img/FriendsPlus/Page28/Key/E8answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "left",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 23,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 80,
      paddingTop: 5,
    },
    hintBox: [
      {
        src: [
          "belong to",
          "come across",
          "keep",
          "forget",
          "look forward",
          "recognise",
          "remind",
          "leave",
        ],
        width: 810,
        borderColor: "#4F3C94",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Complete the words.`,
        color: "#4F3C94",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display: flex; font-size: 23px; line-height: 50px; width: 26cm'>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br>
          <b>2</b>&ensp;<br>
          <b>3</b>&ensp;<br>
          <b>4</b>&ensp;<br>
          <b>5</b>&ensp;<br>
          <b>6</b>&ensp;<br>
          
        </div>

        <div style='width: 24cm'>
         <div>I always think t# before I say ‘yes’. I never answer immediately.</div>
         <div>Lorna makes up her m# very quickly.</div>
         <div>It’s best to keep your options o# .</div>
         <div>Pavel waits and puts o# making decisions.</div>
         <div>Do you ever rush i# things too fast?.</div>
         <div>I decide things slowly. I always take my t# .</div>
        </div>
        </div>
        
        `,
        answer: ["wice", "ind", "pen", "ff", "nto", "ime"],
      },
    ],
  },
  9: {
    unit: "Progress Review 1",
    id: "SB9-2024-PR1-P28-E9",
    exerciseKey: "img/FriendsPlus/Page28/Key/E9answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "left",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 150,
      paddingTop: 5,
    },
    hintBox: [
      {
        src: [
          "belong to",
          "come across",
          "keep",
          "forget",
          "look forward",
          "recognise",
          "remind",
          "leave",
        ],
        width: 810,
        borderColor: "#4F3C94",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "9",
        title: `Complete the sentences with one of the words 
        in brackets and add the suffixes <i>-ful</i> or <i>-less</i>.`,
        color: "#4F3C94",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display: flex; font-size: 22px; line-height: 50px; width: 26cm'>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br>
          <b>2</b>&ensp;<br><br>
          <b>3</b>&ensp;<br><br>
          <b>4</b>&ensp;<br><br>
          <b>5</b>&ensp;<br>
          <b>6</b>&ensp;<br>
          
        </div>

        <div style='width: 25cm'>
         <div>He was once a # doctor. Now he’s decided to change his career. (fear, success)</div>
         <div>Lewis and Abbie travel from place to place. 
         They’re sometimes # for a few days, but they don’t mind. (home, taste)</div>
         <div>Some people in our society throw away 
         useful things – they’re really #. (skill, care)</div>
         <div>Julia hasn’t got a traditional job, but she’s 
         a very clever and # person. (skill, money).</div>
         <div>If you want to travel the world alone, you 
         need to be brave and #. (play, fear)</div>
         <div>They think that spending too long on 
         technology is bad and #. (harm, thank)</div>
        </div>
        </div>
        
        `,
        answer: [
          "successful",
          "homeless",
          "careless",
          "skilful",
          "fearless",
          "harmful",
        ],
      },
    ],
  },
  10: {
    unit: "Progress Review 1",
    id: "SB9-2024-PR1-P28-E10",
    exerciseKey: "img/FriendsPlus/Page28/Key/E10answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "left",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 250,
      paddingTop: 5,
    },
    hintBox: [
      {
        src: [
          "belong to",
          "come across",
          "keep",
          "forget",
          "look forward",
          "recognise",
          "remind",
          "leave",
        ],
        width: 810,
        borderColor: "#4F3C94",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "10",
        title: `Complete the sentences with the present perfect 
        simple or continuous form of the verbs in 
        brackets and <i>since</i> or <i>for</i>.`,
        color: "#4F3C94",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display: flex; font-size: 22px; line-height: 50px; width: 26cm'>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br><br>
          <b>2</b>&ensp;<br>
          <b>3</b>&ensp;<br><br>
          <b>4</b>&ensp;<br><br>
          <b>5</b>&ensp;<br>
          <b>6</b>&ensp;<br>
          
        </div>

        <div style='width: 25cm'>
         <div>He # (live) an alternative lifestyle # 
         six years and he plans to continue.</div>
         <div>I # (know) my best friend # I was very young.</div>
         <div>She # (not listen) # the last hour and she still isn’t listening!</div>
         <div>They # (not have) a home # October last year.</div>
         <div>We # (buy) any new things # six months.</div>
         <div>It # (rain) # two o’clock and I don’t think it’s going to stop soon.</div>
        </div>
        </div>
        
        `,
        answer: [
          "'s been living",
          "for",
          "'ve known",
          "since",
          "hasn't been listening",
          "for",
          "haven't had",
          "since",
          "haven't bought",
          "for",
          "'s been raining",
          "since",
        ],
      },
    ],
  },
  11: {
    unit: "Progress Review 1",
    id: "SB9-2024-PR1-P27-E11",
    exerciseKey: "img/FriendsPlus/Page28/Key/E11answerKey.png",
    audio: "Audios/1.17.mp3",
    component: Circle_Write,
    inputSize: 500,
    stylesTextInput: { width: 100, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "belong to",
          "come across",
          "keep",
          "forget",
          "look forward",
          "recognise",
          "remind",
          "leave",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 150,
      },
    ],
    titleQuestion: [
      {
        num: "11",
        title: `<headphone name=1.17 src='Audios/1.17 tieu_de.mp3' color='black'  typeImg="sound2"></headphone> Listen to three students talking about 
        the first afternoon of their community work at a 
        primary school. Choose the correct options.`,
        color: "#4F3C94",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [""],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "positive / negative",
          "got_into / got_used",
          "very_positive / quite_negative",
          "got_to_know_another_helper / got_together_with_a_friend",
          "mostly_positive / really_negative",
          "got_into / got_ready",
        ],
        answers: ["0-4", "1-0", "2-0", "3-4", "4-0", "5-4"],
        initialValue: [],
      },
      Layout: `
      <div style='line-height:50px; width:24cm; margin-left: 100px'>
        <b>1</b>&ensp;Victoria had a <input id='0' type='Circle'/> experience at first, but things changed.<br>
        <b>2</b>&ensp;Victoria <input id='1' type='Circle'/> to reading stories to 
        the small children.<br>
        <b>3</b>&ensp;Mark had a <input id='2' type='Circle'/> 
        experience at the school.<br>
        <b>4</b>&ensp;Mark <input id='3' type='Circle'/> and did some sport.<br>
        <b>5</b>&ensp;Ellie had a <input id='4' type='Circle'/> experience.<br>
        <b>6</b>&ensp;Ellie <input id='5' type='Circle'/> for the painting.<br>
      </div>
      `,
    },
  },
};

export default json;
