import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  6: {
    unit: "Unit 6",
    id: "SB9-2024-U6-P65-E6",
    // audio: "img/FriendsPlus/Page110/Audio/audio-e2-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "100%",
      // marginLeft: 150,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page65/E6/1.jpg" }]],
  },
  1: {
    unit: "Unit 6",
    id: "SB9-2024-U6-P65-E1",
    // audio: "Audios/2.06.mp3",
    exerciseKey: "img/FriendsPlus/Page65/Key/E1answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "1",
        title: `Read Lan's letter. Find examples of formal writting.`,
        color: "#00A850",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 4px",
          // border: "2px solid",
          borderColor: "transparent",
          // fontWeight: "bold",
        },
        selectWordStyle: {
          // fontWeight: "bold",
          // padding: "1px 4px",
          // borderRadius: "50%",
          color: "black",
          borderBottom: "2px solid #00aeef",
          borderColor: "#00aeef",
          fontWeight: 600,
        },
        limitSelect: 21,
        listWords: [
          "31/1/11_An_Dương_Vương_Street <br> District_5 <br> Hồ_Chí_Minh_City <br><br> Ms_Ngô_Phương_Lan, <br> Head,_Personnel_Department <br> ABC_Travel_Group <br> Hồ_Chí_Minh_City <br><br> 11th_June,_20... <br><br> Dear_Ms_Ngô_Phương_Lan, <br> I_am_Lê_Ngọc_Điệp,_a_year_9_student. I_am_writing_for_further_information_about_the_volunteer_tourism_programme_organised_by_ABC_Travel_Group_advertised_on_your_website_last_week. <br> This_programme_really_interests_me_because_I_love_gaining_experience_from_tourism_experts. I_plan_to_study_tourism_at_university_so_that_I_can_become_a_tour_guide, so_it_would_be_rewarding_to_have_hands-on_experience. <br> I_was_born_with_skills_for_problem_solving_and_teamwork,_which_meets_the_requirements_for_a_tour_guide. Moreover,_last_summer's_volunteer_tourism_programme_gave_me_precious_experiences_on_travelling_software_development. <br> I_would_be_grateful_if_you_could_send_me_further_information_about_the_volunteer_programme. I_am_about_to_take_exams,_but_I_will_be_available_from_12th_July. <br><br> I_look_forward_to_hearing_from_you. <br> Yours_sincerely, <br> Lê_Ngọc_Điệp",
        ],
        answers: [
          "0-32",
          "0-36",
          "0-38",
          "0-46",
          "0-54",
          "0-60",
          "0-64",
          "0-56",
          "0-62",
          "0-66",
        ],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative; width: 27cm; font-size: 22px; line-height: 40px'>
      <img style="width:17cm; height:5.5cm" src='img/FriendsPlus/Page65/E1/1.jpg' />
      <img style="width:27cm; height:31.5cm" src='img/FriendsPlus/Page65/E1/2.jpg' />
        <div style='position: absolute; width: 24cm; top: 250px; left: 62px'>
           <input id='0' type='Circle' />
        </div>
      </div>
      
      `,
    },
  },
  2: {
    unit: "Unit 6",
    id: "SB9-2024-U6-P65-E2",
    // audio: "Audios/2.06.mp3",
    exerciseKey: "img/FriendsPlus/Page65/Key/E2answerKey.png",
    component: T6,
    // textAlign: "center",
    maxLength: 1,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 23,
      height: 27,
      width: 60,
      paddingTop: 5,
      // textTransform: 'uppercase',
      textAlign: "center",
    },
    textareaStyle: {
      // background: "none",
      width: 700,
      fontSize: 20,
      // height: 70,
      paddingTop: 5,
    },

    titleQuestion: [
      {
        num: "2",
        title: `Read the letter again. Put topics a–d in the order 
        they appear in the text.`,
        color: "#00A850",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative; display: flex; font-size: 23px; line-height: 35px; '>
          <div>
          <img style="width:17cm; height:30cm" src='img/FriendsPlus/Page65/E2/1.jpg' />
          </div>

          <div>
          <b>The order: </b><br>
          # -> # -> # -> #
          </div>

        <div>
       
        `,
        answer: ["c", "a", "d", "b"],
      },
    ],
  },
  3: {
    unit: "Unit 6",
    id: "SB9-2024-U6-P65-E3",
    // audio: "Audios/2.06.mp3",
    exerciseKey: "img/FriendsPlus/Page65/Key/E3answerKey.png",
    component: T6,
    // textAlign: "center",
    // maxLength:5,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 23,
      height: 27,
      width: 400,
      paddingTop: 5,
      // textTransform: 'uppercase',
      // textAlign: "center",
    },
    textareaStyle: {
      // background: "none",
      width: 400,
      fontSize: 22,
      // height: 70,
      paddingTop: 5,
    },

    titleQuestion: [
      {
        num: "3",
        title: `Find and complete the Key Phrases.`,
        color: "#00A850",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative; font-size: 23px; line-height: 50px; '>
        

        <div style='position: relative;'>
          <img style='height:14.3cm; width: 22cm;' src="img/FriendsPlus/Page65/E3/1.jpg"/>
          <div style='position: absolute; top: 179px; left: 30px'>
            <div style='display: flex'><div>Dear <sup><b>1</b></sup>&ensp;</div>#.</div>
            <div style='display: flex'><div>I am writing for <sup><b>2</b></sup>&ensp;</div>#.</div>
            <div style='display: flex'><div>I would be grateful if you could <sup><b>3</b></sup>&ensp;</div>#.</div>
            <div style='display: flex'><div>I look forward to <sup><b>4</b></sup>&ensp;</div>#.</div>
            <div style='display: flex'><div>Yours sincerely <sup><b>5</b></sup>&ensp;</div>#.</div>
          </div>
        </div>
        </div>
       
        `,
        answer: [
          "Ms Ngô Phương Lan",
          "further information about",
          "send me further information about",
          "hearing from you",
          "Lê Ngọc Điệp",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 6",
    id: "SB9-2024-U6-P65-E4",
    // audio: "Audios/2.06.mp3",
    exerciseKey: "img/FriendsPlus/Page65/Key/E4answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      width: 160,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "4",
        title: `Study the <span style='color: #5cc9dd'>blue</span> words in the letter. Then complete 
        the sentences with the words.`,
        color: "#00A850",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    giaiThua: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='display: flex; font-size: 22px; line-height: 50px; width: 27cm'>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br>
          <b>2</b>&ensp;<br>
          <b>3</b>&ensp;<br>
          <b>4</b>&ensp;<br>
          
        </div>

        <div style=''>
         <div>I join an online course # I want to upgrade my knowledge.</div>
         <div>Tourism management is an interesting field of study, # it is quite difficult.</div>
         <div>He studied hard # he could achieve his ambitions.</div>
         <div>I love meeting people, # it would be great 
         to work in tourism.</div>
        </div>
        
        `,
        answer: ["because", "but", "so that", "so"],
      },
    ],
  },
  5: {
    unit: "Unit 6",
    id: "SB9-2024-U6-P65-E5",
    // exerciseKey: "img/FriendsPlus/Page9/Key/E7answerKey.png",
    component: T6,
    //  maxLength: 30,
    // recorder: true,
    hideBtnFooter: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 800,
      fontSize: 22,
      // height: 120,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "5",
        title: `<img style='height:1.1cm; width: 4.8cm; margin-bottom: 5px' src='img/FriendsPlus/icon/6.jpg'/> 
        Follow the steps in the Writing Guide.`,
        color: "#00A850",
        left: 50,
        width: "26cm",
      },
    ],
    questions: [
      {
        title: `
        <img style="width:14cm; height:18.5cm; margin-left: 170px" src='img/FriendsPlus/Page65/E5/1.jpg' />
        <div style='margin-left: 50px'><textarea id='0' rows='8'></div>
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
