import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  5: {
    unit: "Unit 6",
    id: "SB9-2024-U6-P62-E5",
    // audio: "img/FriendsPlus/Page110/Audio/audio-e2-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "100%",
      // marginLeft: 150,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page62/E5/1.jpg" }]],
  },
  1: {
    unit: "Unit 6",
    id: "SB9-2024-U6-P62-E1",
    exerciseKey: "img/FriendsPlus/Page62/Key/E1answerKey.png",
    component: T6,
    // audio: "Audios/1.33.mp3",
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 24,
      height: 27,
      width: 250,
      // textTransform: 'uppercase',
      textAlign: "center",
      paddingTop: 5,
      fontWeight: 600,
    },
    hintBox: [
      {
        src: ["past", "present", "will"],
        width: 350,
        borderColor: "#5cc9dd",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Read the text about summer courses and check 
        the meaning of the words in <span style='color: #5cc9dd'>blue</span>. Then copy and 
        complete the table with the words.`,
        color: "#F78024",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img style="width:27cm; height:16cm; margin-left: -20px" src='img/FriendsPlus/Page62/E1/2.jpg' />
        <div style='position: relative; font-size: 22px'>
        <img style="width:21cm; height:16cm; margin-left: 30px" src='img/FriendsPlus/Page62/E1/1.jpg' />
        <div style='position: absolute; top: 73px; left: 63px'><sup><b>1</b></sup>#</div>
        <div style='position: absolute; top: 121px; left: 63px'><sup><b>2</b></sup>#</div>
        <div style='position: absolute; top: 174px; left: 418px'><sup><b>3</b></sup>#</div>

        <div style='position: absolute; top: 222px; left: 418px'><sup><b>4</b></sup>#</div>
        <div style='position: absolute; top: 270px; left: 63px'><sup><b>5</b></sup>#</div>
        <div style='position: absolute; top: 320px; left: 63px'><sup><b>6</b></sup>#</div>

        <div style='position: absolute; top: 370px; left: 418px'><sup><b>7</b></sup>#</div>
        <div style='position: absolute; top: 419px; left: 63px'><sup><b>8</b></sup>#</div>
        <div style='position: absolute; top: 466px; left: 63px'><sup><b>9</b></sup>#</div>
        <div style='position: absolute; top: 516px; left: 63px'><sup><b>10</b></sup>#</div>

        </div>
        `,
        // InputRong: true,
        answer: [
          "adventurous",
          "beneficial",
          "challenge",
          "confidence",
          "easy-going",
          "hard-working",
          "independence",
          "responsible",
          "rewarding",
          "sociable",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 6",
    id: "SB9-2024-U6-P62-E2",
    exerciseKey: "img/FriendsPlus/Page62/Key/E2answerKey.png",
    audio: "Audios/2.04.mp3",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "2",
        title: `<headphone name=2.04 src='Audios/2.04 tieu_de.mp3' color='black'  typeImg="sound2"></headphone> 
        Read the questions below. Then listen to the radio show. Choose the correct options.`,
        color: "#F78024",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: ["a <br> b <br> c", "a <br> b <br> c"],
        answers: ["0-8", "1-0"],
        initialValue: [],
      },
      Layout: `
  <div style='font-size: 23px; margin-left: 50px; margin-top: 20px; height: 500px'>
   
   <div style='width: 26cm; line-height: 45px'>
   <div><b>1</b>&ensp;The first two speakers are …</div>
   <div style='display: flex'>
      <div style='font-weight: 600; margin-left: 50px'><input id='0' type='Circle' /></div>  
      
      <div style='margin-left: 20px'>
        <div style="">two presenters.</div>
        <div style="">two experts.</div>
        <div style="">a presenter and an expert.</div>
       </div>
    </div>

    <div><b>2</b>&ensp;The people who call in are …</div>
   <div style='display: flex'>
      <div style='font-weight: 600; margin-left: 50px'><input id='1' type='Circle' /></div>  
      
      <div style='margin-left: 20px'>
        <div style="">teens asking for advice.</div>
        <div style="">teens talking about past experiences.</div>
        <div style="">parents requesting advice for their children.</div>
       </div>
    </div>
        
    </div>
      
      `,
    },
  },
  3: {
    unit: "Unit 5",
    id: "SB9-2024-U5-P62-E3",
    exerciseKey: "img/FriendsPlus/Page62/Key/E3answerKey.png",
    audio: "Audios/2.04.mp3",
    component: T6,
    // textAlign: "center",
    // maxLength:5,
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 22,
      // height: 70,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "3",
        title: `<headphone name=2.04 src='Audios/2.04 tieu_de.mp3' color='black'  typeImg="sound2"></headphone> 
        Listen again and answer the questions.`,
        color: "#F78024",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    // giaiThua: true,
    questions: [
      {
        title: `
        <div style='font-size: 22px; line-height: 30px; width: 26cm'>
          <div style='font-weight: 600'><b>1</b>&ensp;What type of student can benefit from a 
          character-building course?</div>
          <div><textarea id='0' rows='2'></div>

          <div style='font-weight: 600'><b>2</b>&ensp;What skills can these courses help to develop?</div>
          <div><textarea id='1' rows='2'></div>

          <div style='font-weight: 600'><b>3</b>&ensp;What is Andy worried about?</div>
          <div><textarea id='2' rows='2'></div>

          <div style='font-weight: 600'><b>4</b>&ensp;What other activities do you have to do on 
          the sailing course?</div>
          <div><textarea id='3' rows='2'></div>

          <div style='font-weight: 600'><b>5</b>&ensp;How does Claire describe herself?</div>
          <div><textarea id='4' rows='2'></div>

          <div style='font-weight: 600'><b>6</b>&ensp;What doesn’t she want to do?</div>
          <div><textarea id='5' rows='2'></div>

        </div>
       
        `,
        answer: [
          "all types | everyone",
          "teamwork, decision-making, responsibility | decision-making, responsibility, teamwork | responsibility, teamwork, decision-making",
          "He doesn't know much about sailing.|He does not know much about sailing.",
          "cleaning the toilets, helping prepare food in the kitchen, washing up | helping prepare food in the kitchen, washing up, cleaning the toilets | washing up, cleaning the toilets, helping prepare food in the kitchen",
          "a bit shy",
          "travel too far",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 5",
    id: "SB9-2024-U5-P62-E4",
    // exerciseKey: "img/FriendsPlus/Page62/Key/E3answerKey.png",
    component: T6,
    //  maxLength: 30,
    recorder: true,
    hideBtnFooter: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 22,
      // height: 120,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "4",
        title: `<img style='height:1.1cm; width: 3.8cm; margin-bottom: 5px' src='img/FriendsPlus/icon/9.jpg'/>
        Discuss the questions with a partner. Explain your answers.`,
        color: "#F78024",
        left: 50,
        width: "26cm",
      },
    ],
    questions: [
      {
        title: `
        <div style='margin-left: 130px' >
        <img style="width:16cm; height:8cm; margin-left: 30px" src='img/FriendsPlus/Page62/E4/1.jpg' /><br>
        </div>
        <div style='margin-left:0px; font-size: 22px; margin-top: 20px; line-height: 50px'>
        <div><div><b>1</b>&ensp;Which experience would be more rewarding for you?</div>
        <textarea id='0' rows='2'></div>
        <div><div><b>2</b>&ensp;What chores are you responsible for at home?</div>
        <textarea id='1' rows='2'></div>
        <div><div><b>3</b>&ensp;What’s the most adventurous thing you’ve ever done?</div>
        <textarea id='2' rows='2'></div>
        <div><div><b>4</b>&ensp;Do you like to try new challenges?</div>
        <textarea id='3' rows='2'></div>
        
      </div>
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
