import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  6: {
    unit: "Unit 3",
    id: "SB9-2024-U3-P32-E6",
    // audio: "img/FriendsPlus/Page110/Audio/audio-e2-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "100%",
      marginLeft: 0,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page32/E6/1.jpg" }]],
  },
  // 1: {
  //   unit: "Unit 3",
  //   id: "SB9-2024-U3-P32-E1",
  //   exerciseKey: "img/FriendsPlus/Page32/Key/E1answerKey.png",
  //   component: T6,
  //   audio: "Audios/1.19.mp3",
  //   // maxLength:4,
  //   // hideBtnFooter:true,
  //   recorder: true,
  //   textAlign: "center",
  //   stylesTextInput: {
  //     background: "none",
  //     borderBottom: "1px solid",
  //     fontSize: 22,
  //     height: 27,
  //     // textTransform: "uppercase",
  //     textAlign: "left",
  //     width: 800,
  //     paddingTop: 5,
  //   },
  //   textareaStyle: {
  //     // background: 'none',
  //     width: 800,
  //     paddingTop: 5,
  //     fontSize: 22,
  //     // height: 100,
  //   },
  //   selectStyle: {
  //     height: 35,
  //     width: 106,
  //     textAlign: "center",
  //     fontSize: 21,
  //   },
  //   selectOptionRandom: true,
  //   selectOptionValues: ["true", "false"],
  //   titleQuestion: [
  //     {
  //       num: "1",
  //       title: `<headphone name=1.19 src='Audios/1.19 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
  //       Read and listen to the text. Decide if you think a–d are true or false. Explain your answers.`,
  //       color: "#D92F3B",
  //       left: 50,
  //       width: "26cm",
  //     },
  //   ],
  //   // hideBtnFooter: true,
  //   questions: [
  //     {
  //       title: `

  //       <div style='position: relative; height: 800px; font-size: 22px'>
  //       <div style='display: flex; position: absolute; top: 15px; left: 38px; width: 25cm; line-height: 38px'>
  //         <div>
  //           <div>The person asking the questions …</div>
  //           <div style="display: flex"><b>a</b> &ensp;… knows something about this topic.&ensp; <select id=4></select></div>
  //           <div><textarea id='0' rows='2'></div>

  //           <div style="display: flex"><b>b</b> &ensp;… isn’t interested in making money online. &ensp;<select id=5></select></div>
  //           <div><textarea id='1' rows='2'/></div>

  //           <div>The person answering the questions …</div>

  //           <div style="display: flex"><b>c</b> &ensp;… thinks it’s easy to become an influencer. &ensp;<select id=6></select></div>
  //           <div><textarea id='2' rows='2'/></div>

  //           <div style="display: flex"><b>d</b> &ensp;… thinks that influencers can be effective. &ensp; <select id=7></select></div>
  //           <div><textarea id='3' rows='2'/></div>

  //         </div>
  //       </div>

  //       </div>
  //       `,
  //       InputRong: true,
  //       answer: [
  //         "(The interviewer asks a lot of questions about influencers and doesn't know anything about them.)",
  //         "(The interviewer wants to get more followers and become an influencer)",
  //         "(Harper says that you need a minimum number of followers and that success 'doesn't happen overnight'.)",
  //         "(Harper says that the marketing companies are prepared to give a lot of products to influencers, and this type of marketing is 'massive' now.)",
  //         "false",
  //         "false",
  //         "false",
  //         "true",
  //       ],
  //     },
  //   ],
  // },
  1: {
    unit: "Unit 3",
    id: "SB9-2024-U3-P32-E1",
    exerciseKey: "img/FriendsPlus/Page32/Key/E1answerKey.png",
    component: T6,
    audio: "Audios/1.19.mp3",
    // maxLength:4,
    // hideBtnFooter:true,
    recorder: true,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "1px solid",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "left",
      width: 800,
      paddingTop: 5,
    },
    textareaStyle: {
      // background: 'none',
      width: 800,
      paddingTop: 5,
      fontSize: 22,
      // height: 100,
    },
    selectStyle: {
      height: 35,
      width: 106,
      textAlign: "center",
      fontSize: 21,
    },
    selectOptionRandom: true,
    selectOptionValues: ["true", "false"],
    titleQuestion: [
      {
        num: "1",
        title: `<headphone name=1.19 src='Audios/1.19 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
        Read and listen to the text. Decide if you think a–d are true or false. Explain your answers.`,
        color: "#D92F3B",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img style="width:25cm; height:24cm" src='img/FriendsPlus/Page32/E1/1.jpg' />
        <div style='position: relative; height: 300px; font-size: 22px'>
        
        <div style='display: flex; position: absolute; top: 15px; left: 38px; width: 25cm; line-height: 38px'>
          <div>
            <div>The person asking the questions …</div>
            <div style="display: flex"><b>a</b> &ensp;… knows something about this topic.&ensp; <select id=0></select></div>
            
            <div style="display: flex"><b>b</b> &ensp;… isn’t interested in making money online. &ensp;<select id=1></select></div> 
         
            <div>The person answering the questions …</div>

            <div style="display: flex"><b>c</b> &ensp;… thinks it’s easy to become an influencer. &ensp;<select id=2></select></div>
           

            <div style="display: flex"><b>d</b> &ensp;… thinks that influencers can be effective. &ensp; <select id=3></select></div>

          </div>
        </div>
               
        </div>
        `,
        InputRong: true,
        answer: [
          // "(The interviewer asks a lot of questions about influencers and doesn't know anything about them.)",
          // "(The interviewer wants to get more followers and become an influencer)",
          // "(Harper says that you need a minimum number of followers and that success 'doesn't happen overnight'.)",
          // "(Harper says that the marketing companies are prepared to give a lot of products to influencers, and this type of marketing is 'massive' now.)",
          "false",
          "false",
          "false",
          "true",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 3",
    id: "SB9-2024-U3-P32-E2",
    exerciseKey: "img/FriendsPlus/Page32/Key/E2answerKey.png",
    // audio: "Audios/1.11.mp3",
    component: T6,
    textAlign: "left",
    // recorder: true,
    // maxLength: 1,
    textareaStyle: {
      // background: 'none',
      width: 850,
      paddingTop: 5,
      fontSize: 22,
      // height: 100,
    },
    stylesTextInput: {
      width: 750,
      paddingTop: 15,
      background: "none",
      borderBottom: "solid 1.5px",
      fontSize: 21,
      // fontWeight: 600,
    },

    titleQuestion: [
      {
        num: "2",
        title: `Read the text again and answer the questions.`,
        color: "#D92F3B",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    // isHello: true,
    // KeyMau: true,
    giaiThua: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='position: relative; font-size: 22px'>
        <div style='margin-left: 0px'>
        <img style="width:25cm; height:24cm" src='img/FriendsPlus/Page32/E1/1.jpg' />
        </div>
          
          <div style=' margin-top: 10px; margin-left: 50px '>
              <div>
                <div><b>1&ensp;</b>Do you follow any online bloggers, comedians 
                or tutorials? Which ones?</div>
                <textarea id='0' rows='1'>
              </div>
              <div>
                <div><b>2&ensp;</b>Do you think that marketing influences you? 
                Why / Why not?</div>
                <textarea id='1' rows='1'>
              </div>
              <div>
                <div><b>3&ensp;</b>How much do influencers pay for the 
                products which they promote?</div>
                <textarea id='2' rows='1'>
              </div>
              <div>
                <div><b>4&ensp;</b>Give two reasons why marketing 
                companies want to reach teenagers.</div>
                <textarea id='3' rows='1'>
              </div>

          </div>
        </div>
        `,
        answer: [
          "Music, clothes, make-up, skateboards, games",
          "They pay for every view or 'like' that the influencer gets.",
          "Nothing. The companies give them free products.",
          "Because they spend a lot of money and they stick with brands.",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 3",
    id: "SB9-2024-U3-P32-E3",
    exerciseKey: "img/FriendsPlus/Page32/Key/E3answerKey.png",
    component: T6,
    // audio: "Audios/1.19.mp3",
    // maxLength:4,
    // hideBtnFooter:true,
    // recorder: true,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "left",
      width: 250,
      paddingTop: 10,
    },
    textareaStyle: {
      // background: 'none',
      width: 800,
      paddingTop: 5,
      fontSize: 22,
      // height: 100,
    },
    titleQuestion: [
      {
        num: "3",
        title: `<span style='color: #D92F3B'><b>VOCABULARY PLUS</b></span>
        Complete the noun + noun combinations. 
        Find the missing words on pages 30–33. How do you say them in Vietnamese?`,
        color: "#D92F3B",
        left: 50,
        width: "26cm",
      },
    ],
    character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative; height: 300px; font-size: 22px'>
        
        <div style='display: flex; position: absolute; top: 15px; left: 38px; width: 25cm; line-height: 38px'>
          <div>
            <div style="display: flex"><b>1</b> &ensp;scorpion</div>
            <div style="display: flex"><b>2</b> &ensp;computer</div>
            <div style="display: flex"><b>3</b> &ensp;beauty</div>
            <div style="display: flex"><b>4</b> &ensp;luxury</div>
            <div style="display: flex"><b>5</b> &ensp;internet</div>
            <div style="display: flex"><b>6</b> &ensp;fashion</div>
            <div style="display: flex"><b>7</b> &ensp;music</div>

          </div>
          <div>
            <div style="display: flex">&ensp; # (page 30)</div>
            <div style="display: flex">&ensp; # (page 30)</div>
            <div style="display: flex">&ensp; # (page 32)</div>
            <div style="display: flex">&ensp; # (page 31)</div>
            <div style="display: flex">&ensp; # (page 32)</div>
            <div style="display: flex">&ensp; # (page 32)</div>
            <div style="display: flex">&ensp; # (page 33)</div>

          </div>
        </div>
               
        </div>
        `,
        InputRong: true,
        answer: [
          "venom",
          "printers",
          "channel",
          "brands",
          "celebrities",
          "blogger",
          "promoter / company",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 3",
    id: "SB9-2024-U3-P32-E4",
    exerciseKey: "img/FriendsPlus/Page32/Key/E4answerKey.png",
    audio: "",
    video: "",
    component: Circle_Write,
    inputSize: 500,
    // hideBtnFooter: true,
    stylesTextInput: { width: 100, paddingTop: 10 },

    titleQuestion: [
      {
        num: "4",
        title: `Choose the noun from a–c which does not make a 
        compound noun with words 1–6.`,
        color: "#D92F3B",
        // left: -30,
        width: "26cm",
      },
    ],

    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "0px 7px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 7px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
        ],
        answers: ["0-0", "1-8", "2-4", "3-0", "4-0", "5-8"],
        initialValue: [],
      },
      Layout: `

      <div style='position:relative; line-height:43.4px; margin-left: 100px'>
        
        <div style='position:relative; left: 130px'>
          <div style='display:flex;justify-content:space-around;width:16cm; height: 1.1cm'><input id='0' type='Circle'/></div>
          <div style='display:flex;justify-content:space-around;width:16cm; height: 1.2cm'><input id='1' type='Circle'/></div>
          <div style='display:flex;justify-content:space-around;width:16cm; height: 1.2cm'><input id='2' type='Circle'/></div>
          <div style='display:flex;justify-content:space-around;width:16cm; height: 1.1cm'><input id='3' type='Circle'/></div>
          <div style='display:flex;justify-content:space-around;width:16cm; height: 1.1cm'><input id='4' type='Circle'/></div>
          <div style='display:flex;justify-content:space-around;width:16cm; height: 1.1cm'><input id='5' type='Circle'/></div>
        </div>
        <div style='position:absolute;top:0px;left:0px;font-weight:bold;'>
          <div><b>1</b>&ensp;hip hop</div>
          <div><b>2</b>&ensp;shop</div>
          <div><b>3</b>&ensp;head</div>
          <div><b>4</b>&ensp;football</div>
          <div><b>5</b>&ensp;maths</div>
          <div><b>6</b>&ensp;film</div>
         
        </div>
        
        <div style='position:absolute;top:0px;left:205px'>
          <div>feet</div>
          <div>assistant</div>
          <div>ache</div>
          <div>win</div>
          <div>man</div>
          <div>star</div>
        </div>
        <div style='position:absolute;top:0px;left:460px'>
          <div>artist</div>
          <div>window</div>
          <div>child</div>
          <div>match</div>
          <div>teacher</div>
          <div>industry</div>
        </div>
        <div style='position:absolute;top:0px;left:720px'>
          <div>concert</div>
          <div>paper</div>
          <div>phones</div>
          <div>shirt</div>
          <div>exam</div>
          <div>TV</div>
        </div>
      </div>

      `,
    },
  },
  5: {
    unit: "Unit 3",
    id: "SB9-2024-U3-P32-E5",
    // exerciseKey: "img/FriendsPlus/Page32/Key/E4answerKey.png",
    component: T6,
    //  maxLength: 30,
    recorder: true,
    hideBtnFooter: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 22,
      // height: 120,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "5",
        title: `<img style='height:1.1cm; width: 4cm; margin-bottom: 5px' src='img/FriendsPlus/icon/1.jpg'/> 
        Work in pairs. Ask and answer the questions.`,
        color: "#D92F3B",
        left: 50,
        width: "26cm",
      },
    ],

    questions: [
      {
        title: `
    
        <div style=' margin-top: 10px; margin-left: 30px '>
              <div>
                <div><b>1&ensp;</b>Do you follow any online bloggers, comedians 
                or tutorials? Which ones?</div>
                <textarea id='0' rows='3'>
              </div>
              <div>
                <div><b>2&ensp;</b>Do you think that marketing influences you? 
                Why / Why not?</div>
                <textarea id='1' rows='3'>
              </div>

          </div>
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
