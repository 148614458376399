import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 6",
    id: "SB9-2024-U6-P61-E1",
    exerciseKey: "img/FriendsPlus/Page61/Key/E1answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 23,
      height: 27,
      // textTransform: "uppercase",
      width: 250,
      paddingTop: 5,
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "1",
        title: `Complete these sentences from pages 60–61 
        with the correct reflexive pronouns. What are the other reflexive pronouns?`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    giaiThua: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='display: flex; font-size: 23px; line-height: 50px; width: 27cm'>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br>
          <b>2</b>&ensp;<br>
          <b>3</b>&ensp;<br>
          <b>4</b>&ensp;<br>
          
        </div>

        <div style=''>
         <div>Sometimes people lose # before they find their way again.</div>
         <div>I’ll be #, so please respect me, too.</div>
         <div>At different times we see # in a different light.</div>
         <div>You # know you’ve got reasons to be proud.</div>
         
        </div>
        </div><br>
        <div><b>What are the other reflexive pronouns?</b></div>
        <div><i style='font-size:18px; color: rgb(245,54,92)'>*Write the reflexive pronouns and between words seperated by commas (,).</i></div>
        <div><input id=5 width='850px'/></div>
        `,
        answer: [
          "themselves",
          "myself",
          "ourselves",
          "yourself",
          // "himself, herself, itself, yourselves | herself, itself, yourselves, himself | itself, yourselves, himself, herself | yourselves, himself, herself, itself",
          "himself, herself, itself, yourselves",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 6",
    id: "SB9-2024-U6-P61-E2",
    // exerciseKey: "img/FriendsPlus/Page61/Key/E1answerKey.png",
    component: T6,
    // maxLength:4,
    hideBtnFooter: true,
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 22,
      // height: 70,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "2",
        title: `Read the rules. Which sentence in exercise 1 has an emphatic pronoun?`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img style="width:15.5cm; height:7cm" src='img/FriendsPlus/Page61/E2/1.jpg' />
        <img style="width:27cm; height:7cm" src='img/FriendsPlus/Page61/E2/2.png' />
        <div><textarea id='0' rows='4'></div>
        `,
        answer: [""],
      },
    ],
  },
  3: {
    unit: "Unit 6",
    id: "SB9-2024-U6-P61-E2",
    exerciseKey: "img/FriendsPlus/Page61/Key/E3answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      width: 160,
      paddingTop: 5,
    },
    selectStyle: {
      height: 35,
      width: 100,
      textAlign: "center",
      fontSize: 22,
    },
    selectOptionRandom: true,
    selectOptionValues: ["R", "E"],
    titleQuestion: [
      {
        num: "3",
        title: `Complete the sentences with the correct 
        pronouns. Then write <i>R</i> (reflexive) or <i>E</i> (emphatic).`,
        color: "#5cc9dd",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `

        <div style='position: relative; font-size: 22px'>
          <div>Oh dear. You’ve cut <span style='color: gray'>yourself</span> really badly. <span style='color: gray'>R</span></div>
          <div style="display: flex"><b>1</b> &ensp;My parents enjoyed # at the cinema. &ensp; <select id=8></select></div>
          
          <div style="display: flex"><b>2</b> &ensp;Katie told us # that she was leaving 
          school. &ensp; <select id=9></select></div>
          
          <div style="display: flex"><b>3</b> &ensp;My friends and I enjoy # most when 
          we’re hanging out in the park. &ensp; <select id=10></select></div>
          
          <div style="display: flex"><b>4</b> &ensp;Did he teach # how to play the 
          guitar? &ensp; <select id=11></select></div>
          
          
          <div style="display: flex"><b>5</b> &ensp;The poem # is pretty good, but I 
          don’t like poetry in general. &ensp; <select id=12></select></div>
          
          <div style="display: flex"><b>6</b> &ensp;I # am happiest when I’m reading a 
          book. &ensp; <select id=13></select></div>
          
          <div style="display: flex"><b>7</b> &ensp;How would you describe #? &ensp; <select id=14></select></div>

          <div style="display: flex"><b>8</b> &ensp;What activities do you like to do by #? &ensp; <select id=15></select></div>
          
        </div>
        `,
        InputRong: true,
        answer: [
          "themselves",
          "herself",
          "ourselves",
          "himself",
          "itself",
          "myself",
          "yourself",
          "yourself",
          "R",
          "E",
          "R",
          "R",
          "E",
          "E",
          "R",
          "R",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 6",
    id: "SB9-2024-U6-P61-E4",
    // exerciseKey: "img/FriendsPlus/Page32/Key/E4answerKey.png",
    component: T6,
    //  maxLength: 30,
    recorder: true,
    hideBtnFooter: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 22,
      // height: 120,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "4",
        title: `<img style='height:1.1cm; width: 4cm; margin-bottom: 5px' src='img/FriendsPlus/icon/3.jpg'/> 
        Work in pairs. Ask and answer questions 7 and 8 from exercise 3.`,
        color: "#5cc9dd",
        left: 50,
        width: "25cm",
      },
    ],

    questions: [
      {
        title: `
    
        <img style="width:21cm; height:4cm" src='img/FriendsPlus/Page61/E4/1.png' />
        
        `,
        answer: [""],
      },
    ],
  },
  5: {
    unit: "Unit 6",
    id: "SB9-2024-U6-P61-E5",
    // exerciseKey: "img/FriendsPlus/Page71/Key/E2answerKey.png",
    component: T6,
    //  maxLength: 25,
    hideBtnFooter: true,
    recorder: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 22,
      paddingTop: 5,
      // height: 200,
    },
    titleQuestion: [
      {
        num: "",
        title: `<div style='margin-bottom: 10px'><span style='color: #1C5161 ;font-weight: 900; border: 2px solid darkgray; border-radius: 15px; padding: 5px'>Finished?</span></div> 
        Answer the questions. <br>
        • How do you enjoy yourself?<br>
        • What clothes do you wear to 'be yourself'?<br>
        • What have you taught yourself to do?
        `,
        color: "#5cc9dd",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,

    questions: [
      {
        title: `
        
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
