import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 1",
    id: "SB9-2024-U1-P15-E1",
    exerciseKey: "img/FriendsPlus/Page15/Key/E1answerKey.png",
    component: T6,
    maxLength: 1,
    // hideBtnFooter:true,
    textAlign: "left",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      textTransform: "lowercase",
      textAlign: "center",
      width: 80,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "1",
        title: `Match rules 1–3 with the examples of tenses a–c. 
        Then find more examples of these tenses in the 
        text on pages 12–13.`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display: flex'>
          <div style=''>
          <img style="width:14cm; height:5.5cm" src='img/FriendsPlus/Page15/E1/1.jpg' /><br><br>
          <img style="width:15cm; height:7cm" src='img/FriendsPlus/Page15/E1/2.jpg' />
          </div>
          <div style='font-size: 22px; line-height: 45px; margin-top: 20px; margin-left: 100px'>
          <b>Answer:</b><br>
            <b>1</b>&ensp;- # <br>
            <b>2</b>&ensp;- # <br>
            <b>3</b>&ensp;- # <br>

          </div>
        </div>
        
        `,
        answer: ["b", "a", "c"],
      },
    ],
  },
  2: {
    unit: "Unit 1",
    id: "SB9-2024-U1-P15-E2",
    exerciseKey: "img/FriendsPlus/Page15/Key/E2answerKey.png",
    component: T6,
    //  //  maxLength: 25,
    // hideBtnFooter: true,
    textAlign: "left",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      // background: 'none',
      width: 800,
      fontSize: 22,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "2",
        title: `Look again at examples a–c in exercise 1. Answer 
        the questions.`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    questions: [
      {
        title: `
        <img style="width:14cm; height:5.5cm" src='img/FriendsPlus/Page15/E1/1.jpg' />
      <div style='margin-left:0px; font-size: 22px; margin-top: 20px'>
        <b>1</b>&ensp;Which word is a regular past simple form?<br>#<br>
        <b>2</b>&ensp;Which word is a past participle?<br>#<br>
        <b>3</b>&ensp;Which word is a past form of <i>be</i>?<br>#<br>
        <b>4</b>&ensp;How do we form the past continuous?<br>#<br>
        <b>5</b>&ensp;How do we form the past perfect?<br>#<br>
        
      </div>
        
        `,
        answer: [
          "opened",
          "destroyed",
          "were",
          "was / were + verb + -ing",
          "had + past participle",
        ],
      },
    ],
  },

  3: {
    unit: "Unit 1",
    id: "SB9-2024-U1-P15-E3",
    // audio: "Audios/3-07.mp3",
    exerciseKey: "img/FriendsPlus/Page15/Key/E3answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "3",
        title: `Read the story and choose the correct verb forms. 
            Explain your answers.`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "hadn't_had / wasn't_having ",
          "was_feeling / had_felt", //2
          "decided / was_deciding", //3
          "put / was_putting",
          "threw / had_thrown",
          "emptied / was_emptying",
          "gave / was_giving",
          "started / had_started",
          "had_written / was_writing",
          "married / had_married",
        ],
        answers: [
          "0-0",
          "1-0",
          "2-0",
          "3-0",
          "4-0",
          "5-4",
          "6-0",
          "7-0",
          "8-0",
          "9-0",
        ],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative; margin-left: 30px'>
       <img style="width:23cm; height:19.5cm" src='img/FriendsPlus/Page15/E3/1.jpg' />

       <div style='position: absolute; top: 104px; left: 77px; width: 20cm; line-height: 47px; font-size: 22px'>
       A long time ago in Sweden, there was a sailor called Ake Viking. 
       He worked on a boat, but because of his work, he didn’t meet many people. 
       He <sup><b>1&ensp;</b></sup><input id='0' type='Circle' /> having a girlfriend for a long 
       time. One day he <sup><b>2&ensp;</b></sup><input id='1' type='Circle' /> lonely and romantic, 
       so he <sup><b>3&ensp;</b></sup><input id='2' type='Circle' /> to write a 
       letter. He addressed it to ‘someone beautiful and far 
       away’, then he <sup><b>4&ensp;</b></sup><input id='3' type='Circle' /> the letter into a 
       bottle and he <sup><b>5&ensp;</b></sup><input id='4' type='Circle' /> it in the sea.<br>
       One day a fisherman in Italy <sup><b>6&ensp;</b></sup><input id='5' type='Circle' /> his net when he found the bottle 
       and the message. He <sup><b>7&ensp;</b></sup><input id='6' type='Circle' /> it to his 
       daughter Paolina, and she <sup><b>8&ensp;</b></sup><input id='7' type='Circle' />
       writing to Ake. Three years after Ake <sup><b>9&ensp;</b></sup><input id='8' type='Circle' /> the letter, he finally travelled to Sicily 
       and <sup><b>10&ensp;</b></sup><input id='9' type='Circle' /> his true love.
       </div>
    
       </div>
      
      `,
    },
  },
  4: {
    unit: "Unit 1",
    id: "SB9-2024-U1-P15-E4",
    exerciseKey: "img/FriendsPlus/Page15/Key/E4answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      width: 200,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "4",
        title: `Complete the sentences using the verbs in 
        brackets. Include a past simple form and a past 
        perfect form in each sentence.`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display: flex; font-size: 22px; line-height: 50px; width: 26cm'>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br>
          <b>2</b>&ensp;<br>
          <b>3</b>&ensp;<br>
          <b>4</b>&ensp;<br>
          <b>5</b>&ensp;<br>
          
        </div>

        <div style=''>
         <div>I # (remember) that I # (see) her somewhere before.</div>
         <div>We # (be) late because we # (forget) the tickets.</div>
         <div>We # (not meet) before I # (see) her at the party.</div>
         <div>After we # (have) breakfast, we # (go) for a walk.</div>
         <div>When we # (arrive) at the station, we were late and the train # (leave).</div>

        </div>
        
        `,
        answer: [
          "remembered",
          "had seen",
          "were",
          "had forgotten",
          "hadn't met|had not met",
          "saw",
          "had had",
          "went",
          "arrived",
          "had left",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 1",
    id: "SB9-2024-U1-P15-E5",
    exerciseKey: "img/FriendsPlus/Page15/Key/E5answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      width: 160,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "5",
        title: `<img style='height:1.1cm; width: 1.3cm; margin-bottom: 5px' src='img/FriendsPlus/icon/2.jpg'/> Complete the questions with the correct verb 
        forms. Then ask and answer the questions with a 
        partner.`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display: flex; font-size: 22px; line-height: 50px; width: 26cm'>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br>
          <b>2</b>&ensp;<br>
          <b>3</b>&ensp;<br>
          <b>4</b>&ensp;<br>
          <b>5</b>&ensp;<br>
          
        </div>

        <div style=''>
         <div>What # you doing at this time yesterday?</div>
         <div># you do anything interesting last weekend?</div>
         <div>When # you last # to the cinema?</div>
         <div># you studied much English before you # to this school?</div>
         <div>Who # talking to you before you came into this class?</div>

        </div>
        
        `,
        answer: ["were", "Did", "did", "go", "Had", "came", "was"],
      },
    ],
  },
  6: {
    unit: "Unit 1",
    id: "SB9-2024-U1-P15-E6",
    exerciseKey: "img/FriendsPlus/Page15/Key/E6answerKey.png",
    component: T6,
    // maxLength:4,
    hideBtnFooter: true,
    recorder: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      width: 150,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "6",
        title: `<img style='height:1.1cm; width: 3.3cm; margin-bottom: 5px' src='img/FriendsPlus/icon/3.jpg'/> Work in pairs. Invent a ‘cooperative 
        story’. Take turns to say sentences. Listen to your partner and continue their ideas.`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display: flex; font-size: 22px; line-height: 50px; width: 26cm'>
        <img style='height: 8cm;margin-top:20px' src='img/FriendsPlus/Page15/E6/1.jpg'/>
        </div>
        
        `,
        answer: [""],
      },
    ],
  },
  7: {
    unit: "Unit 1",
    id: "SB9-2024-U1-P13-E5",
    exerciseKey: "img/FriendsPlus/Page71/Key/E2answerKey.png",
    component: T6,
    //  maxLength: 25,
    hideBtnFooter: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 22,
      paddingTop: 5,
      // height: 200,
    },
    titleQuestion: [
      {
        num: "",
        title: `<div style='margin-bottom: 10px'><span style='color: #1C5161 ;font-weight: 900; border: 2px solid darkgray; border-radius: 15px; padding: 5px'>Finished?</span></div> 
        Think about a film with a happy or surprising ending. Write the story, using past narrative tenses. Read it to your classmates. Can they guess the film?`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,

    questions: [
      {
        title: `
        <div style='font-size: 22px; line-height: 45px; margin-top: 20px'>
          <textarea id='0' rows='8'>
        </div>
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
