import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 3",
    id: "SB9-2024-U3-P35-E1",
    // audio: "Audios/3-07.mp3",
    exerciseKey: "img/FriendsPlus/Page35/Key/E1answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "1",
        title: `Match a–d with examples 1–4.`,
        color: "#5cc9dd",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          //LEFT
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "330px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "54px",
              left: "330px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "85px",
              left: "330px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "119px",
              left: "330px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3

          //RIGHT
          {
            boxMatchStyle: {
              position: "absolute",
              top: "17px",
              left: "430px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "51px",
              left: "430px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "82px",
              left: "430px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "116px",
              left: "430px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3
        ],
        answers: ["0-4", "3-5", "2-6", "1-7"],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "different / the_same",
          "base / continuous", //1
          "use_to / used_to", //2
          "use_to / used_to ",
        ],
        answers: [],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative;'>
       <img style="width:24cm; height:4cm" src='img/FriendsPlus/Page35/E1/1.jpg' />
    
        <input id='0' type= 'boxMatch' />
			  <input id='1' type= 'boxMatch' />
			  <input id='2' type= 'boxMatch' />
			  <input id='3' type= 'boxMatch' />
        
        <input id='4' type= 'boxMatch' />
        <input id='5' type= 'boxMatch' />
        <input id='6' type= 'boxMatch' />
        <input id='7' type= 'boxMatch' />
				
       </div>
      
      `,
    },
  },
  2: {
    unit: "Unit 3",
    id: "SB9-2024-U3-P35-E2",
    exerciseKey: "img/FriendsPlus/Page35/Key/E2answerKey.png",
    component: T6,
    // audio: "Audios/2.12.mp3",
    maxLength: 1,
    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 24,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "center",
      width: 80,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "2",
        title: `Match the future forms from exercise 1 with rules 1–4.`,
        color: "#5cc9dd",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img style="width:13cm; height:4.5cm" src='img/FriendsPlus/Page35/E2/1.jpg' />
        <div style='position: relative;'>
        <img style="width:22.5cm; height:12.5cm" src='img/FriendsPlus/Page7/E2/1.jpg' />
        <div style='position: absolute; top: 110px; left: 38px; width: 21cm; line-height: 50px'>
          <div>
            <div style=""><b>1</b> &ensp;We use this form when we plan or intend to 
            do something in the future, but there isn’t an 
            exact date or time yet. #</div>

            <div style=""><b>2</b> &ensp;We use this form when we have organised 
            something and there is an exact date or time. # 
            </div> 
            
            <div style=""><b>3</b> &ensp;We use this form for things which have a fixed 
            timetable. #</div>

            <div style=""><b>4</b> &ensp;We use this when we make a prediction about 
            the future. #</div>

          </div>
        </div>
               
        </div>
        `,
        answer: ["a", "b", "c", "d"],
      },
    ],
  },
  3: {
    unit: "Unit 3",
    id: "SB9-2024-U3-P35-E3",
    exerciseKey: "img/FriendsPlus/Page35/Key/E3answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    // recorder: true,
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "3",
        title: `Choose the correct options to complete the 
        dialogue. Explain your answers.`,
        color: "#5cc9dd",
        // left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "I'm_meeting / I'll_meet",
          "will_you_have / are_you_having", //2
          "leaving / going_to_leave", //3
          "you'll_leave / you're_leaving",
          "going_to_buy / buying",
          "we'll_go / we're_going",
          "will_leave / leaves",
        ],
        answers: ["0-0", "1-4", "2-0", "3-0", "4-0", "5-0", "6-4"],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative; height: 600px'>
          <div style=' position: absolute; top: 15px; left: 14px; width: 27cm; line-height: 45px; font-size: 21.4px'>
          
          <div style='display: flex'>
            <div style='width: 1.8cm; font-weight: 600; margin-right: 10px'>Becky</div>
            <div style='width: 21.2cm'>Bye, Mum. See you later. <sup><b>1</b></sup><input id='0' type='Circle' /> Grace for lunch at 2.00 p.m.</div>
          </div>
          <div style='display: flex'>
            <div style='width: 1.8cm; font-weight: 600; margin-right: 10px'>Mum</div>
            <div style='width: 21.2cm'>Where <sup><b>2</b></sup><input id='1' type='Circle' />lunch?</div>
          </div>
          <div style='display: flex'>
            <div style='width: 1.8cm; font-weight: 600; margin-right: 10px'>Becky</div>
            <div style='width: 21.2cm'>At Richy’s café. Why?</div>
          </div>
          <div style='display: flex'>
            <div style='width: 1.8cm; font-weight: 600; margin-right: 10px'>Mum</div>
            <div style='width: 21.2cm'>Oh, because I’m <sup><b>3</b></sup><input id='2' type='Circle' /> for 
            my dentist’s appointment in half an hour.<br>
            I can drive you to town if you want.</div>
          </div>
          <div style='display: flex'>
            <div style='width: 1.8cm; font-weight: 600; margin-right: 10px'>Becky</div>
            <div style='width: 21.2cm'>Ah no, it’s OK, thanks, Mum. I’m sure 
            <sup><b>4</b></sup><input id='3' type='Circle' /> late, as always.</div>
          </div>
          <div style='display: flex'>
            <div style='width: 1.8cm; font-weight: 600; margin-right: 10px'>Mum</div>
            <div style='width: 21.2cm'>Hmm. Have you got any plans for the 
            afternoon? Are you <sup><b>5</b></sup><input id='4' type='Circle' /> anything?</div>
          </div>
          <div style='display: flex'>
            <div style='width: 1.8cm; font-weight: 600; margin-right: 10px'>Becky</div>
            <div style='width: 21.2cm'>Maybe <sup><b>6</b></sup><input id='5' type='Circle' /> window 
            shopping. Why not meet us later?</div>
          </div>
          <div style='display: flex'>
            <div style='width: 1.8cm; font-weight: 600; margin-right: 10px'>Mum</div>
            <div style='width: 21.2cm'>OK, great.</div>
          </div>
          <div style='display: flex'>
            <div style='width: 1.8cm; font-weight: 600; margin-right: 10px'>Becky</div>
            <div style='width: 21.2cm'>Right. I’m going – my bus <sup><b>7</b></sup><input id='6' type='Circle' /> in two minutes. Bye!</div>
          </div>
          
        </div>
          
        </div><br>
      
      `,
    },
  },
  4: {
    unit: "Unit 3",
    id: "SB9-2024-U3-P35-E4",
    exerciseKey: "img/FriendsPlus/Page35/Key/E4answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 23,
      height: 27,
      // textTransform: "uppercase",
      width: 250,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "4",
        title: `Complete the sentences using the correct form 
        of <i>will, be going to</i> or the present continuous.`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display: flex; font-size: 23px; line-height: 50px; width: 27cm'>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br>
          <b>2</b>&ensp;<br>
          <b>3</b>&ensp;<br>
          <b>4</b>&ensp;<br>
          <b>5</b>&ensp;<br>
          <b>6</b>&ensp;<br>
          
        </div>

        <div style=''>
         <div>I # (not buy) that. I’ve decided that it doesn’t suit me.</div>
         <div>I # (play) golf with Anna next Saturday. Would you like to come?</div>
         <div>I promise that I # (pay) you when I’ve got some money.</div>
         <div>Ask the shop assistant. He # (help) you.</div>
         <div>We’ve got great plans. Our company # (sell) fresh air.</div>
         <div>The president # (have) a staff 
         meeting next Monday. You can meet him after that day.</div>
        </div>
        </div>
        
        `,
        answer: [
          "'m not going to buy",
          "'m playing",
          "'ll pay",
          "'ll help",
          "is going to sell",
          "'s having",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 3",
    id: "SB9-2024-U3-P35-E5",
    // exerciseKey: "img/FriendsPlus/Page35/Key/E4answerKey.png",
    component: T6,
    //  maxLength: 30,
    recorder: true,
    hideBtnFooter: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 22,
      // height: 120,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "5",
        title: `<img style='height:1.1cm; width: 3.8cm; margin-bottom: 5px' src='img/FriendsPlus/icon/3.jpg'/>
        Work in pairs. Read the information in the leaflet and prepare answers 
        for 1–6. Then tell the class your plans and predictions.`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    questions: [
      {
        title: `
        <div style='margin-left: 130px; font-size: 22px' >
        <img style="width:15cm; height:17cm; margin-left: 30px" src='img/FriendsPlus/Page35/E5/1.jpg' /><br>
        
        </div>
        <div style=' margin-top: 10px; margin-left: 30px '>
              <div>
                <div><b>1&ensp;</b>When are you going?</div>
                <textarea id='0' rows='2'>
              </div>
              <div>
                <div><b>2&ensp;</b>How are you going to get there?</div>
                <textarea id='1' rows='2'>
              </div>
              <div>
                <div><b>3&ensp;</b>What time do the shops open and close there?</div>
                <textarea id='2' rows='2'>
              </div>
              <div>
                <div><b>4&ensp;</b>What kind of things are you going to buy?</div>
                <textarea id='3' rows='2'>
              </div>
              <div>
                <div><b>5&ensp;</b>Do you think that you’ll enjoy the experience?</div>
                <textarea id='4' rows='2'>
              </div>
              <div>
                <div><b>6&ensp;</b>What will be the best and worst things about the trip?</div>
                <textarea id='5' rows='2'>
              </div>

          </div>
        
        
        `,
        answer: [""],
      },
    ],
  },
  6: {
    unit: "Unit 3",
    id: "SB9-2024-U3-P35-E6",
    // exerciseKey: "img/FriendsPlus/Page71/Key/E2answerKey.png",
    component: T6,
    //  maxLength: 25,
    hideBtnFooter: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 22,
      paddingTop: 5,
      // height: 200,
    },
    titleQuestion: [
      {
        num: "",
        title: `<div style='margin-bottom: 10px'><span style='color: #1C5161 ;font-weight: 900; border: 2px solid darkgray; border-radius: 15px; padding: 5px'>Finished?</span></div> 
        Imagine you are going shopping. Write a paragraph about your plans and predictions 
        using as many different future forms as you can.`,
        color: "#5cc9dd",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,

    questions: [
      {
        title: `
        <div style='font-size: 22px; line-height: 45px; margin-top: 20px'>
          <textarea id='0' rows='10'>
        </div>
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
