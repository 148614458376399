import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import url from "../../configs/url";
const json = {
  2: {
    unit: "Unit 1",
    id: "SB9-2024-U1-P11-E2",
    exerciseKey: "img/FriendsPlus/Page11/Key/E2answerKey.png",
    component: T6,
    // maxLength:4,
    // textAlign:"center",
    stylesTextInput: { width: 900, paddingTop: 5, fontSize: 21 },
    titleQuestion: [
      {
        num: "2",
        title: `Do a memory test. Cover the texts and answer the questions.`,
        color: "#203c8f",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page11/E2/1.png" style='height:27cm; width: 29cm; margin-left: -80px'/>
          <div style='margin-left:0px; font-size: 21px; margin-top: 20px; line-height: 40px'>
            <b>1</b>&ensp;How many teddy bears were there at the 
            hotel?<br>#<br>
            <b>2</b>&ensp;Who took Nam to the festival?<br>#<br>
            <b>3</b>&ensp;Where did Erin find the photo?<br>#<br>
            <b>4</b>&ensp;What belonged to Dean's grandad?<br>#<br>
            <b>5</b>&ensp;Where did Ichika get her souvenir?<br>#<br>
            
          </div>
          
        
        `,
        answer: [
          "15",
          "his dad",
          "in a box of old photographs in her attic",
          "a watch",
          "in Việt Nam | in Viet Nam",
        ],
      },
    ],
  },

  3: {
    unit: "Unit 1",
    id: "SB9-2024-U1-P11-E5",
    video: url.cndUrl + "Videos/Unit 1.mp4 - Ex 3 ( trang 11).mp4",
    audio: "Audios/1.04.mp3",
    exerciseKey: "img/FriendsPlus/Page11/Key/E3answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "3",
        title: `<headphone name=1.04 src='Audios/1.04 tieu_de.mp3' color='black'  typeImg="sound2"></headphone> Choose the correct options in the
             questionnaire. Then watch or listen. Which questions do you hear?`,
        color: "#203c8f",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 4,
        listWords: [
          "keep / belong_to",
          "looking_back_on / finding",
          "looking_forward_to / forgetting",
          "recognise / remind",
          "remember / leave",
          "get_back / come_across",
          "1 / 2 / 3 / 4",
        ],
        answers: [
          "0-0",
          "1-0",
          "2-0",
          "3-4",
          "4-0",
          "5-4",
          "6-0",
          "6-8",
          "6-12",
        ],
        initialValue: [],
      },
      Layout: `
  <div style='position: relative; font-size: 23px; margin-left: 50px; margin-bottom: 100px'>
   <img style="width:19cm; height:16cm" src='img/FriendsPlus/Page11/E3/1.jpg' />
   <div style='display: flex; position: absolute; top: 110px; left: 50px; width: 16cm; line-height: 38px'>
     <div>
       <div style=""><b>1</b> &ensp;
       Do you <input id='0' type='Circle' /> souvenirs of places that you visit?
       </div>

       <div style=""><b>2</b> &ensp;
       Do you spend more time <input id='1' type='Circle' /> the past or <input id='2' type='Circle' /> the future?
       </div>

       <div style=""><b>3</b> &ensp;
       Do you frame photos to <input id='3' type='Circle' /> you of special events?
       </div>

       <div style=""><b>4</b> &ensp;
       What can you <input id='4' type='Circle' /> about your last birthday?
       </div>

       <div style=""><b>5</b> &ensp;
       If you're tidying your room and you <input id='5' type='Circle' /> old toys, games or books, do
       you spend a long time looking at them?
       </div>

       </div>
     </div>
        <div style='position: absolute; top: 610px; left: 50px; width: 16cm; line-height: 38px'>
        <div style='font-weight: 600'>Which questions do you hear?&ensp; <input id='6' type='Circle' /></div>
        
        </div>
     </div>
      
      `,
    },
  },

  4: {
    unit: "Unit 1",
    id: "SB9-2024-U1-P11-E4",
    exerciseKey: "img/FriendsPlus/Page11/Key/E4answerKey.png",
    component: T6,
    // recorder: true,
    // maxLength:4,
    // textAlign:"center",
    stylesTextInput: { width: 900, paddingTop: 5, fontSize: 22 },
    titleQuestion: [
      {
        num: "4",
        title: `<prefix></prefix> Work in pairs. Ask and answer the 
        questions from the questionnaire. Use the key 
        phrases to find out more information.`,
        color: "#203c8f",
        left: 50,
        width: "26cm",
      },
    ],
    hideBtnFooter: true,

    questions: [
      {
        title: `
        <img style="width:15.5cm; height:6cm" src='img/FriendsPlus/Page11/E4/1.jpg' />
          <div style='margin-left:0px; font-size: 22px; margin-top: 20px; width: 25cm'>
            <b>1</b>&ensp;Do you keep souvenirs of places 
            that you visit?<br>#<br>
            <b>2</b>&ensp;Do you spend more time looking back on the past or looking forward to the future?<br>#<br>
            <b>3</b>&ensp;Do you frame photos to remind
            you of special events?<br>#<br>
            <b>4</b>&ensp;What can you remember about your 
            last birthday?<br>#<br>
            <b>5</b>&ensp;If you're tidying your room and you
            come across old toys, games or books, do 
            you spend a long time looking at them?<br>#<br>
            
          </div>
          
        
        `,
        answer: ["", "", "", "", ""],
      },
    ],
  },
  5: {
    unit: "Unit 1",
    id: "SB9-2024-U1-P11-E5",
    exerciseKey: "img/FriendsPlus/Page71/Key/E2answerKey.png",
    component: T6,
    // maxLength: 25,
    hideBtnFooter: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 800,
      fontSize: 22,
      paddingTop: 5,
      // height: 200,
    },
    titleQuestion: [
      {
        num: "",
        title: `<div style='margin-bottom: 10px'><span style='color: #1C5161 ;font-weight: 900; border: 2px solid darkgray; border-radius: 15px; padding: 5px'>Finished?</span></div> Write your own internet post about something 
        you lost or found. What happened? How did you feel?`,
        color: "#203c8f",
        left: 50,
        width: "25cm",
      },
    ],

    questions: [
      {
        title: `
        <div style='font-size: 22px; line-height: 45px; margin-top: 20px'>
          <textarea id='0' rows='8'>
        </div>
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
