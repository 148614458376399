import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  5: {
    unit: "Unit 3",
    id: "SB9-2024-U3-P37-E5",
    // audio: "img/FriendsPlus/Page110/Audio/audio-e2-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "100%",
      // marginLeft: 150,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page37/E5/1.jpg" }]],
  },
  1: {
    unit: "Unit 3",
    id: "SB9-2024-U3-P37-E1",
    // audio: "Audios/1.10.mp3",
    exerciseKey: "img/FriendsPlus/Page37/Key/E1answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "1",
        title: `Read Nam Phương's letter then label each section with the appropriate heading.`,
        color: "#00A850",
        // left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          //LEFT
          {
            boxMatchStyle: {
              position: "absolute",
              top: "70px",
              left: "707px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "161px",
              left: "707px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "240px",
              left: "707px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "500px",
              left: "707px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "787px",
              left: "707px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "893px",
              left: "707px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "986px",
              left: "707px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0

          //RIGHT
          {
            boxMatchStyle: {
              position: "absolute",
              top: "70px",
              left: "842px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "217px",
              left: "842px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "363px",
              left: "842px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "508px",
              left: "842px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "653px",
              left: "842px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
        ],
        answers: ["0-7", "1-8", "2-9", "10-3", "10-4", "10-5", "11-6"],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "different / the_same",
          "base / continuous", //1
          "use_to / used_to", //2
          "use_to / used_to ",
        ],
        answers: [],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative; display: flex; width: 28cm; font-size: 20px'>
      <div>
        <div style='width: 18cm; border-right: 2px solid gray; margin: 10px 0px; padding: 10px; display: flex; align-items: flex-end; flex-direction: column'>
          <div>99/8A Lê Lợi Street</div>
          <div>Hồ Chí Minh City</div>
          <div>11<sup>th</sup> September, 20…</div>
        </div>
        <div style='width: 18cm; border-right: 2px solid gray; margin: 10px 0px; padding: 10px; '>
          Dear Tony,<br>
        </div>
        <div style='width: 18cm; border-right: 2px solid gray; margin: 10px 0px; padding: 10px; '>
          It's been a while since we last talked. How are you doing at school? Hope you are having an awesome term.<br>
        </div>
        <div style='width: 18cm; border-right: 2px solid gray; margin: 10px 0px; padding: 10px; '>
          Today, I'm glad to share my future career plans with you. I hope that you will support me as well as give me 
          some pieces of advice. I've always been interested in the healthcare industry and helping people in need. After 
          much thought and consideration, I'd like to pursue a career in nursing in the future. I believe nursing is a both
          rewarding and challenging career that will allow me to make positive impacts on people's lives. I'm thrilled 
          about the prospect of working in a dynamic environment where I'll have the chance to develop every day. I love 
          not only meeting new people but also building lasting relationships with patients and colleagues. To achieve 
          my career goals, I'll enrol in a nursing programme at a college. I'm confident that with the right training and 
          experience, I'll be able to make a meaningful difference in the lives of those I care for. <br>
        </div>
        <div style='width: 18cm; border-right: 2px solid gray; margin: 10px 0px; padding: 10px; '>
          I'd love to hear about your future career plans too. What are you interested in pursuing? Have you had any plans 
          for your future career yet?<br>
        </div>
        <div style='width: 18cm; border-right: 2px solid gray; margin: 10px 0px; padding: 10px; '>
          Thank you for being such a great pen pal of mine.<br>
          I look forward to hearing from you soon.<br>
        </div>
        <div style='width: 18cm; border-right: 2px solid gray; margin: 10px 0px; padding: 10px; '>
          Stay well,<br>
          Nam Phương<br>
        </div>
      </div>
      <div style='margin-left: 190px; line-height: 145px'>
        <div><b>a</b>&ensp;Address and date</div>
        <div><b>b</b>&ensp;Salutation</div>
        <div><b>c</b>&ensp;Pleasantries</div>
        <div><b>d</b>&ensp;Body</div>
        <div><b>e</b>&ensp;Closing</div>
      </div>
      
        <input id='0' type= 'boxMatch' />
        <input id='1' type= 'boxMatch' />
        <input id='2' type= 'boxMatch' />
        <input id='3' type= 'boxMatch' />
        <input id='4' type= 'boxMatch' />
        <input id='5' type= 'boxMatch' />
        <input id='6' type= 'boxMatch' />
			  
        <input id='7' type= 'boxMatch' />
        <input id='8' type= 'boxMatch' />
        <input id='9' type= 'boxMatch' />
        <input id='10' type= 'boxMatch' />
        <input id='11' type= 'boxMatch' />
				
       </div>
      
      `,
    },
  },
  2: {
    unit: "Unit 3",
    id: "SB9-2024-U3-P37-E2",
    // audio: "Audios/1.09.mp3",
    exerciseKey: "img/FriendsPlus/Page37/Key/E2answerKey.png",
    component: T6,
    // textAlign: "center",
    // maxLength:5,
    stylesTextInput: {
      background: "none",
      borderBottom: "1px solid",
      fontSize: 20,
      height: 27,
      width: 930,
      paddingTop: 5,
      // textTransform: 'uppercase',
      // textAlign: "center",
    },
    textareaStyle: {
      // background: "none",
      width: 700,
      fontSize: 20,
      // height: 70,
      paddingTop: 5,
    },

    titleQuestion: [
      {
        num: "2",
        title: `Complete the Key Phrases with words from the text.`,
        color: "#00A850",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative; font-size: 20px; line-height: 35px; '>
        <div>
        <div style='width: 25cm; display: flex; align-items: flex-end; flex-direction: column'>
          <div>99/8A Lê Lợi Street</div>
          <div>Hồ Chí Minh City</div>
          <div>11<sup>th</sup> September, 20…</div>
        </div>
        <div style='width: 25cm; '>
          Dear Tony,<br>
        </div>
        <div style='width: 25cm; '>
          It's been a while since we last talked. How are you doing at school? Hope you are having an awesome term.<br>
        </div>
        <div style='width: 25cm; '>
          Today, I'm glad to share my future career plans with you. I hope that you will support me <span style='color: rgb(54,166,215)'>as well as</span> give me 
          some pieces of advice. I've always been interested in the healthcare industry and helping people in need. After 
          much thought and consideration, I'd like to pursue a career in nursing in the future. I believe nursing is a <span style='color: rgb(54,166,215)'>both</span>
          rewarding <span style='color: rgb(54,166,215)'>and</span> challenging career that will allow me to make positive impacts on people's lives. I'm thrilled 
          about the prospect of working in a dynamic environment where I'll have the chance to develop every day. I love 
          <span style='color: rgb(54,166,215)'>not only</span> meeting new people <span style='color: rgb(54,166,215)'>but also</span> building lasting relationships with patients and colleagues. To achieve 
          my career goals, I'll enrol in a nursing programme at a college. I'm confident that with the right training and 
          experience, I'll be able to make a meaningful difference in the lives of those I care for. <br>
        </div>
        <div style='width: 25cm; '>
          I'd love to hear about your future career plans too. What are you interested in pursuing? Have you had any plans 
          for your future career yet?<br>
        </div>
        <div style='width: 25cm; '>
          Thank you for being such a great pen pal of mine.<br>
          I look forward to hearing from you soon.<br>
        </div>
        <div style='width: 25cm; '>
          Stay well,<br>
          Nam Phương<br>
        </div>
      </div>
        <div style='position: relative; font-size: 20px; line-height: 35px'>
          <img style='height:20cm; width: 25cm;' src="img/FriendsPlus/Page37/E2/1.jpg"/>
          <div style='position: absolute; top: 120px; left: 30px'>
            <div style='display: flex'><div>I'm glad to <sup><b>1</b></sup>&ensp;</div><textarea id='0' rows='1'>.</div>
            <div style='display: flex'><div>I believe <sup><b>2</b></sup>&ensp;</div><textarea id='1' rows='1'>.</div>
            <div style='display: flex'><div>I'm thrilled about <sup><b>3</b></sup>&ensp;</div><textarea id='2' rows='2'>.</div>
            <div style='display: flex'><div>To achieve <sup><b>4</b></sup>&ensp;</div><textarea id='3' rows='2'>.</div>
            <div style='display: flex'><div>I'm confident that <sup><b>5</b></sup>&ensp;</div><textarea id='4' rows='2'>.</div>
            <div style='display: flex'><div>I'd love to <sup><b>6</b></sup>&ensp;</div><textarea id='5' rows='2'>.</div>
          </div>
        </div>
        </div>
       
        `,
        answer: [
          "share my future career plans with you",
          "nursing is a both rewarding and challenging career",
          "the prospect of working in a dynamic environment",
          "my career goals, I'll enrol in a nursing programme at a college|my career goals, I will enrol in a nursing programme at a college",
          "with the right training and experience, I'll be able to make a meaningful difference in the lives of those I care for.|with the right training and experience, I will be able to make a meaningful difference in the lives of those I care for.",
          "hear about your career plans too",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 3",
    id: "SB9-2024-U3-P37-E3",
    // audio: "Audios/1.09.mp3",
    exerciseKey: "img/FriendsPlus/Page37/Key/E3answerKey.png",
    component: T6,
    // textAlign: "center",
    // maxLength:5,
    stylesTextInput: {
      background: "none",
      borderBottom: "1px solid",
      fontSize: 22,
      height: 27,
      width: 880,
      paddingTop: 5,
      // textTransform: 'uppercase',
      // textAlign: "center",
    },
    textareaStyle: {
      // background: "none",
      width: 900,
      fontSize: 22,
      // height: 70,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "3",
        title: `Study the conjunctions in <span style='color: rgb(54,166,215)'>blue</span> in Nam Phương's 
        letter. Then complete the sentences using the 
        conjunctions.`,
        color: "#00A850",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='font-size: 22px; line-height: 35px'>
        <div>
        <div style='width: 25cm; display: flex; align-items: flex-end; flex-direction: column'>
          <div>99/8A Lê Lợi Street</div>
          <div>Hồ Chí Minh City</div>
          <div>11<sup>th</sup> September, 20…</div>
        </div>
        <div style='width: 25cm; '>
          Dear Tony,<br>
        </div>
        <div style='width: 25cm; '>
          It's been a while since we last talked. How are you doing at school? Hope you are having an awesome term.<br>
        </div>
        <div style='width: 25cm; '>
          Today, I'm glad to share my future career plans with you. I hope that you will support me <span style='color: rgb(54,166,215)'>as well as</span> give me 
          some pieces of advice. I've always been interested in the healthcare industry and helping people in need. After 
          much thought and consideration, I'd like to pursue a career in nursing in the future. I believe nursing is a <span style='color: rgb(54,166,215)'>both</span>
          rewarding <span style='color: rgb(54,166,215)'>and</span> challenging career that will allow me to make positive impacts on people's lives. I'm thrilled 
          about the prospect of working in a dynamic environment where I'll have the chance to develop every day. I love 
          <span style='color: rgb(54,166,215)'>not only</span> meeting new people <span style='color: rgb(54,166,215)'>but also</span> building lasting relationships with patients and colleagues. To achieve 
          my career goals, I'll enrol in a nursing programme at a college. I'm confident that with the right training and 
          experience, I'll be able to make a meaningful difference in the lives of those I care for. <br>
        </div>
        <div style='width: 25cm; '>
          I'd love to hear about your future career plans too. What are you interested in pursuing? Have you had any plans 
          for your future career yet?<br>
        </div>
        <div style='width: 25cm; '>
          Thank you for being such a great pen pal of mine.<br>
          I look forward to hearing from you soon.<br>
        </div>
        <div style='width: 25cm; '>
          Stay well,<br>
          Nam Phương<br>
        </div>
      </div><br>
      <div>
          <div style='font-weight: 500'><b>1</b>&ensp; Hannah gives me useful advice. She also helps me make reports quickly. (as well as)</div>
          <div><textarea id='0' rows='2'></div>
          <div style='font-weight: 500'><b>2</b>&ensp; Tuấn is a skilful football player. So is Minh. (Both … and)</div>
          <div><textarea id='1' rows='2'></div>
          <div style='font-weight: 500'><b>3</b>&ensp; Doing exercise is good for your physical 
          health and mental health, too. (not only … but also)</div>
          <div><textarea id='2' rows='2'></div>
      </div>
        </div>
       
        `,
        answer: [
          "Hannah gives me useful advice as well as helps me make reports quickly.",
          "Both Tuấn and Minh are skilful football players.",
          "Doing exercise is not only good for your physical health but also mental health.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 3",
    id: "SB9-2024-U3-P37-E4",
    // exerciseKey: "img/FriendsPlus/Page9/Key/E7answerKey.png",
    component: T6,
    //  maxLength: 30,
    // recorder: true,
    hideBtnFooter: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 22,
      // height: 120,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "4",
        title: `<img style='height:1.1cm; width: 4.8cm; margin-bottom: 5px' src='img/FriendsPlus/icon/6.jpg'/> 
        Follow the steps in the Writing Guide.`,
        color: "#00A850",
        left: 50,
        width: "26cm",
      },
    ],
    questions: [
      {
        title: `
        <img style="width:15cm; height:20cm; margin-left: 170px" src='img/FriendsPlus/Page37/E4/1.jpg' />
        <div style='margin-left: 30px'><textarea id='0' rows='8'></div>
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
