import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import url from "../../configs/url";

const json = {
  6: {
    unit: "Unit 3",
    id: "SB9-2024-U3-P36-E6",
    // audio: "img/FriendsPlus/Page110/Audio/audio-e2-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "100%",
      marginBottom: 150,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page36/E6/1.jpg" }]],
  },
  1: {
    unit: "Unit 3",
    id: "SB9-2024-U3-P36-E1",
    audio: "Audios/1.22.mp3",
    video: url.cndUrl + "Videos/Unit 3.mp4 - Ex 1,3 (trang 36).mp4",
    exerciseKey: "img/FriendsPlus/Page36/Key/E1answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "1",
        title: `<headphone name=1.22 src='Audios/1.22 tieu_de.mp3' color='black'  typeImg="sound2"></headphone> 
        Watch or listen to Joelle presenting a 'new' product. What features 
        from a–j does she say that a book has?`,
        color: "#DC1E74",
        left: 100,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 5,
        listWords: [
          "a <br> b <br> c <br> d <br> e",
          "f <br> g <br> h <br> i <br> j",
        ],
        answers: ["0-0", "0-12", "1-8", "1-12"],
        initialValue: [],
      },
      Layout: `
  <div style='font-size: 23px; margin-left: 140px; margin-top: 20px;'>
   
   <div style='display: flex; width: 26cm; line-height: 45px'>
   <div style='width: 13cm; display: flex'>
      <div style='font-weight: 600; margin-left: 50px'><input id='0' type='Circle' /></div>  
      
      <div style='margin-left: 20px'>
        <div style="">light and portable</div>
        <div style="">electronic</div>
        <div style="">versatile</div>
        <div style="">tough</div>
        <div>fragile</div>
       </div>
    </div>
      
    <div style='width: 13cm; display: flex'>
    <div style='font-weight: 600; margin-left: 50px'><input id='1' type='Circle' /></div>  
       <div style='margin-left: 20px'>
       <div style="">convenient</div>
       <div style="">luxury brand</div>
       <div style="">easy to share</div>
       <div style="">good value</div>
       <div>special offer</div>
        </div>
     </div>
        
    </div>
      
      `,
    },
  },
  2: {
    unit: "Unit 3",
    id: "SB9-2024-U3-P36-E2",
    // audio: "Audios/1.22.mp3",
    exerciseKey: "img/FriendsPlus/Page36/Key/E2answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "2",
        title: `Study the Key Phrases. Which phrases introduce new points?`,
        color: "#DC1E74",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 4px",
          // border: "2px solid",
          borderColor: "transparent",
          // fontWeight: "bold",
        },
        selectWordStyle: {
          // fontWeight: "bold",
          // padding: "1px 4px",
          // borderRadius: "50%",
          color: "black",
          borderBottom: "2px solid #00aeef",
          borderColor: "#00aeef",
          fontWeight: 600,
        },
        limitSelect: 8,
        listWords: [
          "I'd_like_to_talk_to_you_about_… <br> Let_me_start_by_showing_you_<sup>1</sup>.......................... <br> First_of_all,_it's_<sup>2</sup>.......................... <br> As_you_can_see,_<sup>3</sup>.......................... <br> Another_advantage_is_that_<sup>4</sup>.......................... <br> It's_also_worth_mentioning_that_<sup>5</sup>.......................... <br> Finally,_one_big_advantage_of_<sup>6</sup>.......................... <br> I_think_you'll_agree_that_<sup>7</sup>..........................",
        ],
        answers: ["0-8", "0-16", "0-20", "0-24"],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative; width: 25cm; font-size: 22px; line-height: 37px; margin-left: 100px'>
      <img style="width:19cm; height:11.5cm" src='img/FriendsPlus/Page36/E2/1.jpg' />
        <div style='position: absolute; top: 111px; left: 32px'>
           <input id='0' type='Circle' />
        </div>
      </div>
      
      `,
    },
  },
  3: {
    unit: "Unit 3",
    id: "SB9-2024-U3-P36-E3",
    audio: "Audios/1.22.mp3",
    video: url.cndUrl + "Videos/Unit 3.mp4 - Ex 1,3 (trang 36).mp4",
    exerciseKey: "img/FriendsPlus/Page36/Key/E3answerKey.png",
    component: T6,
    // maxLength:4,
    // textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      // textAlign: "center",
      width: 550,
      paddingTop: 5,
      fontWeight: 600,
    },
    titleQuestion: [
      {
        num: "3",
        title: `<headphone name=1.22 src='Audios/1.22 tieu_de.mp3' color='black'  typeImg="sound2"></headphone> 
        Watch or listen again and complete 1–7 in the Key Phrases.`,
        color: "#DC1E74",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative; font-size: 22px'>
        <img style="width:25.5cm; height:12.5cm" src='img/FriendsPlus/Page36/E3/1.jpg' />
        <div style='display: flex; position: absolute; top: 109px; left: 27px; line-height: 34px'>
          <div>
            <div>I'd like to talk to you about …</div>
            <div>Let me start by showing you <sup><b>1</b></sup>&ensp;# .</div>
            <div>First of all, it’s <sup><b>2</b></sup>&ensp;# .</div>
            <div>As you can see, <sup><b>3</b></sup>&ensp;# .</div>
            <div>Another advantage is that <sup><b>4</b></sup>&ensp;# .</div>
            <div>It’s also worth mentioning that <sup><b>5</b></sup>&ensp;# .</div>
            <div>Finally, one big advantage of <sup><b>6</b></sup>&ensp;# .</div>
            <div>I think you’ll agree that <sup><b>7</b></sup>&ensp;# .</div>
          </div>
        </div>
               
        </div>
        `,
        answer: [
          "some great features of the book",
          "light and portable",
          "I can carry it easily with one hand",
          "books don't require any electricity or a charger",
          "books are very easy to share with friends",
          "books is that they're cheap - or even free",
          "it's a great product",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 3",
    id: "SB9-2024-U3-P36-E4",
    audio: "Audios/1.23.mp3",
    exerciseKey: "img/FriendsPlus/Page36/Key/E4answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "4",
        title: `<headphone name=1.23 src='Audios/1.23 tieu_de.mp3' color='black'  typeImg="sound2"></headphone> 
        When we want to keep a listener’s attention, it’s a good idea to vary the speed and emphasis of what we’re saying. Listen 
        and repeat phrases 1–5. Which words does the speaker make longer?`,
        color: "#DC1E74",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 4px",
          // border: "2px solid",
          borderColor: "transparent",
          // fontWeight: "bold",
        },
        selectWordStyle: {
          // fontWeight: "bold",
          // padding: "1px 4px",
          // borderRadius: "50%",
          color: "black",
          borderBottom: "2px solid #00aeef",
          borderColor: "#00aeef",
          fontWeight: 600,
        },
        limitSelect: 10,
        listWords: [
          "I think you're really going to like it",
          "I can carry it easily with one_hand",
          "Some other products, in contrast, are a bit more fragile",
          "You can use them anywhere",
          "Now that's what I call good_value",
        ],
        answers: [
          "0-6",
          "0-12",
          "1-8",
          "1-12",
          "2-2",
          "2-14",
          "2-18",
          "3-8",
          "4-2",
          "4-10",
        ],
        initialValue: [],
      },
      Layout: `
      <div style='display: flex; width: 25cm; font-size: 23px; line-height: 50px; margin-left: 100px'>
        <div>
          <div><b>1</b></div>
          <div><b>2</b></div>
          <div><b>3</b></div>
          <div><b>4</b></div>
          <div><b>5</b></div>
        </div>
        <div style='margin-left: 15px'>
           <input id='0' type='Circle' />.<br>
           <input id='1' type='Circle' />.<br>
           <input id='2' type='Circle' />.<br>
           <input id='3' type='Circle' />.<br>
           <input id='4' type='Circle' />.<br>
        </div>
      </div>
      
      `,
    },
  },
  5: {
    unit: "Unit 3",
    id: "SB9-2024-U3-P16-E5",
    exerciseKey: "img/FriendsPlus/Page36/Key/E5answerKey.png",
    component: T6,
    //  maxLength: 30,
    recorder: true,
    hideBtnFooter: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 800,
      fontSize: 22,
      height: 120,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "5",
        title: `<img style='height:1.2cm; width: 3.8cm; margin-bottom: 5px' src='img/FriendsPlus/icon/5.jpg'/>
        Follow the steps in the Speaking Guide.`,
        color: "#DC1E74",
        left: 50,
        width: "26cm",
      },
    ],
    questions: [
      {
        title: `
        <img style="width:15cm; height:16.5cm; margin-left: 170px" src='img/FriendsPlus/Page36/E5/1.jpg' />
        
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
