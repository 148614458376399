import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 6",
    id: "SB9-2024-U6-P63-E1",
    // audio: "Audios/3-07.mp3",
    exerciseKey: "img/FriendsPlus/Page63/Key/E1answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "1",
        title: `Study sentences a–f. Then choose the correct 
        options to complete the rules.`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "places / people",
          "people / things", //2
          "people / possessions",
          "places / things",
          "beginning / end",
        ],
        answers: ["0-4", "1-4", "2-4", "3-0", "4-4"],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative;'>
      <img style="width:16cm; height:8.1cm; margin-left: 100px" src='img/FriendsPlus/Page63/E1/1.jpg' />
       <img style="width:19cm; height:10cm; margin-left: 100px" src='img/FriendsPlus/Page43/E1/2.jpg' />
       <div style='display: flex; position: absolute; top: 389px; left: 145px; width: 17cm; line-height: 39px; font-size: 23px'>
         <div>
           <div style=""><b>1</b> &ensp;We can use the relative pronoun who or that
           when we refer to <input id='0' type='Circle' />. 
           </div>
    
           <div style=""><b>2</b> &ensp;We can use which or that for <input id='1' type='Circle' />.
           </div>

           <div style=""><b>3</b> &ensp;We can use whose for <input id='2' type='Circle' />.
           </div>

           <div style=""><b>4</b> &ensp;We can use where for <input id='3' type='Circle' />.
           </div>

           <div style=""><b>5</b> &ensp;We usually put prepositions at the <input id='4' type='Circle' /> of a relative clause.
           </div>
    
         </div>
       </div>
    
       </div>
      
      `,
    },
  },
  2: {
    unit: "Unit 6",
    id: "SB9-2024-U6-P63-E2",
    // audio: "Audios/3-07.mp3",
    exerciseKey: "img/FriendsPlus/Page63/Key/E2answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      width: 160,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "2",
        title: `Complete the sentences with <i>who, whose, where</i> 
        or <i>which</i>. Which pronouns can be replaced with <i>that</i>?`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    giaiThua: true,
    character: ",",
    questions: [
      {
        title: `
        <div style='display: flex; font-size: 22px; line-height: 50px; width: 27cm'>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br>
          <b>2</b>&ensp;<br>
          <b>3</b>&ensp;<br>
          <b>4</b>&ensp;<br>
          <b>5</b>&ensp;<br>
          
        </div>

        <div style=''>
         <div>I don’t know # bag this is.</div>
         <div>Alaska is a place # you can see wild bears.</div>
         <div>Is there anyone here # can speak Japanese?</div>
         <div>Tom is the man # lives next door.</div>
         <div>Sailing is something # I’d like to try.</div>
        </div>
        </div><br>
        <div style='font-size: 22px; line-height: 50px;'><b>Which pronouns can be replaced with <i>that</i>?</b></div>
        <div><i style='font-size:18px; color: rgb(245,54,92)'>*Write the pronouns and between words seperated by commas (,).</i></div>
        <div style='font-size: 22px; line-height: 50px;'>The pronouns which can be replaced 
        with that:<input id=5 width='350px'/></div>
        
        `,
        answer: ["whose", "where", "who", "who", "which", "who, which"],
      },
    ],
  },
  3: {
    unit: "Unit 6",
    id: "SB9-2024-U6-P63-E3",
    // audio: "Audios/3-07.mp3",
    // exerciseKey: "img/FriendsPlus/Page63/Key/E2answerKey.png",
    component: T6,
    // maxLength:4,
    hideBtnFooter: true,
    textAlign: "center",
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      width: 160,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "3",
        title: `Read the examples and study the rules.`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    // giaiThua: true,
    questions: [
      {
        title: `
        <img style="width:16cm; height:12cm" src='img/FriendsPlus/Page63/E3/1.jpg' />
        
        `,
        answer: [""],
      },
    ],
  },
  4: {
    unit: "Unit 6",
    id: "SB9-2024-U6-P63-E4",
    exerciseKey: "img/FriendsPlus/Page63/Key/E4answerKey.png",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    // textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      width: 800,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "4",
        title: `Rewrite the sentences using defining or 
        non-defining relative clauses.`,
        color: "#5cc9dd",
        left: 50,
        width: "26cm",
      },
    ],
    character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display: flex; font-size: 22px; line-height: 55px; width: 27cm'>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br><br>
          <b>2</b>&ensp;<br><br>
          <b>3</b>&ensp;<br><br>
          <b>4</b>&ensp;<br><br>
          <b>5</b>&ensp;<br>
          
        </div>

        <div style=''>
         <div>The book was very interesting. I borrowed 
         it from the school library.</div>
         <div>The book # .</div>

         <div>Minh's bicycle broke down. He had to take a bus to school.</div>
         <div>Minh, #.</div>

         <div>All the students passed. They studied hard for the test.</div>
         <div>All the students # .</div>

         <div>Hạ Long Bay is a magnificent natural wonder. It is located in Quảng Ninh province.</div>
         <div>Hạ Long Bay, # .</div>

         <div>We're going to a place. We can see lions there.</div>
         <div>We're going # .</div>
         
        </div>
        </div>
        
        `,
        answer: [
          "which I borrowed from the school library was very interesting / that I borrowed from the school library was very interesting",
          "whose bicycle broke down, had to take a bus to school",
          "who studied hard for the test passed",
          "which is located in Quảng Ninh province, is a magnificent natural wonder",
          "to a place where we can see lions",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 6",
    id: "SB9-2024-U6-P63-E5",
    exerciseKey: "img/FriendsPlus/Page63/Key/E5answerKey.png",
    audio: "Audios/2.05.mp3",
    component: T6,
    // maxLength:4,
    // hideBtnFooter:true,
    // textAlign: "center",
    recorder: true,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      height: 27,
      // textTransform: "uppercase",
      width: 900,
      paddingTop: 5,
    },
    isHello: true,
    KeyMau: true,
    titleQuestion: [
      {
        num: "5",
        title: `<headphone name=2.05 src='Audios/2.05 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
        <span style='color: #5cc9dd'>PRONUNCIATION: <i>that</i> in relative clauses</span>
        Listen and repeat. What is the difference between <i>that</i> in the two sentences?`,
        color: "#5cc9dd",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display: flex; font-size: 22px; line-height: 55px; width: 27cm'>
        <div style='margin-right: 10px'>
          <b>1</b>&ensp;<br><br>
          <b>2</b>&ensp;<br><br>
          
        </div>

        <div style=''>
         <div>That course sounds like a lot of fun.</div>
         <div>#</div>

         <div>You’ll visit places that you didn’t know existed.</div>
         <div>#</div>

        </div>
        </div>
        
        `,
        answer: [
          "That is pronounced as the strong form /ðæt/ (that = a demonstrative adjective).",
          "That is pronounced as the weak form /ðət/ (that = a relative pronoun).",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 6",
    id: "SB9-2024-U6-P63-E6",
    // exerciseKey: "img/FriendsPlus/Page63/Key/E5answerKey.png",
    // audio: "Audios/2.05.mp3",
    component: T6,
    // maxLength:4,
    hideBtnFooter: true,
    // textAlign: "center",
    recorder: true,
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 23,
      // height: 70,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "6",
        title: `<img style='height:1.1cm; width: 3.8cm; margin-bottom: 5px' src='img/FriendsPlus/icon/3.jpg'/>
        Write definitions for places, things and people using relative clauses. Then read your 
        definitions to a partner. Can they guess what it is?`,
        color: "#5cc9dd",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img style="width:22cm; height:5cm" src='img/FriendsPlus/Page63/E6/1.jpg' />
        <div><textarea id='0' rows='8'></div>
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
