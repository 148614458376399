import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  5: {
    unit: "Unit 5",
    id: "SB9-2024-U5-P52-E5",
    // audio: "img/FriendsPlus/Page110/Audio/audio-e6-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "100%",
      marginLeft: 0,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page52/E5/1.jpg" }]],
  },
  1: {
    unit: "Unit 5",
    id: "SB9-2024-U5-P52-E1",
    exerciseKey: "img/FriendsPlus/Page52/Key/E1answerKey.png",
    component: T6,
    audio: "Audios/1.33.mp3",
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "1px solid",
      fontSize: 23,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "left",
      width: 800,
      paddingTop: 5,
    },
    selectStyle: {
      height: 35,
      width: 106,
      textAlign: "center",
      fontSize: 21,
    },
    selectOptionRandom: true,
    selectOptionValues: ["true", "false", "NG"],
    titleQuestion: [
      {
        num: "1",
        title: `<headphone name=1.33 src='Audios/1.33 tieu_de.mp3' color='black'  typeImg="sound2"></headphone>
        Read and listen to the text. Write  
        <i>true</i>, <i>false</i> or <i>NG</i> (not given).`,
        color: "#D92F3B",
        left: 50,
        width: "26cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `

        <div style='position: relative; font-size: 22px'>
        <img style="width:16cm; height:31cm; margin-left: 100px" src='img/FriendsPlus/Page52/E1/1.jpg' />
        <div style='display: flex;  width: 25cm; line-height: 44px; margin-top: 20px'>
          <div>
            <div style="display: flex"><b>1</b> &ensp;English helps to record inventions and 
            discoveries.&ensp; <select id=0></select></div>

            <div style="display: flex"><b>2</b> &ensp;English is the only language for the 
            publication of books on world inventions 
            and discoveries. &ensp;<select id=1></select></div> 

            <div style="display: flex"><b>3</b> &ensp;All inventions and discoveries were carried 
            out by English researchers. &ensp;<select id=2></select></div>

            <div style="display: flex"><b>4</b> &ensp;All books written by non-English speaking 
            scientists were translated into English. &ensp; <select id=3></select></div>

          </div>
        </div>
        `,
        // InputRong: true,
        answer: ["true", "NG", "false", "false"],
      },
    ],
  },
  2: {
    unit: "Unit 5",
    id: "SB9-2024-U5-P52-E2",
    // exerciseKey: "img/FriendsPlus/Page52/Key/E1answerKey.png",
    component: T6,
    // audio: "Audios/1.33.mp3",
    // maxLength:4,
    recorder: true,
    hideBtnFooter: true,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "1px solid",
      fontSize: 23,
      height: 27,
      // textTransform: "uppercase",
      textAlign: "left",
      width: 800,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "2",
        title: `Study the highlighted words in the text. What 
        do they mean? Explain your answers using 
        some of the key phrases.`,
        color: "#D92F3B",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `

        <div style='position: relative; font-size: 22px; margin-left: 50px'>
        <img style="width:16cm; height:31cm; margin-left: 150px" src='img/FriendsPlus/Page52/E1/1.jpg' />
        <img style="width:17.5cm; height:10cm; margin-left: 100px" src='img/FriendsPlus/Page52/E2/1.jpg' />
        
        </div>
        `,
        // InputRong: true,
        answer: [""],
      },
    ],
  },
  3: {
    unit: "Unit 5",
    id: "SB9-2024-U5-P52-E3",
    exerciseKey: "img/FriendsPlus/Page52/Key/E3answerKey.png",
    component: T6,
    // audio: "Audios/1.19.mp3",
    // maxLength:4,
    // hideBtnFooter:true,
    // recorder: true,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted",
      fontSize: 22,
      // height: 27,
      // textTransform: "uppercase",
      textAlign: "left",
      width: 280,
      paddingTop: 10,
    },
    textareaStyle: {
      // background: 'none',
      width: 800,
      paddingTop: 5,
      fontSize: 22,
      // height: 100,
    },
    // giaiThua: true,
    character: ",",
    titleQuestion: [
      {
        num: "3",
        title: `<span style='color: #D92F3B'><b>VOCABULARY PLUS</b></span>
        Find the noun form of words 1–5 in the text. Which four suffixes can you identify?`,
        color: "#D92F3B",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative; font-size: 22px'>
        <img style="width:17cm; height:29cm; margin-left: 100px" src='img/FriendsPlus/Page52/E1/1.jpg' />
        <div style='display: flex; width: 25cm; line-height: 38px; margin-top: 20px'>
          <div>
            <div style="display: flex"><b>1</b> &ensp;explore (v) &ensp; #</div>
            <div style="display: flex"><b>2</b> &ensp;develop (v) &ensp; #</div>
            <div style="display: flex"><b>3</b> &ensp;advance (v) &ensp; #</div>
            
          </div>
          <div style='margin-left: 20px'>
            
            <div style="display: flex"><b>4</b> &ensp;invent (v) &ensp; #</div>
            <div style="display: flex"><b>5</b> &ensp;communicate (v) &ensp; #</div>

          </div>
        </div>
          <div><b>Which four suffixes can you identify?</b></div>
          <div>Suffixes: <input id=5 width='800px'/> .</div>
        </div>
        `,
        // InputRong: true,
        answer: [
          "exploration, explorer",
          "development",
          "advancement",
          "invention, inventor",
          "communication",
          "-ation, -er, -ment, -ion, -or",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 5",
    id: "SB9-2024-U5-P52-E4",
    // exerciseKey: "img/FriendsPlus/Page71/Key/E2answerKey.png",
    component: T6,
    //  //  maxLength: 25,
    hideBtnFooter: true,
    textAlign: "left",
    recorder: true,
    // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
    textareaStyle: {
      // background: 'none',
      width: 900,
      paddingTop: 5,
      fontSize: 21,
      // height: 100,
    },
    titleQuestion: [
      {
        num: "4",
        title: `<img style="width:3.5cm; height:1cm; margin-bottom: 7px" src='img/FriendsPlus/icon/1.jpg' /> 
        Work in groups. Brainstorm some 
        ideas for the role of English in your studies.`,
        color: "#D92F3B",
        left: 50,
        width: "26cm",
      },
    ],
    questions: [
      {
        title: `
        <img style="width:18cm; height:8cm; margin-left: 100px" src='img/FriendsPlus/Page52/E4/1.jpg' />
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
