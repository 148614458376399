import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  // 1: {
  //   unit: "Starter",
  //   id: "SB9-2024-Starter-P5-E1",
  //   exerciseKey: "img/FriendsPlus/Page71/Key/E2answerKey.png",
  //   component: T6,
  //   // maxLength:4,
  //   // hideBtnFooter:true,
  //   textAlign: "center",
  //   // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
  //   stylesTextInput: {
  //     background: "none",
  //     borderBottom: "none",
  //     fontSize: 22,
  //     height: 27,
  //     // textTransform: "uppercase",
  //     borderStyle: "dotted",
  //     textAlign: "center",
  //     width: 180,
  //     paddingTop: 5,
  //   },
  //   titleQuestion: [
  //     {
  //       num: "1",
  //       title: `Read answer a-c from the internet review survey on page 6. Complete them with the time expressions in brackets.`,
  //       color: "#5cc9dd",
  //       left: 50,
  //     },
  //   ],
  //   // hideBtnFooter: true,
  //   questions: [
  //     {
  //       title: `
  //       <img style="width:19cm; height:8cm" src='img/FriendsPlus/Page4/E4/1.jpg' />
  //       <div style='display: flex; font-size: 21px'>
  //       <div style='margin-right: 15px'>
  //         <b>a</b>&ensp;<br><br>
  //         <b>b</b>&ensp;<br><br>
  //         <b>c</b>&ensp;<br>
  //       </div>
  //       <div style=''>
  //        <div>I don’t # follow celebrities on
  //        social media, but # I’m following
  //        Daisy Ridley because she’s here in Ireland
  //        making a film. (at the moment / usually)
  //        </div>
  //        <div>I # reset my password, but #
  //        I’m beginning to think that it’s
  //       a good idea. (never / now)
  //        </div>
  //        <div>I write a blog # but I have
  //        problems because my sister is #
  //        borrowing my laptop and I can’t find it.
  //        (always / every week)
  //        </div>
  //       </div>
  //       </div>
  //       `,
  //       answer: ["so", "such", "so"],
  //     },
  //   ],
  // },
  // 2: {
  //   unit: "Starter",
  //   id: "SB9-2024-Starter-P5-E2",
  //   exerciseKey: "img/FriendsPlus/Page71/Key/E2answerKey.png",
  //   component: T6,
  //   maxLength: 22,
  //   // hideBtnFooter:true,
  //   textAlign: "center",
  //   // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
  //   stylesTextInput: {
  //     background: "none",
  //     borderBottom: "none",
  //     fontSize: 22,
  //     height: 27,
  //     // textTransform: "uppercase",
  //     borderStyle: "dotted",
  //     textAlign: "center",
  //     width: 240,
  //     paddingTop: 5,
  //   },
  //   titleQuestion: [
  //     {
  //       num: "2",
  //       title: `Match rules 1-3 with examples from a-c in exercises 1. Then complete the rules with <i>present simple</i> or <i>present continuous</i>.`,
  //       color: "#5cc9dd",
  //       left: 50,
  //     },
  //   ],
  //   // hideBtnFooter: true,
  //   questions: [
  //     {
  //       title: `
  //       <div style='position: relative;'>
  //       <img style="width:22.5cm; height:12cm" src='img/FriendsPlus/Page5/E2/1.jpg' />
  //       <div style='display: flex; position: absolute; top: 110px; left: 38px; width: 20cm; line-height: 44px'>
  //         <div style='margin-right: 15px'>
  //           <b>1</b><br><br>
  //           <b>2</b><br><br><br>
  //           <b>3</b><br><br>
  //         </div>
  //         <div>
  //           <div>In general, we use the #
  //           with frequency adverbs and expressions like
  //           <i>sometimes, usually</i> and <i>every week</i>.</div>
  //           <div>We normally use the #
  //           with time expressions which indicate an
  //           action in progress, like <i>now</i> and <i>at the moment</i>.</div>
  //           <div>We can also use the #
  //           with <i>always</i> when we talk about a habit or
  //           repeated action which annoys us.</div>
  //         </div>
  //       </div>
  //       </div>
  //       `,
  //       answer: ["so", "such", "so"],
  //     },
  //   ],
  // },
  // 3: {
  //   unit: "Starter",
  //   id: "SB9-2024-Starter-P5-E3",
  //   exerciseKey: "img/FriendsPlus/Page71/Key/E2answerKey.png",
  //   component: T6,
  //   // maxLength:4,
  //   // hideBtnFooter:true,
  //   textAlign: "left",
  //   // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
  //   stylesTextInput: {
  //     background: "none",
  //     borderBottom: "none",
  //     fontSize: 22,
  //     height: 27,
  //     // textTransform: "uppercase",
  //     borderStyle: "dotted",
  //     textAlign: "center",
  //     width: 180,
  //     paddingTop: 5,
  //   },
  //   titleQuestion: [
  //     {
  //       num: "3",
  //       title: `Complete the sentences with the correct form of the verbs in brackets. Use the present continuous or present simple.`,
  //       color: "#5cc9dd",
  //       left: 50,
  //     },
  //   ],
  //   // hideBtnFooter: true,
  //   questions: [
  //     {
  //       title: `
  //       <div style='display: flex; font-size: 22px; line-height: 40px'>
  //       <div style='margin-right: 10px'>
  //         <b>1</b>&ensp;<br>
  //         <b>2</b>&ensp;<br><br>
  //         <b>3</b>&ensp;<br>
  //         <b>4</b>&ensp;<br>
  //         <b>5</b>&ensp;<br>
  //         <b>6</b>&ensp;<br>
  //       </div>
  //       <div style=''>
  //        <div>I # (read) <i>Paper Towns</i> by John
  //        Green at the moment and I #
  //        (enjoy) it.
  //        </div>
  //        <div>My friend Sal # (update) her
  //        profile photo quite often but I normally #
  //         (change) mine once a month.
  //        </div>
  //        <div>This phone is fast! It usually #
  //        (download) a film in minutes.
  //        </div>
  //        <div>What # (you / listen) to now?
  //        </div>
  //        <div>I # (not / usually / spend) much
  //        time reading blogs.
  //        </div>
  //        <div>Our Wi-Fi is really slow because my brothers #
  //        (always / play) games online.
  //        </div>
  //       </div>
  //       </div>
  //       `,
  //       answer: ["so", "such", "so", "so", "such", "so", "", ""],
  //     },
  //   ],
  // },
  // 4: {
  //   unit: "Starter",
  //   id: "SB9-2024-Starter-P5-E4",
  //   exerciseKey: "img/FriendsPlus/Page73/Key/E6answerKey.png",
  //   component: T6,
  //   // maxLength:4,
  //   // hideBtnFooter:true,
  //   // textAlign:"center",
  //   stylesTextInput: { width: 900, paddingTop: 5, fontSize: 22 },
  //   titleQuestion: [
  //     {
  //       num: "4",
  //       title: `Order the phrases to make meaningful sentences.`,
  //       color: "#5cc9dd",
  //       left: 50,
  //     },
  //   ],
  //   // hideBtnFooter: true,
  //   questions: [
  //     {
  //       title: `
  //         <div style='margin-left:0px; font-size: 22px'>
  //           <b>1</b>&ensp;show Oanh / to get help / where / I will / . /<br>#<br>
  //           <b>2</b>&ensp;tell me / find / Could you / a Vietnamese restaurant / where to / ? /<br>#<br>
  //           <b>3</b>&ensp;for technical support / who / Yến asked me /
  //           to contact / . /<br>#<br>
  //           <b>4</b>&ensp;to learn a / lent me a good / My brother / new
  //           language quickly / book on how / . /<br>#<br>
  //           <b>5</b>&ensp;a healthy diet / to me how / The doctor
  //           explained / processed foods for / to avoid / . / <br>#<br>
  //           <b>6</b>&ensp;know when / environmentally friendly project /
  //           to start our / Do you / ? /<br>#<br>
  //         </div>
  //       `,
  //       answer: [
  //         "How was the treasure discovered?",
  //         "Where were the jug and coins taken?",
  //         "Are all the coins made of gold?",
  //         "Have the coins been dated yet?",
  //         "Where are the jug and coins kept now?",
  //         "What will the treasure be labelled?",
  //       ],
  //     },
  //   ],
  // },
  // 5: {
  //   unit: "Starter",
  //   id: "SB9-2024-Starter-P5-E5",
  //   exerciseKey: "img/FriendsPlus/Page71/Key/E2answerKey.png",
  //   component: T6,
  //    //  maxLength: 25,
  //   // hideBtnFooter:true,
  //   textAlign: "center",
  //   // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
  //   stylesTextInput: {
  //     background: "none",
  //     borderBottom: "none",
  //     fontSize: 22,
  //     height: 27,
  //     // textTransform: "uppercase",
  //     borderStyle: "dotted",
  //     textAlign: "center",
  //     width: 180,
  //     paddingTop: 5,
  //   },
  //   titleQuestion: [
  //     {
  //       num: "5",
  //       title: `Complete the sentences with <i>to</i>-infinitive of the verbs in the box. There are two extra verbs.`,
  //       color: "#5cc9dd",
  //       left: 50,
  //     },
  //   ],
  //   // hideBtnFooter: true,
  //   hintBox: [
  //     {
  //       src: [
  //         "increase",
  //         "make",
  //         "improve",
  //         "play",
  //         "phone",
  //         "attend",
  //         "travel",
  //         "talk",
  //       ],
  //       width: 700,
  //       borderColor: "#5cc9dd",
  //       marginLeft: 0,
  //     },
  //   ],
  //   questions: [
  //     {
  //       title: `
  //       <hintbox id='0'></hintbox>
  //       <div style='display: flex; font-size: 22px; line-height: 45px; margin-top: 20px'>
  //       <div style='margin-right: 10px'>
  //         <b>1</b>&ensp;<br>
  //         <b>2</b>&ensp;<br>
  //         <b>3</b>&ensp;<br>
  //         <b>4</b>&ensp;<br>
  //         <b>5</b>&ensp;<br><br>
  //         <b>6</b>&ensp;<br>
  //       </div>
  //       <div style=''>
  //        <div>We can't decide whether # a
  //        presentation on renewable energy or not.
  //        </div>
  //        <div>Minh asked his teacher how # his public
  //        speaking skills.
  //        </div>
  //        <div>Could you show me who # to if I want to
  //        rewrite my assignment?
  //        </div>
  //        <div>It's not easy for him to learn how # golf.
  //        </div>
  //        <div>My brother is still at home because he
  //        hesitates about whether # his
  //        ex-girlfriend's graduation ceremony.
  //        </div>
  //        <div>My best friend always plans in advance where #
  //        during her summer break.
  //        </div>
  //       </div>
  //       </div>
  //       `,
  //       answer: ["so", "such", "so", "so", "such", ""],
  //     },
  //   ],
  // },
  // 6: {
  //   unit: "Starter",
  //   id: "SB9-2024-Starter-P5-E6",
  //   exerciseKey: "img/FriendsPlus/Page71/Key/E2answerKey.png",
  //   component: T6,
  //    //  maxLength: 25,
  //   // hideBtnFooter:true,
  //   textAlign: "center",
  //   // stylesTextInput: { width: 100, paddingTop: 5, background: "#fefccc" },
  //   stylesTextInput: {
  //     background: "none",
  //     // borderBottom: "none",
  //     fontSize: 22,
  //     height: 27,
  //     // textTransform: "uppercase",
  //     // borderStyle: "dotted",
  //     textAlign: "center",
  //     width: 900,
  //     paddingTop: 5,
  //   },
  //   titleQuestion: [
  //     {
  //       num: "6",
  //       title: `<prefix></prefix> Use the question words and the verbs in the box to write down five questions about a tourist attraction in Việt Nam.`,
  //       color: "#5cc9dd",
  //       left: 50,
  //     },
  //   ],
  //   // hideBtnFooter: true,
  //   hintBox: [
  //     {
  //       src: [
  //         "get",
  //         "start",
  //         "do",
  //         "go",
  //         "contact",
  //         "eat",
  //         "buy",
  //         "wear",
  //         "visit",
  //         "bring",
  //       ],
  //       width: 800,
  //       borderColor: "#5cc9dd",
  //       marginLeft: 0,
  //     },
  //   ],
  //   questions: [
  //     {
  //       title: `
  //       <hintbox id='0'></hintbox>
  //       <img style="width:18cm; height:12cm" src='img/FriendsPlus/Page5/E6/1.jpg' />
  //       <div style='font-size: 22px; line-height: 45px; margin-top: 20px'>
  //         <b>1</b>&ensp;#<br>
  //         <b>2</b>&ensp;#<br>
  //         <b>3</b>&ensp;#<br>
  //         <b>4</b>&ensp;#<br>
  //         <b>5</b>&ensp;#<br>
  //       </div>
  //       </div>
  //       `,
  //       answer: ["", "", "", "", ""],
  //     },
  //   ],
  // },
};

export default json;
